import {Form} from "react-bootstrap";
import React, {Fragment, useContext} from "react";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {ChartContext} from "../charts/Chart";

export const BaseSelector = ({defaultBase}) => {
    const { allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const { chart, setURL, settings, features, entitiesGrouped } = useContext(ChartContext)

    if (chart.type === "columnRangeAndBar" && settings.isColumnRange ||
        chart.type !== "columnRangeAndBar" && features.length > 1
    ) {
        return null
    }

    const entitiesGrouped2 = defaultBase !== undefined ?
        entitiesGrouped.filter(e => e.id === defaultBase).concat(entitiesGrouped.filter(e => e.id !== defaultBase)) :
        entitiesGrouped

    return (
        <Fragment>
            <b>Base for relative {chart.type === "flow" ? "emission level" : ""} display:</b>
                <Form.Control className={'mt-2 mb-3'} as='select' value={settings.base} onChange={(e) => {
                settings.base = e.target.value === '' ? null : e.target.value
                setURL(chart, settings)
                setAllSettings({...allSettings})
            }}>
                <option value=''>Absolute values</option>
                {entitiesGrouped2.filter(e => e.values[features[0].name] !== null).map(e =>
                    <option
                        value={e.id}
                        disabled={e.values[features[0].name]===0}
                    >
                        {e.name + " (" + e.values[features[0].name].toFixed(1) + " " + features[0].unit + ")"}
                    </option>
                )}
            </Form.Control>
        </Fragment>
    )
}