import React from 'react'
import { Card, Row, Col, ListGroup } from 'react-bootstrap';
import {useQuery} from "@apollo/react-hooks";
import {QUERY_CATEGORY_GROUP} from "../../../../graphql/categoryGroup";
import {useParams} from "react-router";
import {AddCategoryModalButton} from "../categories/add-category";
import {Category} from "../categories/category";


export const CategoryGroup = ({...props}) => {
    const { categoryGroupId } = useParams();

    const { data, loading } = useQuery(QUERY_CATEGORY_GROUP, { variables: { categoryGroupId }})

    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col>
                        <Card.Title>Category group: {loading ? 'Loading...' : data.categoryGroup.name}</Card.Title>
                    </Col>
                    <Col className='text-right'>
                        <AddCategoryModalButton />
                    </Col>
                </Row>
            </Card.Header>
            <ListGroup variant='flush'>
                { loading ? 'Loading...' : data.categoryGroup.categories.map((category, idx) => (
                    <Category key={category.id} category={category} />
                ))}
            </ListGroup>
        </Card>
    )
}