import React, {useContext, useState} from 'react';
import {Modal, Button, Form, Row, Col, Alert} from 'react-bootstrap';
import {useApolloClient, useMutation, useQuery} from "@apollo/react-hooks";
import {LOGIN_QUERY, USER_QUERY} from "../../graphql/login";
import {LoginContext} from "../providers/LoginContextProvider";


const UserModal = ({setShow, show, user, ...props}) => {
    const client = useApolloClient();

    const signOut = () => {
        client.writeData({ data: { isLoggedIn: false } });
        localStorage.removeItem('sustainablepower-token');
        setShow(false);
    };

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>User info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Logged in as: {user.email}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={signOut}>Logout</Button>
            </Modal.Footer>
        </Modal>
    )
}


const LoginModal = ({setShow, show, error, loginMutation, ...props}) => {
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        loginMutation({
            variables: {
                email: email,
                password: password,
            }
        });
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group as={Row}>
                        <Col>
                            <Form.Control
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='email address'
                                type='text' />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col>
                            <Form.Control
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder='Password'
                                type='password' />
                        </Col>
                    </Form.Group>
                    { error ? <Alert variant='danger'>{error}</Alert> : null}
                    <Button type='submit' className='d-none'>Submit</Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={(e) => onSubmit(e)}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )
}


const LoginButton = ({setShow, children, ...props}) => {
    return (
        <Button variant='primary' onClick={() => setShow(true)} {...props}>
            {children}
        </Button>
    )
}


export const Login = (props) => {
    const isLoggedIn = useContext(LoginContext)
    const client = useApolloClient();
    const [show, setShow] = useState(false);
    const [error, setError] = useState('');

    const { data: { user } = { user: {}} } = useQuery(USER_QUERY, {
        onError: (error) => (console.log(error)),
        skip: !isLoggedIn,
    })

    const [ loginMutation ] = useMutation(LOGIN_QUERY, {
        onError: (error) => {
            setError(error.graphQLErrors[0].message)
        },
        onCompleted: (data) => {
            localStorage.setItem('sustainablepower-token', data.login.tokens.accessToken);
            client.writeQuery({query: USER_QUERY, data: { user: { ...data.login.user }}})
            client.writeData({ data: { isLoggedIn: true } });
            setShow(false);
            setError('');
        },
    });

    return (
        <>
            <LoginButton setShow={setShow} {...props} />
            {(show) ? (
                !isLoggedIn ? <LoginModal show={show} setShow={setShow} loginMutation={loginMutation} error={error} /> : <UserModal user={user} show={show} setShow={setShow} />
                ) : null}
        </>
    )
}