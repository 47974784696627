import React from 'react';
import { Card, Button, Col } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'


class ChartCardErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    render() {
        if (!this.props.children) return 'loading.....'

        if (this.state.hasError) {
            return <h2>Something went wrong in the  {this.props.cardTitle} card.</h2>;
        } else {
            return this.props.children;
        }
    }
}


export const ChartCard = ({sm, lg, xs, xl, md, title, linkUri, ...props}) => {
    let history = useHistory();

    return (
        <Col {...{xs, sm, md, lg, xl}}>
            <Card>
                <Card.Header>
                    {title}
                </Card.Header>
                <Card.Body>
                    <ChartCardErrorBoundary cardTitle={title}>
                        {props.children}
                    </ChartCardErrorBoundary>
                </Card.Body>
                <Card.Footer>
                    <Button className='w-100' onClick={() => history.push(linkUri)}>
                        Open
                    </Button>
                </Card.Footer>
            </Card>
        </Col>
    )
}
