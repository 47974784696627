import {Button, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import React, {Fragment, useContext} from "react";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {useSortBy, useTable} from "react-table";
import {ChevronDown, ChevronUp} from "react-bootstrap-icons";

export const sortStrings = (rowA, rowB, columnId, desc, tooltip=false) => {
    if (tooltip) {
        if (rowA.original[columnId].value.toUpperCase() > rowB.original[columnId].value.toUpperCase()) return -1
        if (rowB.original[columnId].value.toUpperCase() > rowA.original[columnId].value.toUpperCase()) return 1
    } else {
        if (rowA.original[columnId].toUpperCase() > rowB.original[columnId].toUpperCase()) return -1
        if (rowB.original[columnId].toUpperCase() > rowA.original[columnId].toUpperCase()) return 1
    }
    return 0
}

export const sortNumbers = (rowA, rowB, columnId, desc, tooltip=false) => {
    if (rowA.original[columnId] === null) {
        if (rowB.original[columnId] === null) {
            return 0
        }
        return desc ? -1 : 1
    }
    if (rowB.original[columnId] === null) {
        return desc ? 1 : -1
    }
    if (tooltip) {
        if (rowA.original[columnId].value > rowB.original[columnId].value) return -1
        if (rowB.original[columnId].value > rowA.original[columnId].value) return 1
    } else {
        if (rowA.original[columnId] > rowB.original[columnId]) return -1
        if (rowB.original[columnId] > rowA.original[columnId]) return 1
    }
    return 0
}

const TableRow = ({row}) => {
    return (
        <tr
            {...row.getRowProps()}
            style={{borderTop: 'solid 1px lightgray'}}
        >
            {row.cells.map(cell => {
                const hasTooltip = typeof cell.value === 'object' && cell.value !== null

                return (
                    <td
                        {...cell.getCellProps()}
                        style={{
                            padding: '2px 5px 2px 5px',
                            backgroundColor: cell.value === null ? '#e8e8e8' : '#ffffff'
                        }}
                    >
                        {/*{cell.render('Cell')}*/}
                        {hasTooltip &&
                            <OverlayTrigger overlay={
                                <Tooltip>
                                    {typeof cell.value.tooltip === 'string' ?
                                    cell.value.tooltip.replaceAll(/{([^}]*)}/g, (match, p1) => cell.value[p1]) :
                                    cell.value.tooltip}
                                </Tooltip>
                            }>
                                <span>{typeof cell.value.value === 'number' ? cell.value.value.toFixed(cell.column.decimals) : cell.value.value}</span>
                            </OverlayTrigger>
                        }
                        {!hasTooltip && <span>{typeof cell.value === 'number' ? cell.value.toFixed(cell.column.decimals) : cell.value}</span>}
                    </td>
                )
            })}
        </tr>
    )
}

export const TableDisplay = ({columns, data, categoryGroups, page}) => {
    const { allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const filter = allSettings[page]["table"]

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({columns, data}, useSortBy)

    const columnAmount = Object.keys(rows[0].values).length

    let rowGroups = []
    let rowsGrouped = []
    if (filter.groupBy !== 'ungrouped') {
        rowGroups = ["Uncategorized"].concat(
            categoryGroups.find(cg => cg.id === filter.groupBy).categories.sort((a, b) => a.index - b.index).map(c => c.name)
        )

        rows.forEach(row => {
            let rowGroup
            if (row.original.group === undefined) {
                rowGroup = "Uncategorized"
            } else {
                rowGroup = row.original.group
            }

            const rowGroupIndex = rowGroups.findIndex(rg => rg === rowGroup)

            if (rowsGrouped[rowGroupIndex] === undefined) {
                rowsGrouped[rowGroupIndex] = [row]
            } else {
                rowsGrouped[rowGroupIndex].push(row)
            }
        })
    }

    return (
        <table {...getTableProps()}>
            <thead>
            <tr>
                <th colspan={3}>
                    <span><b>Group by categories:</b></span>
                    <Form.Control
                        className={'mt-2 mb-2'}
                        as='select'
                        value={filter.groupBy}
                        onChange={(e) => {
                            filter.groupBy = e.target.value
                            setAllSettings({...allSettings})
                        }}
                    >
                        <option value={'ungrouped'}>No grouping</option>
                        {categoryGroups.map(cg => {
                            return <option value={cg.id}>{cg.name}</option>
                        })}
                    </Form.Control>
                </th>
            </tr>
            {headerGroups.map(headerGroup => (
                <tr
                    style={{
                        color: 'black',
                        fontWeight: 'bold',
                        borderTop: 'solid 1px lightgray',
                        // borderBottom: 'solid 1px gray',
                    }}
                >
                    {headerGroup.headers.map(column => (
                        <th
                            {...column.getHeaderProps()}
                        >
                            {/*{column.render('Header')}<br/>*/}
                            {column.tooltip !== undefined &&
                                <OverlayTrigger overlay={
                                    <Tooltip>
                                        {column.tooltip}
                                    </Tooltip>
                                }>
                                    <span>{column.Header}</span>
                                </OverlayTrigger>
                            }
                            {column.tooltip === undefined && column.Header}
                        </th>
                    ))}
                </tr>
            ))}
            <tr style={{borderTop: 'solid 1px lightgray'}}>
                {headerGroups[headerGroups.length - 1].headers.map(column =>
                    <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{padding: '5px 0px 5px 0px'}}
                    >
                        <Button size='sm'>Sort {column.isSorted ? (column.isSortedDesc ? <ChevronDown/> :
                            <ChevronUp/>) : ''}</Button>
                    </th>
                )}
            </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
                {filter.groupBy === 'ungrouped' && rows.map(row => {
                    prepareRow(row)
                    return <TableRow row={row}/>
                })}
                {filter.groupBy !== 'ungrouped' && rowsGrouped.map((groupRows, i) => {
                    return (
                        <Fragment>
                            <tr style={{borderTop: 'solid 1px lightgray'}}>
                                <td colspan={columnAmount} style={{padding: '2px 5px 2px 5px'}}>
                                    <span><b>{rowGroups[i]}</b></span>
                                </td>
                            </tr>
                            {groupRows.map(row => {
                                prepareRow(row)
                                return <TableRow row={row}/>
                            })}
                        </Fragment>
                    )
                })}
            </tbody>
        </table>
    )
}