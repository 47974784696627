import gql from 'graphql-tag';


export const FRAGMENT_CATEGORY_CONTENT = gql`
    fragment categoryContent on CategoryObject {
        name
        description
        color
        categoryGroupName
        categoryGroupId
        index
    }
`


export const QUERY_CATEGORIES = gql`
    query categories {
        categoryGroups {
            id
            name
            hide
            index
            categories {
                id
                ...categoryContent
            }
        }
    }    
    ${FRAGMENT_CATEGORY_CONTENT}
`


export const MUTATION_CREATE_CATEGORY = gql`
    mutation createCategory($categoryGroupId: ID!, $name: String!, $description: String, $color: String) {
        createCategory(categoryGroupId: $categoryGroupId, name: $name, description: $description, color: $color) {
            ok
            category {
                id
                ...categoryContent
            }
        }
    }    
    ${FRAGMENT_CATEGORY_CONTENT}
`


export const MUTATION_DELETE_CATEGORY = gql`
    mutation deleteCategory($categoryId: ID!) {
        deleteCategory(categoryId: $categoryId) {
            ok
            category {
                id
                ...categoryContent
            }
        }
    }   
    ${FRAGMENT_CATEGORY_CONTENT}
`


export const MUTATION_UPDATE_CATEGORY = gql`
    mutation updateCategory($categoryId: ID!, $name: String, $description: String, $color: String, $categoryGroupId: ID) {
        updateCategory(categoryId: $categoryId, categoryGroupId: $categoryGroupId, color: $color, name: $name, description: $description) {
            ok
            category {
                id
                ...categoryContent
            }
        }
    }
    ${FRAGMENT_CATEGORY_CONTENT}
`

export const MUTATION_ADD_CATEGORY_TO_ENTITY = gql`
    mutation addCategoryToEntity {
        addCategoryToEntity(categoryId: $categoryId, entityId: $entityId) {
            ok
            entity {
                id
                categories {
                    id
                }
            }
            category {
                id
            }
        }
    }    
`