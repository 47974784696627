import {Form} from "react-bootstrap";
import React, {useContext} from "react";
import {ChartContext} from "../charts/Chart";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";

export const FeaturesSelector = ({}) => {
    const { allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const { chart, settings, setURL, systemPropertyGroups, formulas, forStacks } = useContext(ChartContext)

    const features = forStacks ? formulas : systemPropertyGroups

    return (
        <Form.Control
            className={"mt-2 mb-3"}
            as='select'
            value={settings.sortBy}
            onChange={e => {
                settings.enabledFeatures = [e.target.value]
                settings.sortBy = e.target.value
                setURL(chart, settings)
                setAllSettings({...allSettings})
            }}
        >
            {JSON.parse(chart.features).map(fId => features.find(f => f.id === fId)).map(f =>
                <option value={f.id}>{f.name}</option>
            )}
        </Form.Control>
)
}