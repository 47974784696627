import React, { useState } from 'react'
import { useParams } from "react-router";
import {ButtonGroup, Modal, Button, Form, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useMutation} from "@apollo/react-hooks";
import {MUTATION_CREATE_CATEGORY} from "../../../../graphql/category";
import { Formik, Field } from 'formik';
import {QUERY_CATEGORY_GROUP} from "../../../../graphql/categoryGroup";


const AddCategoryModal = ({show, setShow, props}) => {
    const { categoryGroupId } = useParams();
    const [ createCategory, { loading }] = useMutation(MUTATION_CREATE_CATEGORY, {
        refetchQueries: [{query: QUERY_CATEGORY_GROUP, variables: { categoryGroupId: categoryGroupId}}]
    })

    const onSubmit = (values, { setSubmitting }) => {
        createCategory({
            variables: {
                categoryGroupId: categoryGroupId,
                name: values.name,
                description: values.description,
                color: values.color,
            }
        }).then(() => setSubmitting(false)).then(() => setShow(false))
    }

    const onCancel = () => {
        setShow(false);
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Create a category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        name: '',
                        description: '',
                        color: '#000000',
                    }}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting, handleReset, handleSubmit}) => (
                        <Form onSubmit={handleSubmit} onReset={handleReset}>
                            <Form.Group>
                                <Form.Label>Category name:</Form.Label>
                                <Field as={Form.Control} name='name'/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Description:</Form.Label>
                                <Field as={Form.Control} name='description' />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Color:</Form.Label>
                                <Field type='color' as={Form.Control} name='color' />
                                <Form.Text>This color can be used in Graphs and for category grouping in tables</Form.Text>
                            </Form.Group>
                            <Form.Group>
                                <ButtonGroup>
                                    <Button type='submit' disabled={isSubmitting || loading}>Submit</Button>
                                    <Button type='button' disabled={isSubmitting} onClick={onCancel}>Cancel</Button>
                                </ButtonGroup>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}


export const AddCategoryModalButton = (props) => {
    const [ show, setShow ] = useState(false)

    return (
        <React.Fragment>
            <OverlayTrigger overlay={<Tooltip id='add-category-tooltip'>Add a category</Tooltip>}>
                <Button onClick={() => setShow(true)}>Add category</Button>
            </OverlayTrigger>
            <AddCategoryModal show={show} setShow={setShow} />
        </React.Fragment>
    )
}