import React, {useContext} from "react";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {Form, InputGroup} from "react-bootstrap";
import {ChartContext} from "../charts/Chart";

export const RangeField = ({rKey}) => {
    const { allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const { chart, forStacks, setURL, settings, features, allEntities, missingDataEntities, valueForFormulaOrSPG } = useContext(ChartContext)

    let realRange = rKey.includes("Min") ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY
    allEntities.filter(e => !settings.disabledEntities.includes(e.id)).filter(e => missingDataEntities.find(mdE => mdE.id === e.id)===undefined).forEach(e => {
        const axis = features[ rKey.includes("X") ? 0 : 1 ]
        const value = forStacks ? valueForFormulaOrSPG(e, axis) : e.values[axis.name]["average"]

        if (settings[rKey] === null && (
            rKey.includes("Min") && value < realRange ||
            rKey.includes("Max") && value > realRange
        )) {
            realRange = value
        }
    })

    const countDecimals = (value) => {
        if (Math.floor(value) === value) return 0
        return value.toString().split(".")[1].length || 0
    }

    return (
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>{rKey.includes("Min") ? "min" : "max"}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
                value={
                    (settings[rKey] !== null) ?
                    settings[rKey] :
                    (countDecimals(realRange)>2 ? realRange.toFixed(2) : realRange)
                }
                onChange={(e) => {
                    settings[rKey] = e.target.value
                    setURL(chart, settings)
                    setAllSettings({...allSettings})
                }}
                style={{backgroundColor: settings[rKey] !== null ? "lightblue" : "white"}}
            />
        </InputGroup>
    )
}