import React from "react";
import {Card, Row, Col, ListGroup, ButtonGroup} from "react-bootstrap";
import {useQuery} from "@apollo/react-hooks";
import { QUERY_STACK_TYPE } from "../../../../../graphql/stackType";
import {
    StackTypeSystemFieldCreateModalFormButton,
    StackTypeSystemFieldEditModalFormButton,
    StackTypeSystemFieldDeleteModalFormButton,
} from './StackTypeSystemField';
import { Pencil, Trash } from "react-bootstrap-icons";
import { useParams } from 'react-router-dom'


const StackTypeSystemFieldDisplay = ({stackTypeSystemField, stackTypeId, ...props}) => {
    return (
        <ListGroup.Item>
            <Row>
                <Col sm={3}>{stackTypeSystemField.name}</Col>
                <Col sm={6}>{stackTypeSystemField.description}</Col>
                <Col sm={1}>{stackTypeSystemField.index}</Col>
                <Col sm={2}>
                    <ButtonGroup size='sm' className='w-100'>
                        <StackTypeSystemFieldEditModalFormButton stackTypeId={stackTypeId} stackTypeSystemField={stackTypeSystemField}>
                            <Pencil size={18} />
                        </StackTypeSystemFieldEditModalFormButton>
                        <StackTypeSystemFieldDeleteModalFormButton stackTypeSystemField={stackTypeSystemField}>
                            <Trash size={18} />
                        </StackTypeSystemFieldDeleteModalFormButton>
                    </ButtonGroup>
                </Col>
            </Row>
        </ListGroup.Item>
    )
}


export const SystemFields = ({...props}) => {
    const { stackTypeId } = useParams();

    const { data, loading } = useQuery(QUERY_STACK_TYPE, {
        variables: { stackTypeId: stackTypeId },
        onError: error => console.log(error),
    })

    if (loading) return <b>Loading....</b>

    const systemFieldsData = data.stackType.systemFields.slice()
    const systemFieldsDataSorted = systemFieldsData.sort((mf0, mf1) => mf0.index >= mf1.index)
    const systemFields = systemFieldsDataSorted.map(mf => {
        return <StackTypeSystemFieldDisplay key={mf.id} stackTypeSystemField={mf} stackTypeId={stackTypeId} />
    })

    return (
        <Card>
            <Card.Header>
                <h3>System fields</h3>
            </Card.Header>
            <Card.Body>
                <ListGroup variant='flush'>
                    { systemFields }
                </ListGroup>
                <StackTypeSystemFieldCreateModalFormButton stackType={data.stackType}>
                    Add
                </StackTypeSystemFieldCreateModalFormButton>
            </Card.Body>
        </Card>
    )
}