import gql from 'graphql-tag';


export const MUTATION_CREATE_STACK_TYPE_SYSTEM_FIELD = gql`
    mutation createStackTypeSystemField($stackTypeId: ID!, $name: String!, $description: String, $index: Int) {
        createStackTypeSystemField(
            stackTypeId: $stackTypeId,
            name: $name, 
            description: $description,
            index: $index,
        ) {
            ok
            stackTypeSystemField {
                id
                name
                description
                index
            }
        }
    }
`


export const MUTATION_UDPATE_STACK_TYPE_SYSTEM_FIELD = gql`
    mutation updateStackTypeSystemField($stackTypeSystemFieldId: ID!, $name: String, $description: String, $index: Int) {
        updateStackTypeSystemField(
            stackTypeSystemFieldId: $stackTypeSystemFieldId,
            name: $name, 
            description: $description,
            index: $index,
        ) {
            ok
            stackTypeSystemField {
                id
                name
                description
                index
            }
        }
    }
`


export const MUTATION_DELETE_STACK_TYPE_SYSTEM_FIELD = gql`
    mutation deleteStackTypeSystemField($stackTypeSystemFieldId: ID!) {
        deleteStackTypeSystemField(
            stackTypeSystemFieldId: $stackTypeSystemFieldId,
        ) {
            ok
            stackTypeSystemField {
                id
                name
                description
                stackTypeId
                index
            }
        }
    }
`