import {Form} from "react-bootstrap";
import React, {useContext} from "react";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {ChartContext} from "../charts/Chart";

export const LabelsCheckboxes = ({}) => {
    const { allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const { chart, setURL, settings, features } = useContext(ChartContext)

    return (
        <div className={'mt-2 mb-3'}>
            {"seriesLabels" in settings && (chart.type !== "columnRangeAndBar" && features.length > 1 || chart.type === "columnRangeAndBar" && settings.isTwoSeries) &&
            <Form.Check>
                <Form.Check.Input
                    type={"checkbox"}
                    value={"seriesLabel"}
                    onChange={() => {
                        settings.seriesLabels = !settings.seriesLabels
                        setURL(chart, settings)
                        setAllSettings({...allSettings})
                    }}
                    checked={settings.seriesLabels}
                />
                <Form.Check.Label>Series labels</Form.Check.Label>
            </Form.Check>}
            {"valueLabels" in settings && <Form.Check>
                <Form.Check.Input
                    type={"checkbox"}
                    value={"valueLabel"}
                    onChange={() => {
                        settings.valueLabels = !settings.valueLabels
                        setURL(chart, settings)
                        setAllSettings({...allSettings})
                    }}
                    checked={settings.valueLabels}
                />
                <Form.Check.Label>Value labels</Form.Check.Label>
            </Form.Check>}
        </div>
    )
}