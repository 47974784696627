import React, {Fragment, useContext, useState} from 'react';
import {Modal, OverlayTrigger, Tooltip, Button, ButtonGroup, Form, Row, Col} from 'react-bootstrap';
import {useMutation} from "@apollo/react-hooks";
import {Formik, Field, FieldArray} from 'formik'
import {BootstrapSelector} from "../BootstrapSelector";
import {FormulaOrSPGSelector} from "../FormulaOrSPGSelector";
import {SustainablePowerDataContext2} from "../../../providers/SustainablePowerDataProvider2";
import {sortAlphabetical} from "../../../general/functions/SortAlphabetical";
import {MUTATION_UPDATE_CHART_SETTING} from "../../../../graphql/chartSetting";

const Bip = ({field, ...props}) => {
    return (
        <Form.Control placeholder={field.value === null ? "null" : ""}/>
    )
}

const EditChartSettingModal = ({chartSetting, show, setShow, ...props}) => {
    const {
        allCharts,
        allStacks, allSystems,
        allCategoryGroups,
        allFormulas, allSystemPropertyGroups,
        allEntityTypes, allSystemTypes,
        allChartSettingTypes, allChartSettingLegalValues,
    } = useContext(SustainablePowerDataContext2)

    const chart = allCharts.find(ch => ch.id === chartSetting.chartId)
    const entityType = allEntityTypes.find(eT => eT.id === chart.entityTypeId)
    const forStacks = entityType.discriminator === "stack_type"

    const categoryGroups = allCategoryGroups
        .filter(cg => !cg.hide)
        .filter(cg => entityType.categoryGroups.find(cg2 => cg2.id === cg.id)!==undefined)
        .sort((a, b) => a.index-b.index)

    const categories = categoryGroups.reduce((categories, cg) => [...categories, ...cg.categories], [])

    const systemPropertyGroups = allSystemPropertyGroups
        .filter(spg => forStacks || spg.systemType.id === chart.entityTypeId)
        .sort((a, b) => sortAlphabetical(a.name, b.name))
        .sort((a, b) => b.index - a.index)
        .sort((a, b) => a.index === null ? 1 : -1)
        .map(spg => {
            return {groupId: spg.systemType.id, ...spg}
        })

    const formulas = allFormulas.filter(f => !f.hide).sort((a, b) => sortAlphabetical(a.name, b.name)).map(f => {
        return {groupId: "6", ...f}
    })

    const formulasOrSPGs = formulas.concat(systemPropertyGroups)

    const columns = [
        {id: "0", name: 'Resource'},
        {id: "1", name: 'Energy Carrier'},
        {id: "2", name: 'Energy Conversion'},
        {id: "3", name: 'Distribution & Drive'},
        {id: "4", name: 'Emission Level'}
    ]

    const groups = {
        "systems": allSystems,
        "stacks": allStacks,
        "categoryGroups": categoryGroups,
        "categories": categories,
        "systemPropertyGroups": systemPropertyGroups,
        "formulas": formulas,
        "formulasOrSPGs": formulasOrSPGs,
        "columns": columns,
    }

    const [ updateChartSetting, { loading }] = useMutation(MUTATION_UPDATE_CHART_SETTING)

    const onSubmit = (values, {setSubmitting}) => {
        updateChartSetting({
            variables: {
                chartSettingId: chartSetting.id,
                chartId: chartSetting.chartId,
                type: values.type,
                legalValues: values.type === "boolean" || values.type === "number" ? null : values.legalValues,
                name: values.name,
                shortName: values.shortName,
                default: JSON.stringify(
                    values.type === "number" && values.default === "" || values.type === "nullOrId" && values.default === "null"
                    ? null : values.default
                ),
                hidden: values.hidden
            }
        }).then(() => setSubmitting(false)).then(() => setShow(false))
    }

    const chartSettingDefault = JSON.parse(chartSetting.default)

    const initialValues = {
        type: chartSetting.type,
        legalValues: chartSetting.legalValues,
        name: chartSetting.name,
        shortName: chartSetting.shortName,
        default: chartSettingDefault === null ? (chartSetting.type === "number" ? "" : "null") : chartSettingDefault,
        hidden: chartSetting.hidden
    }

    return (
        <Modal show={show} onHide={() => setShow(false)} onClick={e => e.stopPropagation()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit chart setting "{chartSetting.name}"
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {({values, handleSubmit, handleReset, isSubmitting, setFieldValue}) => (
                        <Form onSubmit={handleSubmit} onReset={handleReset}>
                            <Form.Group>
                                <Form.Label>Setting type:</Form.Label>
                                <Form.Control as={"select"} onChange={(e) => {
                                    if (e.target.value === "listOfIds" || e.target.value === "listOfObjects") {
                                        setFieldValue("default", [])
                                    } else if (e.target.value === "nullOrId") {
                                        setFieldValue("default", "null")
                                    } else if (e.target.value === "idOrString" || e.target.value === "objectOrString") {
                                        setFieldValue("default", "abc")
                                    } else if (e.target.value === "boolean") {
                                        setFieldValue("default", true)
                                    } else if (e.target.value === "number") {
                                        setFieldValue("default", "")
                                    } else {
                                        setFieldValue("default", "notset")
                                    }
                                    setFieldValue("type", e.target.value)
                                }}>
                                    {allChartSettingTypes.map(t => <option value={t}>{t}</option>)}
                                </Form.Control>
                            </Form.Group>
                            {values.type !== "boolean" && values.type !== "number" &&
                                <Form.Group>
                                    <Form.Label>Group of legal values for this setting:</Form.Label>
                                    <Field component={BootstrapSelector} name='legalValues'>
                                        {allChartSettingLegalValues.map(lV => <option value={lV}>{lV}</option>)}
                                    </Field>
                                </Form.Group>
                            }
                            <Form.Group>
                                <Form.Label>Setting name:</Form.Label>
                                <Field as={Form.Control} name='name'/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Setting short name:</Form.Label>
                                <Field as={Form.Control} name='shortName'/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Default value:</Form.Label>
                                {(values.type === "id" || values.type === "nullOrId" || values.type === "idOrString") &&
                                    <Field component={BootstrapSelector} name={'default'}>
                                        <option value={"notset"}>NOT SET</option>
                                        {values.type === "nullOrId" && <option value={"null"}>NULL</option>}
                                        {values.type === "idOrString" && <option value={"abc"}>Alphabetical</option>}
                                        {values.type === "idOrString" && <option value={"sum"}>Sum of bars</option>}
                                        {groups[values.legalValues].map(o => <option value={o.id}>{o.name}</option>)}
                                    </Field>
                                }
                                {values.type === "listOfIds" &&
                                    <FieldArray
                                        name={"default"}
                                        render={
                                            arrayHelpers => (
                                                <Fragment>
                                                    {values.default.map((item, i) => {
                                                        return (
                                                            <Row>
                                                                <Col>
                                                                    <Field component={BootstrapSelector} name={`default.${i}`}>
                                                                        {groups[values.legalValues].map(o => <option value={o.id}>{o.name}</option>)}
                                                                    </Field>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <Button onClick={() => arrayHelpers.remove(i)}>Remove</Button>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })}
                                                    <Button onClick={() => {
                                                        arrayHelpers.push(groups[values.legalValues][0].id)
                                                    }}>Add</Button>
                                                </Fragment>
                                            )
                                        }
                                    />
                                }
                                {(values.type === "object" || values.type === "objectOrString") &&
                                    <Field component={FormulaOrSPGSelector} notSet={true} forSort={values.type === "objectOrString"} name={'default'}/>
                                }
                                {values.type === "listOfObjects" &&
                                    <FieldArray
                                        name={"default"}
                                        render={
                                            arrayHelpers => (
                                                <Fragment>
                                                    {values.default.map((item, i) => {
                                                        return (
                                                            <Row>
                                                                <Col>
                                                                    <Field component={FormulaOrSPGSelector} forSort={values.type === "objectOrString"} name={`default.${i}`}/>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <Button onClick={() => arrayHelpers.remove(i)}>Remove</Button>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })}
                                                    <Button onClick={() => {
                                                        arrayHelpers.push(groups[values.legalValues][0].id)
                                                    }}>Add</Button>
                                                </Fragment>
                                            )
                                        }
                                    />
                                }
                                {values.type === "boolean" &&
                                    <Field component={BootstrapSelector} name={'default'}>
                                        <option value={true}>TRUE</option>
                                        <option value={false}>FALSE</option>
                                    </Field>
                                }
                                {values.type === "number" &&
                                    <Field
                                        component={({field}) => <Form.Control placeholder={field.value === "" ? "not set" : ""}/>}
                                        name={'default'}
                                    />
                                }
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Is hidden (not in URL parameters):</Form.Label>
                                <Field component={BootstrapSelector} name={'hidden'}>
                                    <option value={false}>FALSE</option>
                                    <option value={true}>TRUE</option>
                                </Field>
                            </Form.Group>
                            <Form.Group>
                                <ButtonGroup className='w-100'>
                                    <Button disabled={loading || isSubmitting} type='submit'>Submit</Button>
                                    <Button variant='danger' onClick={() => setShow(false)}>Cancel</Button>
                                </ButtonGroup>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}


export const EditChartSettingModalButton = ({chartSetting, ...props}) => {
    const [ show, setShow ] = useState(false);

    const openModal = (e) => {
        e.stopPropagation();
        setShow(true);
    }

    return (
        <React.Fragment>
            <OverlayTrigger overlay={<Tooltip id='edit-chart-setting-tooltip-id'>Edit this chart setting</Tooltip>}>
                <span style={{marginLeft: "10px", color: "blue", cursor: "pointer"}} onClick={openModal}>edit</span>
            </OverlayTrigger>
            <EditChartSettingModal show={show} setShow={setShow} chartSetting={chartSetting} />
        </React.Fragment>
    )
}
