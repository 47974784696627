import {Button, ButtonGroup, Col, Form, ListGroup, Row} from "react-bootstrap";
import React, {useRef, useState} from "react";
import {Field, Formik, useFormikContext} from "formik";
import {FileCheck, Pencil, Trash, X} from "react-bootstrap-icons";
import {useMutation} from "@apollo/react-hooks";
import {MUTATION_UPDATE_SYSTEM_PROPERTY_GROUP} from "../../../graphql/queries/system-property-group";
import {Form as FormikForm} from "formik";


const SystemPropertyGroupRowButtonGroup = ({editing, setEditing}) => {
    const { submitForm, resetForm } = useFormikContext();

    function onCancel() {
        resetForm()
        setEditing(false);
    }

    return (
        (editing) ? (
            <ButtonGroup>
                <Button size='sm' onClick={submitForm}>
                    <FileCheck size={18}/>
                </Button>
                <Button size='sm' onClick={onCancel}>
                    <X size={18}/>
                </Button>
                <Button size='sm' disabled={true}>
                    <Trash size={18}/>
                </Button>
            </ButtonGroup>
        ) : (
            <ButtonGroup>
                <Button size='sm' onClick={() => setEditing(true)}>
                    <Pencil size={18}/>
                </Button>
                <Button size='sm' disabled={true}>
                    <Trash size={18}/>
                </Button>
            </ButtonGroup>
        )
    )
}


const SystemPropertyGroupRow = ({systemPropertyGroup, ...props}) => {
    const [ editing, setEditing ] = useState(false);
    const formRef = useRef(null);

    const [ updateSystemPropertyGroups ] = useMutation(MUTATION_UPDATE_SYSTEM_PROPERTY_GROUP, {
        onError: error => console.log(error),
        onCompleted: data => console.log(data),
    })

    function onSubmit(values) {
        updateSystemPropertyGroups({
                variables: {
                    data: {
                        systemPropertyGroupId: systemPropertyGroup.id,
                        ...values,
                    }
                }
            }
        )

        setEditing(false);
    }

    return (
        <ListGroup.Item>
            <Formik
                validateOnChange={false}
                innerRef={formRef}
                initialValues={{
                    name: systemPropertyGroup.name,
                    shortName: systemPropertyGroup.shortName,
                    color: (!!systemPropertyGroup.color) ? systemPropertyGroup.color : '#000000',
                    unit: (!!systemPropertyGroup.unit) ? systemPropertyGroup.unit : '',
                }}
                onSubmit={onSubmit}
            >
                {({ values }) => (
                    <FormikForm>
                        <Row>
                            {((editing) ? [
                                <Field type='text' as={Form.Control} name='name' placeholder='name'/>,
                                <Field type='text' as={Form.Control} name='shortName' placeholder='short name'/>,
                                <Field type='color' as={Form.Control} name='color' placeholder='color (HEX CODE)'/>,
                                <Field type='text' as={Form.Control} name='unit' placeholder='unit short-hand'/>,
                            ] : [
                                values.name,
                                values.shortName,
                                <Form.Control type='color' disabled value={values.color}/>,
                                values.unit,
                            ]).map((item, idx) => {
                                const widths = [4, 2, 2, 2];
                                return <Col key={idx} sm={widths[idx]}>{item}</Col>
                            }) }
                            <Col sm={2} className='text-right'>
                                <SystemPropertyGroupRowButtonGroup editing={editing} setEditing={setEditing} />
                            </Col>
                        </Row>
                    </FormikForm>
                )}
            </Formik>
        </ListGroup.Item>
    )
}


const SystemPropertyGroupSettingsTableHeader = (props) => {
    return (
        <ListGroup.Item className='font-weight-bold'>
            <Row>
                <Col sm={4}>Name</Col>
                <Col sm={4}>Short name</Col>
                <Col sm={2}>Color</Col>
                <Col sm={2}>Unit</Col>
            </Row>
        </ListGroup.Item>
    )
}


export const SystemPropertyGroupSettingsTable = ({systemPropertyGroups = [], ...props}) => {
    return (
        <ListGroup variant='flush'>
            <SystemPropertyGroupSettingsTableHeader />
            {systemPropertyGroups.map(spg => <SystemPropertyGroupRow key={spg.id} systemPropertyGroup={spg} /> )}
        </ListGroup>
    )
}