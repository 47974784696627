import gql from "graphql-tag";

export const QUERY_ENTITY_TYPES = gql`
    query entityTypes {
        entityTypes {
            id
            name
            description
            discriminator
            categoryGroups {
                id
            }
        }
    }
`

export const QUERY_ENTITY_TYPE = gql`
    query entityType($entityTypeId: ID!) {
        entityType(entityTypeId: $entityTypeId) {
            id
            name
            description
            categoryGroups {
                id
                name
                description
                categories {
                    id
                    name
                }
            }
        }
    }    
`