import React, {useContext} from "react";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {ChartContext} from "./Chart";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HC_exporting from 'highcharts/modules/exporting';
import HC_offline_exporting from 'highcharts/modules/offline-exporting';
import {HCExporting} from "./HCExporting";
HC_exporting(Highcharts);
HC_offline_exporting(Highcharts);

export const Bar = ({}) => {
    const { allSystems } = useContext(SustainablePowerDataContext2)
    const { chart, forStacks, settings, features, hasValueForFormulaOrSPG, valueForFormulaOrSPG, entities, isPreview } = useContext(ChartContext)

    let xlsxHeaderRow = [
        {type: 'string', value: forStacks ? 'Stack' : "Energy Carrier"}
    ]
    features.forEach(f => xlsxHeaderRow.push({type: 'string', value: "displayName" in f ? f.displayName : f.name}))
    const xlsxRows = [xlsxHeaderRow].concat(entities.map(e => {
        let eRow = [{type: 'string', value: e.name}]
        features.forEach(f => {
            if (forStacks && hasValueForFormulaOrSPG(e, f)) {
                eRow.push({type: 'number', value: valueForFormulaOrSPG(e, f)})
            }
            else if (!forStacks && f.name in e.values) {
                eRow.push({type: 'number', value: e.values[f.name]["average"]})
            }
        })
        return eRow
    }))

    const csvRows = entities.map(e => {
        let eRow = {
            [forStacks ? 'Stack' : "Energy Carrier"]: e.name,
        }
        features.forEach(f => {
            if (forStacks && hasValueForFormulaOrSPG(e, f)) {
                eRow["displayName" in f ? f.displayName : f.name] = valueForFormulaOrSPG(e, f)
            }
            else if (!forStacks && f.name in e.values) {
                eRow[f.name] = e.values[f.name]["average"]
            }
        })
        return eRow
    })

    const countDecimals = (value) => {
        if (Math.floor(value) === value) return 0
        return value.toString().split(".")[1].length || 0
    }

    const title = (
        features.length === 1 ?
        features[0].name :
        features.map(f => f.name).slice(0,-1).join(", ")+" & "+features[features.length-1].name
    ) + " for " + (forStacks ? "stacks" : "energy carriers")

    const multipleSeries = features.length > 1

    const height = isPreview ? 300 : features.length*Math.pow(entities.length, 0.65)*125

    const lastFeatureWithDataIndex = entities.map(e => {
        return features.reduce((lastEntity, f, i) => f.name in e.values ? i : lastEntity, 0)
    })

    const systemsMetaData = !forStacks ? entities.map(e => e.metaData
        .sort((a, b) => a.systemTypeMetaField.index - b.systemTypeMetaField.index)
        .map(m => m.systemTypeMetaField.name + ": " + m.value)
        .join("<br/>")
    ) : []

    const baseEntityIndex = entities.findIndex(e => e.id === settings.base)

    function getValue(e, feature) {
        if (forStacks) {
            if (feature.groupId !== "6") {
                const system = allSystems.find(sys =>
                    sys.id === e.systems.find(stkSys =>
                        stkSys.system.systemTypeId === feature.groupId
                    ).systemId
                )
                return feature.name in system.values ? system.values[feature.name]["average"] : false
            }
            return e.values[feature.name]
        }
        return feature.name in e.values ? e.values[feature.name]["average"] : false
    }

    const options = {
        chart: {
            type: "bar",
            height: height
        },
        title: {
            text: isPreview ? "" : title,
        },
        series: features.map((f, i) => {
            let featureLabel
            if ("isFormula" in f) {
                featureLabel = f.isFormula ? "Stack" : f.systemType.name
                featureLabel += " / " + f.name
            } else {
                featureLabel = f.name
            }

            return {
                name: f.name,
                unit: settings.base ? '%' : f.unit,
                stacking: settings.stacked ? 'normal' : undefined,
                data: entities.reduce((acc, e, j) => {
                    const value = getValue(e, f)
                    if (value === false) {
                        return acc
                    }
                    let baseValue
                    if ("base" in settings && settings.base) {
                        baseValue = getValue(entities[baseEntityIndex], f)
                    }
                    const categoryColor = e.categories.find(c => c.categoryGroupId === settings.colorBy) ?
                        e.categories.find(c => c.categoryGroupId === settings.colorBy).color :
                        '#800080'
                    return [...acc, {
                        x: j,
                        y: settings.base ?
                            (baseValue<0 ? -1 : 1)*(100.0 + (100.0 * (value - baseValue) / baseValue)) :
                            value,
                        value: value,
                        color: categoryColor,
                    }]
                }, []),
                states: {
                    hover: {
                        borderColor: "#ff0000",
                    },
                },
                dataLabels: [{
                    allowOverlap: true,
                    enabled: settings.valueLabels,
                    formatter: function () {
                        return (countDecimals(this.y)>2 ? this.y.toFixed(2) : this.y) + (settings.base ? ' %' : '')
                    }
                }, {
                    align: "left",
                    inside: true,
                    enabled: multipleSeries && settings.seriesLabels,
                    formatter: function () {
                        return featureLabel
                    }
                }],
                tooltip: {
                    pointFormatter: function () {
                        return featureLabel + ": " +
                            (countDecimals(this.value)>2 ? this.value.toFixed(2) : this.value) + " " + f.unit + "<br/>" +
                            (i === lastFeatureWithDataIndex[this.x] && systemsMetaData.length>0 ? "<br/>" + systemsMetaData[this.x] : "")
                    },
                },
            }
        }),
        xAxis: {
            categories: entities.map(e => e.name),
            plotBands: settings.base ? [
                {
                    color: 'orange',
                    from: baseEntityIndex-0.5,
                    to: baseEntityIndex+0.5
                }
            ] : null
        },
        yAxis: [{
            reversedStacks: false,
            title: {
                text: (multipleSeries ? features.map(f => f.name+" ["+(f.unit ? f.unit : '-')+"]") : features.map(f => f.unit)).join(", ")
            },
            lineWidth: 1,
            gridLineWidth: 1,
        }, {
            title: {
                text: (multipleSeries ? features.map(f => f.name+" ["+(f.unit ? f.unit : '-')+"]") : features.map(f => f.unit)).join(", ")
            },
            lineWidth: 1,
            gridLineWidth: 0,
            linkedTo: 0,
            opposite: true
        }],
        legend: {
            enabled: false,
        },
        tooltip: {
            shared: true,
            useHTML: true,
            followPointer: true,
        },
        ...HCExporting(true, 1504, isPreview, chart.name, xlsxRows, csvRows)
    }

    return (
        <HighchartsReact
            immutable={true}
            highcharts={Highcharts}
            options={options}
        />
    )
}