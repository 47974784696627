import React, {Fragment} from "react";
import {Col, Row} from "react-bootstrap";
import {RangeField} from "./RangeField";
import {ResetRangesButton} from "./ResetRangesButton";

export const RangeForm = ({}) => {
    return (
        <Fragment>
            <b>Range of x-axis values to show:</b>
            <Row className={"my-2"}>
                <Col>
                    <RangeField rKey={"rangeXMin"}/>
                </Col>
                <Col>
                    <RangeField rKey={"rangeXMax"}/>
                </Col>
            </Row>
            <b>Range of y-axis values to show:</b>
            <Row className={"my-2"}>
                <Col>
                    <RangeField rKey={"rangeYMin"}/>
                </Col>
                <Col>
                    <RangeField rKey={"rangeYMax"}/>
                </Col>
            </Row>
            <ResetRangesButton/>
        </Fragment>

    )
}