import gql from 'graphql-tag'


export const FRAGMENT_SYSTEM_PROPERTY_CONTENT = gql`
    fragment systemPropertyContent on SystemPropertyObject {
        value
        reference
        referenceMarin
        referenceDetail
        note
        explanation
        include
        certainty
        date
        changed
        timescale
    }
`


export const QUERY_SYSTEM_PROPERTIES = gql`
    query allData {
        systems {
            id
            name
            systemType {
                id
                name
            }
            metaData {
                id
                value
                systemTypeMetaField {
                    id
                    name
                }
            }
            systemProperties {
                id
                systemPropertyGroup { 
                    id
                    name
                    unit
                }
                ...systemPropertyContent
            }
        }
    }
    ${FRAGMENT_SYSTEM_PROPERTY_CONTENT}
`



export const MUTATION_UPDATE_SYSTEM_PROPERTY = gql`
    mutation updateSystemProperty(
        $data: UpdateSystemPropertyInput!
    ) {
        updateSystemProperty(data: $data) {
            id
            ...systemPropertyContent
        }
    }
    ${FRAGMENT_SYSTEM_PROPERTY_CONTENT}
`

// in this mutation, we add the systemPropertyGroup for front-end caching reasons, so we can efficiently update the
// QUERY_SYSTEM_PROPERTIES query with the newly added systemProperty
export const MUTATION_CREATE_SYSTEM_PROPERTY = gql`
    mutation createSystemProperty(
        $data: CreateSystemPropertyInput!
    ) {
        createSystemProperty(
            data: $data
        ) {
            id
            ...systemPropertyContent
            systemId
            systemPropertyGroup { 
                id
                name
            }
        }
    }
    ${FRAGMENT_SYSTEM_PROPERTY_CONTENT}
`


export const MUTATION_DELETE_SYSTEM_PROPERTY = gql`
    mutation deleteSystemProperty($data: DeleteSystemPropertyInput!) {
        deleteSystemProperty(data: $data) {
            id
            systemId
            ...systemPropertyContent
        }
    }
    ${FRAGMENT_SYSTEM_PROPERTY_CONTENT}
`