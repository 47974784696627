import React from "react";
import {Card} from "react-bootstrap";
import {useQuery} from "@apollo/react-hooks";
import {useParams} from 'react-router-dom';
import { QUERY_SYSTEM_TYPE } from "../../../../graphql/systemType";
import { SystemPropertyGroupSettingsTable } from "../SystemPropertyGroups";
import './../SystemTypeCard.css';


export const FeatureSettingsCard = ({...props}) => {
    const { systemTypeId } = useParams();

    const { data, loading } = useQuery(QUERY_SYSTEM_TYPE, {
        variables: { systemTypeId: systemTypeId }
    })

    if (loading) return <b>Loading....</b>

    return (
        <Card>
            <Card.Header>
                <h3>Feature settings</h3>
            </Card.Header>
            <Card.Body>
                <SystemPropertyGroupSettingsTable systemPropertyGroups={data.systemType.systemPropertyGroups} />
            </Card.Body>
        </Card>
    )
}