import gql from 'graphql-tag';

export const FRAGMENT_CHART_SETTING_CONTENT = gql`
    fragment chartSettingContent on ChartSettingObject {
        chartId
        type
        legalValues
        name
        shortName
        default
        hidden
    }
`


export const MUTATION_CREATE_CHART_SETTING = gql`
     mutation createChartSetting($chartId: ID!, $type: String!, $legalValues: String, $name: String!, $shortName: String!, $default: JSONString!, $hidden: Boolean) {
        createChartSetting(chartId: $chartId, type: $type, legalValues: $legalValues, name: $name, shortName: $shortName, default: $default, hidden: $hidden) {
            ok
            chartSetting {
                id
                ...chartSettingContent
            }
        }
    }
    ${FRAGMENT_CHART_SETTING_CONTENT}
`


export const MUTATION_UPDATE_CHART_SETTING = gql`
     mutation updateChartSetting($chartSettingId: ID!, $chartId: ID, $type: String, $legalValues: String, $name: String, $shortName: String, $default: JSONString, $hidden: Boolean) {
        updateChartSetting(chartSettingId: $chartSettingId, chartId: $chartId, type: $type, legalValues: $legalValues, name: $name, shortName: $shortName, default: $default, hidden: $hidden) {
            ok
            chartSetting {
                id
                ...chartSettingContent
            }
        }
    }
    ${FRAGMENT_CHART_SETTING_CONTENT}
`


export const MUTATION_DELETE_CHART_SETTING = gql`
    mutation deleteChartSetting($chartSettingId: ID!) {
        deleteChartSetting(chartSettingId: $chartSettingId) {
            ok
            chartSetting {
                id
                ...chartSettingContent
            }
        }
    }
    ${FRAGMENT_CHART_SETTING_CONTENT}
`

