import React, {Fragment, useContext, useState} from 'react'
import {ListGroup, ButtonGroup, Nav, Row, Col, Collapse, Button, Tooltip, OverlayTrigger} from 'react-bootstrap';
import {EditChartModalButton} from "./edit-chart";
import {DeleteChartModalButton} from "./delete-chart";
import {SustainablePowerDataContext2} from "../../../providers/SustainablePowerDataProvider2";
import {isObject} from "../../../general/functions/isObject";
import {useParams} from "react-router";
import {Route, Switch, useHistory} from "react-router-dom";
import {CaretDownFill, CaretUpFill, List, Pencil, Plus} from "react-bootstrap-icons";
import {DeleteChartFieldModalButton} from "../chart-fields/delete-chart-field";
import {AddChartFieldModalButton} from "../chart-fields/add-chart-field";
import {EditChartFieldModalButton} from "../chart-fields/edit-chart-field";
import {DeleteChartSettingModalButton} from "../chart-settings/delete-chart-setting";
import {EditChartSettingModalButton} from "../chart-settings/edit-chart-setting";
import {AddChartSettingModalButton} from "../chart-settings/add-chart-setting";
import {sortAlphabetical} from "../../../general/functions/SortAlphabetical";

export const defaultDisplay = (setting, groups) => {
    const def = JSON.parse(setting.default)

    if (setting.type === "id" || setting.type === "nullOrId" || setting.type === "idOrString") {
        if (def === null) return "not set"
        else if (def === "abc") return "Alphabetical"
        else if (def === "sum") return "Sum of bars"
        return groups[setting.legalValues].find(item => item.id === def).name
    } else if (setting.type === "listOfIds") {
        return "["+def.map(itemId => groups[setting.legalValues].find(item => item.id === itemId).name).join(", ")+"]"
    } else if (setting.type === "object" || setting.type === "objectOrString") {
        if (def === "abc") return "Alphabetical"
        else if (def === "sum") return "Sum of bars"
        return groups[setting.legalValues].find(item => item.groupId === def.groupId && item.id === def.id).name
    } else if (setting.type === "listOfObjects") {
        return "["+def.map(obj => groups[setting.legalValues].find(item => item.groupId === obj.groupId && item.id === obj.id).name).join(", ")+"]"
    } else if (setting.type === "boolean") {
        return def ? "true" : "false"
    } else if (setting.type === "number") {
        return def === null ? <i>not set</i> : def
    }
}


const Field = ({field, chartFields, parents}) => {
    if (chartFields.find(chF => chF.parentFieldId + "" === field.id) === undefined) {
        return (
            <div style={{marginLeft: parents * 20 + "px"}}>
                {field.type} ({field.id})
                <EditChartFieldModalButton chartField={field}/> <DeleteChartFieldModalButton chartField={field}/> <AddChartFieldModalButton chartId={field.chartId} parent={field}/>
            </div>
        )
    }
    return (
        <Fragment>
            <div style={{marginLeft: parents * 20 + "px"}}>
                {field.type} ({field.id})
                <EditChartFieldModalButton chartField={field}/> <DeleteChartFieldModalButton chartField={field}/> <AddChartFieldModalButton chartId={field.chartId} parent={field}/>
            </div>
            {chartFields.filter(chF => chF.parentFieldId + "" === field.id).sort((a, b) => a.index - b.index).map(field2 =>
                <Field
                    field={field2}
                    chartFields={chartFields}
                    parents={parents + 1}
                />
            )}
        </Fragment>
    )
}

export const Chart = ({chart, ...props}) => {
    const [ isSelected, setIsSelected ] = useState(chart.index===0)

    const {
        allStacks, allSystems,
        allCategoryGroups,
        allFormulas, allSystemPropertyGroups,
        allEntityTypes, allSystemTypes,
    } = useContext(SustainablePowerDataContext2)

    const entityType = allEntityTypes.find(eT => eT.id === chart.entityTypeId)
    const forStacks = entityType.discriminator === "stack_type"

    const categoryGroups = allCategoryGroups
        .filter(cg => !cg.hide)
        .filter(cg => entityType.categoryGroups.find(cg2 => cg2.id === cg.id)!==undefined)
        .sort((a, b) => a.index-b.index)

    const categories = categoryGroups.reduce((categories, cg) => [...categories, ...cg.categories], [])

    const systemPropertyGroups = allSystemPropertyGroups
        .filter(spg => forStacks || spg.systemType.id === chart.entityTypeId)
        .sort((a, b) => sortAlphabetical(a.name, b.name))
        .sort((a, b) => b.index - a.index)
        .sort((a, b) => a.index === null ? 1 : -1)
        .map(spg => {
            return {groupId: spg.systemType.id, ...spg}
        })

    const formulas = allFormulas.filter(f => !f.hide).map(f => {
        return {groupId: "6", ...f}
    })

    const formulasOrSPGs = formulas.concat(systemPropertyGroups)

    const columns = [
        {id: "0", name: 'Resource'},
        {id: "1", name: 'Energy Carrier'},
        {id: "2", name: 'Energy Conversion'},
        {id: "3", name: 'Distribution & Drive'},
        {id: "4", name: 'Emission Level'}
    ]

    const groups = {
        "systems": allSystems,
        "stacks": allStacks,
        "categoryGroups": categoryGroups,
        "categories": categories,
        "systemPropertyGroups": systemPropertyGroups,
        "formulas": formulas,
        "formulasOrSPGs": formulasOrSPGs,
        "columns": columns,
    }

    // const allFormulasOrSPGs = allFormulas.map(f => {
    //         return {groupId: "6", ...f}
    //     }).concat(allSystemPropertyGroups.map(spg => {
    //         return {groupId: spg.systemType.id, ...spg}
    //     }))

    return (
        <Fragment>
            <ListGroup.Item>
                <Row>
                    <Col sm={3}>
                        {chart.name}
                    </Col>
                    <Col sm={2}>
                        {chart.type}
                    </Col>
                    <Col sm={3}>
                        {chart.title}
                    </Col>
                    <Col sm={3}>
                        {JSON.parse(chart.features).map(chF => {
                           if (forStacks) {
                               if (isObject(chF)) {
                                   return formulasOrSPGs.find(f => f.groupId === chF.groupId && f.id === chF.id)
                               }
                               return allFormulas.find(f => f.id === chF)
                           }
                           return allSystemPropertyGroups.find(spg => spg.id === chF)
                        }).map(f => f.name).join(", ")}
                    </Col>
                    <Col sm={1}>
                        <ButtonGroup className={"float-right"}>
                            <Button onClick={() => setIsSelected(!isSelected)} size='sm'>
                                {!isSelected && <CaretDownFill/>}
                                {isSelected && <CaretUpFill/>}
                            </Button>
                            <EditChartModalButton chart={chart} size='sm' />
                            <DeleteChartModalButton chart={chart} size='sm' />
                        </ButtonGroup>
                    </Col>
                </Row>
                <Collapse in={isSelected}>
                    <Row>
                        <Col sm={5}>
                            <b>Chart fields:</b>
                            <br/>
                            {chart.chartFields.filter(chF => chF.parentFieldId === null).sort((a, b) => a.index-b.index).map(field =>
                                <Field
                                    field={field}
                                    chartFields={chart.chartFields}
                                    parents={0}
                                />
                            )}
                            {chart.chartFields
                                .filter(chF => chF.parentFieldId !== null)
                                .filter(chF =>
                                    chart.chartFields.find(chF2 => chF2.id === chF.parentFieldId+"")===undefined
                                ).map(field =>
                                    <div style={{backgroundColor: "#d4bab8"}}>
                                        {field.type} ({field.id})
                                        <EditChartFieldModalButton chartField={field}/> <DeleteChartFieldModalButton chartField={field}/> <AddChartFieldModalButton chartId={chart.id} parent={field}/> (error: this chart field has a non-existent parent field)
                                    </div>
                                )
                            }
                            <div>
                                <AddChartFieldModalButton chartId={chart.id}/>
                            </div>
                        </Col>
                        <Col sm={5}>
                            <b>Visible default settings:</b><br/>
                            {chart.chartSettings.sort((a, b) => sortAlphabetical(a.name, b.name)).filter(setting => !setting.hidden).map(setting => {
                                return (
                                    <div>
                                        {setting.name}: {defaultDisplay(setting, groups)}
                                        <EditChartSettingModalButton chartSetting={setting}/> <DeleteChartSettingModalButton chartSetting={setting}/>
                                    </div>
                                )
                            })}
                            <b>Hidden default settings (not in URL parameters):</b>
                            {chart.chartSettings.sort((a, b) => sortAlphabetical(a.name, b.name)).filter(setting => setting.hidden).map(setting => {
                                return (
                                    <div>
                                        {setting.name}: {defaultDisplay(setting, groups)}
                                        <EditChartSettingModalButton chartSetting={setting}/> <DeleteChartSettingModalButton chartSetting={setting}/>
                                    </div>
                                )
                            })}
                            <AddChartSettingModalButton chartId={chart.id}/>
                        </Col>
                    </Row>
                </Collapse>
            </ListGroup.Item>
        </Fragment>
    )
}