import React, {useContext } from 'react';
import {LoginContext} from "../../providers/LoginContextProvider";
import { QUERY_SYSTEM_TYPES } from "../../../graphql/systemType";
import { useQuery } from "@apollo/react-hooks";
import {Row, Col, Nav, Card} from 'react-bootstrap';
import { Route, Switch, useHistory } from 'react-router-dom';
import {MetaFields} from "./system-type-meta-fields/MetaFields";
import { FeatureSettingsCard} from "./property-groups/PropertyGroups";
import { Systems } from "./systems/Systems";
import {QUERY_STACK_TYPES} from "../../../graphql/stackType";
import {SystemFields} from "./stacks/system-fields/SystemFields";
import {StackFormulas} from "./stacks/formulas/formulas";
import {Stacks} from "./stacks/stacks/stacks";
import {QUERY_CATEGORY_GROUPS} from "../../../graphql/categoryGroup";
import {AddCategoryGroupModalButton} from "./category-groups/add-category-group";
import { CategoryGroupMenuItem } from "./category-groups/category-group-menu-item";
import {CategoryGroup} from "./category-groups/category-group";
import {EntityTypeCategoryGroups} from "./entity-type/category-groups";
import {QUERY_PAGES} from "../../../graphql/page";
import {Page} from "./pages/page";
import {AddPageModalButton} from "./pages/add-page";
import {PageMenuItem} from "./pages/page-menu-item";


export const Settings = (props) => {
    const history = useHistory();
    const { data, loading } = useQuery(QUERY_SYSTEM_TYPES);
    const { data: stackTypesData, loading: stackTypesLoading } = useQuery(QUERY_STACK_TYPES);
    const { data: categoryGroupsData, loading: categoryGroupsLoading } = useQuery(QUERY_CATEGORY_GROUPS);
    const { data: pagesData, loading: pagesLoading } = useQuery(QUERY_PAGES);

    const isLoggedIn = useContext(LoginContext)

    if (loading || stackTypesLoading ||categoryGroupsLoading || pagesLoading) return <b>Loading....</b>

    return isLoggedIn ? (
        <Row>
            <Col sm={3}>
                <Card>
                    <Card.Header>
                        <h3>Menu</h3>
                    </Card.Header>
                    <Card.Body>
                        <Nav variant='pills' className='flex-column' defaultActiveKey='/settings'>
                            { data.systemTypes.map((systemType, idx) => (
                                <React.Fragment key={systemType.id}>
                                    <Nav.Item key={systemType.id}>
                                        <Nav.Link eventKey={`/settings/${systemType.id}`} onSelect={() => history.push(`/settings/${systemType.id}`)}>
                                            {systemType.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Switch>
                                        <Route path={`/settings/${systemType.id}`} exact={false}>
                                            <Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link className='pl-5' eventKey={`/settings/${systemType.id}/meta-data`} onSelect={() => history.push(`/settings/${systemType.id}/meta-data`)}>
                                                        Meta data fields
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link className='pl-5' eventKey={`/settings/${systemType.id}/systems`} onSelect={() => history.push(`/settings/${systemType.id}/systems`)}>
                                                        Systems
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link className='pl-5' eventKey={`/settings/${systemType.id}/features`} onSelect={() => history.push(`/settings/${systemType.id}/features`)}>
                                                        Features
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link className='pl-5' eventKey={`/settings/${systemType.id}/category-groups`} onSelect={() => history.push(`/settings/${systemType.id}/category-groups`)}>
                                                        Category groups
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav.Item>
                                        </Route>
                                    </Switch>
                                </React.Fragment>
                            ))}
                        </Nav>
                    </Card.Body>
                </Card>
                <Card className='mt-3'>
                    <Card.Header>
                        <Row>
                            <Col>
                                <h3>Categories menu</h3>
                            </Col>
                            <Col className='text-right'>
                                <AddCategoryGroupModalButton />
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Nav variant='pills' className='flex-column' defaultActiveKey='/settings/category-groups'>
                            { categoryGroupsData.categoryGroups.map((categoryGroup, idx) => (
                                <CategoryGroupMenuItem key={categoryGroup.id} categoryGroup={categoryGroup} />
                            ))}
                        </Nav>
                    </Card.Body>
                </Card>
                <Card className='mt-3'>
                    <Card.Header>
                        <h3>Stacks menu</h3>
                    </Card.Header>
                    <Card.Body>
                        <Nav variant='pills' className='flex-column' defaultActiveKey='/settings/stacks'>
                            { stackTypesData.stackTypes.map((stackType, idx) => (
                                <React.Fragment key={stackType.id}>
                                    <Nav.Item key={stackType.id}>
                                        <Nav.Link eventKey={`/settings/stacks/${stackType.id}`} onSelect={() => history.push(`/settings/stacks/${stackType.id}`)}>
                                            {stackType.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Switch>
                                        <Route path={`/settings/stacks/${stackType.id}`} exact={false}>
                                            <Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link
                                                        className='pl-5'
                                                        eventKey={`/settings/stacks/${stackType.id}/system-fields`}
                                                        onSelect={() => history.push(`/settings/stacks/${stackType.id}/system-fields`)}
                                                    >
                                                        System fields
                                                    </Nav.Link>
                                                    <Nav.Link
                                                        className='pl-5'
                                                        eventKey={`/settings/stacks/${stackType.id}/formulas`}
                                                        onSelect={() => history.push(`/settings/stacks/${stackType.id}/formulas`)}
                                                    >
                                                        Formulas
                                                    </Nav.Link>
                                                    <Nav.Link
                                                        className='pl-5'
                                                        eventKey={`/settings/stacks/${stackType.id}/stacks`}
                                                        onSelect={() => history.push(`/settings/stacks/${stackType.id}/stacks`)}
                                                    >
                                                        Stacks
                                                    </Nav.Link>
                                                    <Nav.Link className='pl-5'
                                                              eventKey={`/settings/stacks/${stackType.id}/category-groups`}
                                                              onSelect={() => history.push(`/settings/stacks/${stackType.id}/category-groups`)}>
                                                        Category groups
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav.Item>
                                        </Route>
                                    </Switch>
                                </React.Fragment>
                            ))}
                        </Nav>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>
                                <h3>Charts menu</h3>
                            </Col>
                            <Col className='text-right'>
                                <AddPageModalButton/>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Nav variant='pills' className='flex-column' defaultActiveKey='/settings/pages'>
                            { pagesData.pages.sort((a, b) => a.index-b.index).map(page => (
                                <PageMenuItem key={page.id} page={page} />
                            ))}
                        </Nav>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Switch>
                    <Route path={`/settings/:systemTypeId/meta-data`} exact={true}>
                        <MetaFields />
                    </Route>
                    <Route path={`/settings/:systemTypeId/features`} exact={true}>
                        <FeatureSettingsCard />
                    </Route>
                    <Route path={`/settings/:systemTypeId/systems`} exact={true}>
                        <Systems />
                    </Route>
                    <Route path={`/settings/:systemTypeId/category-groups`} exact={true}>
                        <EntityTypeCategoryGroups />
                    </Route>
                    <Route path={`/settings/stacks/:stackTypeId/category-groups`} exact={true}>
                        <EntityTypeCategoryGroups />
                    </Route>
                    <Route path={`/settings/stacks/:stackTypeId/system-fields`} exact={true}>
                        <SystemFields />
                    </Route>
                    <Route path={`/settings/stacks/:stackTypeId/formulas`} exact={true}>
                        <StackFormulas />
                    </Route>
                    <Route path={`/settings/stacks/:stackTypeId/stacks`} exact={true}>
                        <Stacks />
                    </Route>
                    <Route path={`/settings/category-groups/:categoryGroupId`} exact={true}>
                        <CategoryGroup />
                    </Route>
                    <Route path={`/settings/pages/:pageId`} exact={true}>
                        <Page />
                    </Route>
                </Switch>
            </Col>
        </Row>
    ) : (
        <h3>Only logged in users can see this.</h3>
    )
}
