import React, {useContext} from "react";
import {Button, Card, Form, Row, Col, ListGroup, ButtonGroup, InputGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useQuery, useMutation} from "@apollo/react-hooks";
import {Formik, Field, useField, ErrorMessage} from 'formik'
import { useParams } from 'react-router-dom';
import { QUERY_STACK_TYPE } from "../../../../../graphql/stackType";
import { StackAddButton } from "./add-button";
import { MUTATION_UPDATE_STACK} from "../../../../../graphql/stack";
import {StackDeleteButton} from "./delete-button";
import { SustainablePowerDataContext2 } from "../../../../providers/SustainablePowerDataProvider2";
import * as Yup from "yup";
import { InfoCircle } from "react-bootstrap-icons";
import {QUERY_ENTITY_TYPE} from "../../../../../graphql/entityType";
import {SelectField} from "../../../../general/forms/SelectField";
import {QUERY_CATEGORIES} from "../../../../../graphql/category";


const SystemSelectField = ({systemTypeId, name, ...props}) => {
    const { allSystems } = useContext(SustainablePowerDataContext2);
    const [ field,, ] = useField(name);

    let options = [<option key='' disabled value=''>Choose a system type</option>]
    if (!!systemTypeId) {
        options = [...options, allSystems.filter(s => s.systemTypeId === systemTypeId).map(s => (
            <option key={s.id} value={s.id}>{s.name}</option>
        ))]
    }

    return (
        <Form.Control as='select' {...field} {...props}>
            { options }
        </Form.Control>
    )
}


const stackSchema = Yup.object().shape({
    name: Yup.string().min(2).defined(),
    systems: Yup.array().of(Yup.object().shape({
        systemId: Yup.number().defined(),
        stackId: Yup.number(),
        name: Yup.string(),
        stackTypeSystemFieldId: Yup.number(),
        stackSystemId: Yup.number(),
        systemTypeId: Yup.number(),
    }))
})


const StackForm = ({stack, entityType, stackTypeSystemFields, ...props}) => {
    const [ updateSystem ] = useMutation(MUTATION_UPDATE_STACK)
    const { data: categoriesData, loading: categoriesLoading } = useQuery(QUERY_CATEGORIES)

    const processedMetaData = stackTypeSystemFields.map(stsf => {
        const fieldData = stack.systems.find(ss => ss.stackTypeSystemFieldId === stsf.id)
        return {...stsf,
            stackSystemId: (!!fieldData) ? fieldData.id : undefined,
            systemId: (!!fieldData) ? (fieldData.systemId || ''): ''}
    })

    const initialValues = {
        name: stack.name,
        systems: processedMetaData.map(pmd => ({
            stackId: stack.id,
            systemId: pmd.systemId,
            stackTypeSystemFieldId: pmd.id,
            stackSystemId: pmd.stackSystemId,
            systemTypeId: pmd.systemTypeId,
            name: pmd.name
        })),
        categories: entityType.categoryGroups.map(cg => {
            const chosenCat = stack.categories.find(c => c.categoryGroupId === cg.id);
            return !!chosenCat ? {categoryGroupId: cg.id, categoryId: chosenCat.id} : {categoryGroupId: cg.id, categoryId: ''}
        }),
    }


    return (
        <Formik
            validateOnMount={true}
            initialTouched={{systems: true}}
            validationSchema={stackSchema}
            enableReinitialize={false}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting, resetForm }) => updateSystem({
                variables: {
                    data: {
                        stackId: stack.id,
                        name: values.name,
                        systems: values.systems.map(md => ({
                            stackId: stack.id,
                            systemId: md.systemId,
                            stackTypeSystemFieldId: md.stackTypeSystemFieldId,
                            stackSystemId: md.stackSystemId,
                        })),
                        categories: values.categories.filter(c => c.categoryId !== ''),
                    }
                }
            }).then(({data: { updateStack }}) => {
                setSubmitting(false)
                resetForm({
                    values: {
                        name: values.name,
                        systems: values.systems.map(sm => ({
                            ...sm,
                            stackSystemId: updateStack.stack.systems.find(s => s.systemId === sm.systemId).id,
                        })),
                        categories: values.categories,
                    }
                })
            })}
        >
            {({ values, errors, isValid, initialValues, initialTouched, handleSubmit, setErrors, handleReset, resetForm, validateForm, dirty} ) => (
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                    <Row className='mb-3'>
                        <Col>
                            <h5>{stack.name}</h5>
                        </Col>
                        <Col className='text-right'>
                            <ButtonGroup>
                                <Button type='submit' disabled={!isValid || !dirty} variant={(dirty) ? 'warning' : 'primary'}>Submit</Button>
                                <Button type='reset' onClick={resetForm}>Reset</Button>
                            </ButtonGroup>
                            <ButtonGroup className='ml-2'>
                                <StackDeleteButton stack={stack} variant='danger'>Delete</StackDeleteButton>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group as={Row}>
                                <Col md={3}>
                                    <Form.Label>Name</Form.Label>
                                </Col>
                                <Col>
                                    <Field as={Form.Control} name='name'/>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            {values.systems.map((md, idx) => (
                                <Form.Group as={Row} key={idx}>
                                    <Col md={3}>
                                        <Form.Label>{md.name}</Form.Label>
                                    </Col>
                                    <Col>
                                        <InputGroup>
                                            <SystemSelectField name={`systems[${idx}].systemId`} systemTypeId={md.systemTypeId} />
                                            {(errors.systems) ? (
                                                (errors.systems[idx] ? (
                                                    <InputGroup.Append>
                                                        <Button disabled variant='danger'>
                                                            <InfoCircle />
                                                        </Button>
                                                    </InputGroup.Append>
                                                ) : null)
                                            ) : null}
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                            ))}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6>Categories</h6>
                        </Col>
                    </Row>
                    { (categoriesLoading) ? (
                        <Row><Col>Loading category group data.....</Col></Row>
                    ) : (
                        entityType.categoryGroups.map((categoryGroup, cgIdx) => (
                            <Form.Group as={Row} key={categoryGroup.id}>
                                <Col>
                                    <Form.Label>{categoryGroup.name}</Form.Label>
                                </Col>
                                <Col>
                                    <SelectField name={`categories[${cgIdx}].categoryId`}>
                                        <option value=''>No category</option>
                                        { categoriesData.categoryGroups.find(c => c.id === categoryGroup.id).categories.map(c => (
                                            <option key={c.id} value={c.id}>{c.name}</option>
                                        ))}
                                    </SelectField>
                                </Col>
                            </Form.Group>
                        ))
                    )}
                </Form>
            )}
        </Formik>
    )
}


const StackSettingsTable = ({stacks, stackTypeId, stackTypeSystemFields, ...props}) => {
    const { data, loading } = useQuery(QUERY_ENTITY_TYPE, {
        variables: {entityTypeId: stackTypeId}
    })

    if (loading) return 'Loading.....'

    return (
        <ListGroup variant='flush'>
            {stacks.map(s => {
                return (
                    <ListGroup.Item key={s.id} className='px-0'>
                        <div>
                            <StackForm stack={s} entityType={data.entityType} stackTypeSystemFields={stackTypeSystemFields} />
                        </div>
                    </ListGroup.Item>
                )
            })}
        </ListGroup>
    )
}


export const Stacks = ({...props}) => {
    const { stackTypeId } = useParams();

    const { data, loading } = useQuery(QUERY_STACK_TYPE, {
        variables: { stackTypeId: stackTypeId }
    })

    if (loading) return <b>Loading....</b>

    return (
        <Card>
            <Card.Header>
                <div className='d-flex'>
                    <h3 className='mr-auto'>Stacks</h3>
                    <StackAddButton>Add stack</StackAddButton>
                </div>
            </Card.Header>
            <Card.Body>
                <StackSettingsTable stackTypeId={stackTypeId} stacks={data.stackType.stacks} stackTypeSystemFields={data.stackType.systemFields} />
            </Card.Body>
        </Card>
    )
}