import {Form} from "react-bootstrap";
import React, {useContext} from "react";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {ChartContext} from "../charts/Chart";

export const FeaturesSortByRadios = ({}) => {
    const { allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const { chart, settings, setURL } = useContext(ChartContext)

    return (
        <div className={"mt-2 mb-3"}>
            {JSON.parse(chart.features).map(fId =>
                <Form.Check
                    type={"radio"}
                    value={fId}
                    onChange={(e) => {
                        settings.sortBy = e.target.value
                        setURL(chart, settings)
                        setAllSettings({...allSettings})
                    }}
                    checked={settings.sortBy === fId}
                />
            )}
        </div>
    )
}