import React, { useContext } from 'react'
import {StackFormik} from "./stack-formik";
import {Field} from 'formik'
import {Button, Form, Spinner} from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import {useMutation} from "@apollo/react-hooks";
import { MUTATION_CREATE_STACK} from "../../../../../graphql/stack";
import { QUERY_STACK_TYPE } from "../../../../../graphql/stackType";
import {ModalFormContext} from './add-modal';


export const StackAddForm = (props) => {
    const {closeModal} = useContext(ModalFormContext);
    const { stackTypeId } = useParams();
    const [ createStack, { loading } ] = useMutation(MUTATION_CREATE_STACK)

    function onSubmit(values, { setSubmitting }) {
        createStack({
            variables: {
                data: {
                    stackTypeId: stackTypeId,
                    name: values.name,
                }
            },
            refetchQueries: [{query: QUERY_STACK_TYPE, variables: {stackTypeId: stackTypeId}}],
            awaitRefetchQueries: true,
        }).then(
            () => setSubmitting(false)
        ).then(
            () => closeModal()
        )
    }

    return (
        <StackFormik
            initialValues={{
                name: '',
            }}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleReset, isSubmitting}) => (
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Field as={Form.Control} name='name' placeholder='Name' />
                    </Form.Group>
                    <Button type='submit'>Save { (loading || isSubmitting) ? <Spinner animation='border' size='sm' /> : null }</Button>
                </Form>
                )}
        </StackFormik>
    )
}