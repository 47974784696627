import React from 'react'
import {Formik} from 'formik'
import * as Yup from 'yup'


const systemValidationSchema = Yup.object().shape({
})


export const SystemFormik = ({initialValues, onSubmit, ...props}) => {
    return (
        <Formik
            validationSchema={systemValidationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {props.children}
        </Formik>
    )
}