import React from 'react'
import {Nav, Row, Col, ButtonGroup} from "react-bootstrap";
import { useHistory } from "react-router";
import { DeleteCategoryGroupModalButton } from './delete-category-group';
import {EditCategoryGroupModalButton} from "./edit-category-group";


export const CategoryGroupMenuItem = ({categoryGroup, ...props}) => {
    const history = useHistory();

    const onClickEditButton = (e) => {
        e.stopPropagation();
    }

    return (
        <Nav.Item key={categoryGroup.id}>
            <Nav.Link
                eventKey={`/settings/category-groups/${categoryGroup.id}`}
                onSelect={() => history.push(`/settings/category-groups/${categoryGroup.id}`)}
            >
                <Row>
                    <Col>{categoryGroup.name}</Col>
                    <Col className='text-right'>
                        <ButtonGroup onClick={onClickEditButton} onKeyDown={onClickEditButton}>
                            <DeleteCategoryGroupModalButton categoryGroup={categoryGroup} />
                            <EditCategoryGroupModalButton categoryGroup={categoryGroup} />
                        </ButtonGroup>
                    </Col>
                </Row>
            </Nav.Link>
        </Nav.Item>
    )
}