import React from "react";
import {Card, Row, Col, ListGroup, ButtonGroup} from "react-bootstrap";
import {useQuery} from "@apollo/react-hooks";
import { QUERY_SYSTEM_TYPE } from "../../../../graphql/systemType";
import {
    SystemTypeMetaFieldCreateModalFormButton,
    SystemTypeMetaFieldEditModalFormButton,
    SystemTypeMetaFieldDeleteModalFormButton,
} from './SystemTypeMetaField';
import './../SystemTypeCard.css';
import { Pencil, Trash } from "react-bootstrap-icons";
import { useParams } from 'react-router-dom'


const SystemTypeMetaFieldDisplay = ({systemTypeMetaField, systemTypeId, ...props}) => {
    return (
        <ListGroup.Item>
            <Row>
                <Col sm={3}>{systemTypeMetaField.name}</Col>
                <Col sm={6}>{systemTypeMetaField.description}</Col>
                <Col sm={1}>{systemTypeMetaField.index}</Col>
                <Col sm={2}>
                    <ButtonGroup size='sm' className='w-100'>
                        <SystemTypeMetaFieldEditModalFormButton systemTypeId={systemTypeId} systemTypeMetaField={systemTypeMetaField}>
                            <Pencil size={18} />
                        </SystemTypeMetaFieldEditModalFormButton>
                        <SystemTypeMetaFieldDeleteModalFormButton systemTypeMetaField={systemTypeMetaField}>
                            <Trash size={18} />
                        </SystemTypeMetaFieldDeleteModalFormButton>
                    </ButtonGroup>
                </Col>
            </Row>
        </ListGroup.Item>
    )
}


export const MetaFields = ({...props}) => {
    const { systemTypeId } = useParams();

    const { data, loading } = useQuery(QUERY_SYSTEM_TYPE, {
        variables: { systemTypeId: systemTypeId },
        onError: error => console.log(error),
    })

    if (loading) return <b>Loading....</b>

    const metaFieldsData = data.systemType.metaFields.slice()
    const metaFieldsDataSorted = metaFieldsData.sort((mf0, mf1) => mf0.index >= mf1.index)
    const metaFields = metaFieldsDataSorted.map(mf => {
        return <SystemTypeMetaFieldDisplay key={mf.id} systemTypeMetaField={mf} systemTypeId={systemTypeId} />
    })

    return (
        <Card>
            <Card.Header>
                <h3>Meta data fields</h3>
            </Card.Header>
            <Card.Body>
                <ListGroup variant='flush'>
                    { metaFields }
                </ListGroup>
                <SystemTypeMetaFieldCreateModalFormButton systemType={data.systemType}>
                    Add
                </SystemTypeMetaFieldCreateModalFormButton>
            </Card.Body>
        </Card>
    )
}