import React, {Fragment, useContext, useState} from 'react';
import {Modal, OverlayTrigger, Tooltip, Button, ButtonGroup, Form, Row, Col} from 'react-bootstrap';
import {useMutation} from "@apollo/react-hooks";
import {Formik, Field, FieldArray} from 'formik'
import {BootstrapSelector} from "../BootstrapSelector";
import {FormulaOrSPGSelector} from "../FormulaOrSPGSelector";
import {SustainablePowerDataContext2} from "../../../providers/SustainablePowerDataProvider2";
import {sortAlphabetical} from "../../../general/functions/SortAlphabetical";
import {MUTATION_UPDATE_CHART_FIELD} from "../../../../graphql/chartField";


const EditChartFieldModal = ({chartField, show, setShow, ...props}) => {
    const { allCharts, allSystemPropertyGroups, allStacks, allSystems, allChartFieldProps } = useContext(SustainablePowerDataContext2)
    const chart = allCharts.find(ch => ch.id === chartField.chartId)

    const [ updateChartField, { loading }] = useMutation(MUTATION_UPDATE_CHART_FIELD)

    const onSubmit = (values, {setSubmitting}) => {
        updateChartField({
            variables: {
                chartFieldId: chartField.id,
                chartId: chartField.chartId,
                parentFieldId: values.parentFieldId,
                index: values.index,
                type: values.type,
                props: JSON.stringify(values.props.reduce((acc, p, i) => {
                        const prop = allChartFieldProps[values.type][i]
                        let pValue = p
                        if (prop.type === "boolean") {
                            pValue = !!Number(p)
                        } else if (prop.type === "colWidths") {
                            if (!isNaN(Number(p))) {
                                pValue = Number(p)
                            } else {
                                pValue = (p === "notset" ? false : p)
                            }
                        }
                        acc[prop.name] = pValue
                        return acc
                    }, {className: values.className})),
                label: values.label
            }
        }).then(() => setSubmitting(false)).then(() => setShow(false))
    }

    const chartFieldProps = JSON.parse(chartField.props)

    const initialValues = {
        parentFieldId: chartField.parentFieldId,
        index: chartField.index,
        type: chartField.type,
        props: allChartFieldProps[chartField.type].map(p =>
            chartFieldProps !== null && p.name in chartFieldProps ?
            chartFieldProps[p.name] :
            p.default
        ),
        className: chartFieldProps !== null && "className" in chartFieldProps ? chartFieldProps["className"] : "",
        label: chartField.label,
    }

    return (
        <Modal show={show} onHide={() => setShow(false)} onClick={e => e.stopPropagation()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit chart field "{chartField.type} ({chartField.id})"
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {({values, handleSubmit, handleReset, isSubmitting, setFieldValue}) => (
                        <Form onSubmit={handleSubmit} onReset={handleReset}>
                            <Form.Group>
                                <Form.Label>Parent field:</Form.Label>
                                <Field component={BootstrapSelector} name='parentFieldId'>
                                    <option value={null}>NONE</option>
                                    {chart.chartFields.sort((a, b) => a.id-b.id).map(chF => <option value={chF.id}>{chF.type} ({chF.id})</option>)}
                                </Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Index:</Form.Label>
                                <Field as={Form.Control} name='index'/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Field type:</Form.Label>
                                {/*<Field component={BootstrapSelector} name='type'>*/}
                                {/*    {Object.keys(allChartFieldProps).map(t => <option value={t}>{t}</option>)}*/}
                                {/*</Field>*/}
                                <Form.Control as={"select"} onChange={e => {
                                    setFieldValue("props", allChartFieldProps[e.target.value].map(p => p.default))
                                    setFieldValue("type", e.target.value)
                                }}>
                                    {Object.keys(allChartFieldProps).map(t => <option value={t}>{t}</option>)}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                {allChartFieldProps[values.type].length>0 && <Form.Label>Field properties:</Form.Label>}
                                <FieldArray
                                    name={"props"}
                                    render={arrayHelpers => (
                                        values.props.map((p, i) => {
                                            const prop = allChartFieldProps[values.type][i]
                                            return (
                                                <Row>
                                                    <Col lg={5}>{prop.displayName}:</Col>
                                                    <Col>
                                                        {prop.type === "boolean" &&
                                                            <Field component={BootstrapSelector} name={`props.${i}`} defaultValue={0}>
                                                                <option value={0}>FALSE</option>
                                                                <option value={1}>TRUE</option>
                                                            </Field>
                                                        }
                                                        {prop.type === "int" &&
                                                            <Field as={Form.Control} name={`props.${i}`} defaultValue={0}/>
                                                        }
                                                        {prop.type === "string" &&
                                                            <Field as={Form.Control} name={`props.${i}`}/>
                                                        }
                                                        {prop.type === "colWidths" &&
                                                            <Field component={BootstrapSelector} name={`props.${i}`}>
                                                                <option value={"notset"}>Not set</option>
                                                                <option value={"auto"}>Natural width of content (auto)</option>
                                                                <option value={1}>1/12</option>
                                                                <option value={2}>2/12</option>
                                                                <option value={3}>3/12</option>
                                                                <option value={4}>4/12</option>
                                                                <option value={5}>5/12</option>
                                                                <option value={6}>6/12</option>
                                                                <option value={7}>7/12</option>
                                                                <option value={8}>8/12</option>
                                                                <option value={9}>9/12</option>
                                                                <option value={10}>10/12</option>
                                                                <option value={11}>11/12</option>
                                                                <option value={12}>12/12</option>
                                                            </Field>
                                                        }
                                                        {prop.type === "chartSetting" &&
                                                            <Field component={BootstrapSelector} name={`props.${i}`}>
                                                                <option value={"notset"}>Not set</option>
                                                                {chart.chartSettings.filter(chS => chS.type === "boolean").map(chS => <option value={chS.name}>{chS.name}</option>)}
                                                            </Field>
                                                        }
                                                        {prop.type === "features" && chart.entityTypeId === "6" &&
                                                            <Field component={FormulaOrSPGSelector} notSet={true} name={`props.${i}`}/>
                                                        }
                                                        {prop.type === "features" && chart.entityTypeId !== "6" &&
                                                            <Field component={BootstrapSelector} name={`props.${i}`}>
                                                                <option value={"notset"}>Not set</option>
                                                                {allSystemPropertyGroups
                                                                    .sort((a, b) => sortAlphabetical(a.name, b.name))
                                                                    .sort((a, b) => b.index - a.index)
                                                                    .sort((a, b) => a.index === null ? 1 : -1)
                                                                    .filter(spg => spg.systemType.id === chart.entityTypeId)
                                                                    .map(spg =>
                                                                        <option value={spg.id}>{spg.name}</option>
                                                                    )
                                                                }
                                                            </Field>
                                                        }
                                                        {prop.type === "entities" &&
                                                            <Field component={BootstrapSelector} name={`props.${i}`}>
                                                                <option value={"notset"}>Not set</option>
                                                                {(chart.entityTypeId === "6" ? allStacks : allSystems)
                                                                    .sort((a, b) => sortAlphabetical(a.name, b.name))
                                                                    .map(e =>
                                                                        <option value={e.id}>{e.name}</option>
                                                                    )
                                                                }
                                                            </Field>
                                                        }
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    )}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>className:</Form.Label>
                                <Field as={Form.Control} name='className'/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Label:</Form.Label>
                                <Field as={Form.Control} name='label'/>
                            </Form.Group>
                            <Form.Group>
                                <ButtonGroup className='w-100'>
                                    <Button disabled={loading || isSubmitting} type='submit'>Submit</Button>
                                    <Button variant='danger' onClick={() => setShow(false)}>Cancel</Button>
                                </ButtonGroup>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}


export const EditChartFieldModalButton = ({chartField, ...props}) => {
    const [ show, setShow ] = useState(false);

    const openModal = (e) => {
        e.stopPropagation();
        setShow(true);
    }

    return (
        <React.Fragment>
            <OverlayTrigger overlay={<Tooltip id='edit-chart-field-tooltip-id'>Edit this chart field</Tooltip>}>
                <span style={{marginLeft: "10px", color: "blue", cursor: "pointer"}} onClick={openModal}>edit</span>
            </OverlayTrigger>
            <EditChartFieldModal show={show} setShow={setShow} chartField={chartField} />
        </React.Fragment>
    )
}
