import React, {Fragment, useContext, useState} from "react";
import {Button, ButtonGroup, Col, Form, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {Plus} from "react-bootstrap-icons";
import {useMutation} from "@apollo/react-hooks";
import {MUTATION_CREATE_CHART, QUERY_CHARTS} from "../../../../graphql/chart";
import {Field, FieldArray, Formik} from "formik";
import {SustainablePowerDataContext2} from "../../../providers/SustainablePowerDataProvider2";
import {useParams} from "react-router";
import {BootstrapSelector} from "../BootstrapSelector";
import {FormulaOrSPGSelector} from "../FormulaOrSPGSelector";
import {sortAlphabetical} from "../../../general/functions/SortAlphabetical";


const AddChartModal = ({show, setShow, ...props}) => {
    const { pageId } = useParams();
    const { allPages, allEntityTypes, allSystemPropertyGroups, allFormulas } = useContext(SustainablePowerDataContext2)
    const page = allPages.find(p => p.id === pageId)

    const [ createChart, { loading }] = useMutation(MUTATION_CREATE_CHART, {
        refetchQueries: [{query: QUERY_CHARTS}],
    })

    const onSubmit = (values, {setSubmitting}) => {
        createChart({
            variables: {
                pageId: values.pageId,
                entityTypeId: values.entityTypeId,
                index: values.index,
                secured: !!Number(values.secured),
                type: values.type,
                name: values.name,
                features: JSON.stringify(values.features),
                slug: values.slug,
                title: values.title,
                cardImg: values.cardImg === "" ? null : values.cardImg
            }
        }).then(() => setSubmitting(false)).then(() => setShow(false))
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Add a new chart
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        pageId: pageId,
                        entityTypeId: page.charts.length>0 ? page.charts[0].entityTypeId : "6",
                        index: page.charts.length>0 ? page.charts.reduce((acc, ch) => ch.index>acc ? ch.index : acc, 0)+1 : 0,
                        secured: 0,
                        type: "bar",
                        name: "",
                        features: [],
                        slug: "",
                        title: "",
                        cardImg: "",
                        featuresType: "formulas"
                    }}
                    onSubmit={onSubmit}
                >
                    {({values, handleSubmit, handleReset, isSubmitting, setFieldValue}) => (
                        <Form onSubmit={handleSubmit} onReset={handleReset}>
                            <Form.Group>
                                <Form.Label>Page:</Form.Label>
                                <Field component={BootstrapSelector} name='pageId'>
                                    {allPages.sort((a, b) => a.index-b.index).map(p => <option value={p.id}>{p.name}</option>)}
                                </Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Entity type:</Form.Label>
                                <Field component={BootstrapSelector} name='entityTypeId'>
                                    {allEntityTypes.sort((a, b) => a.id-b.id).map(eT => <option value={eT.id}>{eT.name}</option>)}
                                </Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Index:</Form.Label>
                                <Field as={Form.Control} name='index'/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Secured:</Form.Label>
                                <Field as={BootstrapSelector} name='secured'>
                                    <option value={1}>TRUE</option>
                                    <option value={0}>FALSE</option>
                                </Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Type:</Form.Label>
                                <Field component={BootstrapSelector} name='type'>
                                    <option value={"bar"}>bar</option>
                                    <option value={"bubble"}>bubble</option>
                                    <option value={"boxBar"}>boxBar</option>
                                    <option value={"columnRangeAndBar"}>columnRangeAndBar</option>
                                    <option value={"flow"}>flow</option>
                                </Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Name:</Form.Label>
                                <Field as={Form.Control} name='name'/>
                            </Form.Group>
                            {values.entityTypeId === "6" &&
                                <Form.Group>
                                    <Form.Label>Type of features:</Form.Label>
                                    <Form.Control as='select'
                                       onChange={(e) => {
                                           setFieldValue("features", [])
                                           setFieldValue("featuresType", e.target.value)
                                       }}
                                    >
                                        <option value={"formulas"}>Only formulas</option>
                                        <option value={"formulasOrSPGs"}>Formulas or SPGs</option>
                                    </Form.Control>
                                </Form.Group>
                            }
                            <Form.Group>
                                <Form.Label>Features:</Form.Label>
                                <FieldArray
                                    name={"features"}
                                    render={arrayHelpers => (
                                        <Fragment>
                                            {values.features.map((f, i) => {
                                                return (
                                                    <Row>
                                                        <Col>
                                                            {values.entityTypeId === "6" && values.featuresType === "formulasOrSPGs" &&
                                                                <Field component={FormulaOrSPGSelector} name={`features.${i}`}/>
                                                            }
                                                            {values.entityTypeId === "6" && values.featuresType === "formulas" &&
                                                                <Field component={BootstrapSelector} name={`features.${i}`}>
                                                                    {allFormulas
                                                                        .filter(f => !f.hide)
                                                                        .sort((a, b) => sortAlphabetical(a.name, b.name))
                                                                        .map(f =>
                                                                            <option value={f.id}>{f.name}</option>
                                                                        )
                                                                    }
                                                                </Field>
                                                            }
                                                            {values.entityTypeId !== "6" &&
                                                                <Field component={BootstrapSelector} name={`features.${i}`}>
                                                                    {allSystemPropertyGroups
                                                                        .sort((a, b) => sortAlphabetical(a.name, b.name))
                                                                        .sort((a, b) => b.index - a.index)
                                                                        .sort((a, b) => a.index === null ? 1 : -1)
                                                                        .filter(spg => spg.systemType.id === values.entityTypeId)
                                                                        .map(spg =>
                                                                            <option value={spg.id}>{spg.name}</option>
                                                                        )
                                                                    }
                                                                </Field>
                                                            }
                                                        </Col>
                                                        <Col sm={3}>
                                                            <Button onClick={() => arrayHelpers.remove(i)}>Remove</Button>
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                            <Button onClick={() => {
                                                if (values.entityTypeId === "6") {
                                                    if (values.featuresType === "formulasOrSPGs") {
                                                        arrayHelpers.push({"groupId": "6",  "id": "5"})
                                                    } else {
                                                        arrayHelpers.push("5")
                                                    }
                                                } else {
                                                    arrayHelpers.push("13")
                                                }
                                            }}>Add feature</Button>
                                        </Fragment>
                                    )}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Slug:</Form.Label>
                                <Field as={Form.Control} name='slug' />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Title:</Form.Label>
                                <Field as={Form.Control} name='title' />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Path to card image:</Form.Label>
                                <Field
                                    component={({field}) => <Form.Control placeholder={field.value === "" ? "no card image" : ""}/>}
                                    name={'cardImg'}
                                />
                            </Form.Group>
                            <Form.Group>
                                <ButtonGroup className='w-100'>
                                    <Button disabled={loading || isSubmitting} type='submit'>Submit</Button>
                                    <Button variant='danger' onClick={() => setShow(false)}>Cancel</Button>
                                </ButtonGroup>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export const AddChartModalButton = ({props}) => {
    const [ show, setShow ] = useState(false);

    return (
        <React.Fragment>
            <OverlayTrigger overlay={<Tooltip id='add-chart-tooltip-id'>Add a chart</Tooltip>}>
                <Button onClick={() => setShow(true)} size='sm'>
                    <Plus size={20}/>
                </Button>
            </OverlayTrigger>
            <AddChartModal show={show} setShow={setShow} />
        </React.Fragment>
    )
}