import React, {useContext} from "react";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {ChartContext} from "../charts/Chart";
import {entityInSelectedCategories} from "../functions/entityInSelectedCategories";
import Select from "react-select";

export const CategoriesSelector = ({}) => {
    const { allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const { chart, setURL, settings, allEntities, categoryGroups, categories, disabledEntities } = useContext(ChartContext)

    function onCategoriesSelectedChanged(event) {
        const newSelectedCategories = event.map(c => c.value)

        settings.selectedCategories = newSelectedCategories
        if ("disabledEntities" in settings) {
            settings.disabledEntities = allEntities
                .filter(e => disabledEntities.includes(e.id) || !entityInSelectedCategories(e, newSelectedCategories, categories))
                .map(e => e.id)
        }
        setURL(chart, settings)
        setAllSettings({...allSettings})
    }

    let options = []
    let selectedCategoryLabels = []
    categoryGroups.forEach((cg, i) => {
        options.push({
            label: cg.name,
            options: []
        })

        const categoriesNotInThisCG = categories.filter(c => c.categoryGroupId !== cg.id)
        const selectedCategoriesNotInThisCG = settings.selectedCategories.filter(cId => categoriesNotInThisCG.find(c => c.id === cId)!==undefined)

        cg.categories.sort((a, b) => a.index - b.index).forEach(c => {
            const entitiesInCurrentCategory = allEntities.filter(e => entityInSelectedCategories(e, [c.id], categories))
            const entitiesInSelectedCategoriesFromOtherCGs = allEntities.filter(e => entityInSelectedCategories(e, selectedCategoriesNotInThisCG, categories))
            const entitiesInBoth = entitiesInSelectedCategoriesFromOtherCGs.filter(e => entitiesInCurrentCategory.includes(e))

            const categoryLabel = {
                label: c.name+" ("+entitiesInBoth.length+"/"+entitiesInCurrentCategory.length+" stack"+(entitiesInCurrentCategory.length !== 1 ? "s" : "")+")",
                value: c.id,
                isDisabled: entitiesInBoth.length===0,
                isHidden: settings.selectedCategories.find(cId => cId === c.id)!==undefined
            }

            options[i].options.push(categoryLabel)
            if (settings.selectedCategories.find(cId => cId === c.id)!==undefined) {
                selectedCategoryLabels.push(categoryLabel)
            }
        })
    })

    return (
        <Select
            className={"mt-2 mb-3"}
            onChange={onCategoriesSelectedChanged}
            value={selectedCategoryLabels}
            options={options}
            isMulti
        />
    )
}