import {Col, Row} from "react-bootstrap";
import React, {Fragment, useContext} from "react";
import {ChartContext} from "../charts/Chart";

export const ColorLegend = ({}) => {
    const { settings, categoryGroups } = useContext(ChartContext)

    const legendItems = [{
        name: 'Uncategorized',
        color: '#800080'
    }].concat(categoryGroups.find(cg => cg.id === settings.colorBy).categories.sort((a, b) => a.index - b.index))
    let legend = []
    for (let i = 0; i < legendItems.length; i += 3) {
        let legendRow = [legendItems[i]]
        if (i + 1 < legendItems.length) {
            legendRow.push(legendItems[i + 1])
            if (i + 2 < legendItems.length) {
                legendRow.push(legendItems[i + 2])
            }
        }
        legend.push(legendRow)
    }

    return (
        <div className={'mb-3'}>
            {legend.map(row =>
                <Row className={'mt-1'}>
                    {row.map(c =>
                        <Fragment>
                            <Col lg={1} style={{backgroundColor: c.color}}/>
                            <Col lg={3}>{c.name}</Col>
                        </Fragment>
                    )}
                </Row>
            )}
        </div>
    )
}