import React, {useContext} from "react";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {Form} from "react-bootstrap";
import {ChartContext} from "../charts/Chart";

export const SPGSelector = ({fKey, forSort=false, all=true}) => {
    const { allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const { chart, settings, defaultSettings, setURL, features, systemPropertyGroups } = useContext(ChartContext)

    const options = all ? systemPropertyGroups : features

    return (
        <Form.Control
            className={'my-2'}
            as='select'
            value={forSort ? settings.sortBy : settings.selectedFeatures[fKey]}
            onChange={(e) => {
                if (forSort) {
                    settings.sortBy = e.target.value
                } else {
                    settings.selectedFeatures[fKey] = e.target.value
                }
                setURL(chart, settings)
                setAllSettings({...allSettings})
            }}
        >
            {forSort && <option value={"abc"}>Alphabetical</option>}
            {forSort && chart.type === "bar" && <option value={"sum"}>Sum of bars</option>}
            {forSort && options.map(spg => <option value={spg.id}>{spg.name}</option>)}
            {!forSort && options.filter(spg => spg.id === defaultSettings.selectedFeatures[fKey]).concat(options.filter(spg => spg.id !== defaultSettings.selectedFeatures[fKey])).map(spg =>
                <option value={spg.id}>{spg.name}</option>
            )}
        </Form.Control>
    )
}