import React, { useState } from 'react';
import {Modal, OverlayTrigger, Tooltip, Button, ButtonGroup, Form} from 'react-bootstrap';
import {Pencil} from "react-bootstrap-icons";
import {useMutation} from "@apollo/react-hooks";
import {MUTATION_UPDATE_PAGE} from "../../../../graphql/page";
import {Formik, Field} from 'formik'


const EditPageModal = ({page, show, setShow, ...props}) => {
    const [ updatePage, { loading }] = useMutation(MUTATION_UPDATE_PAGE)

    const onSubmit = (values, {setSubmitting}) => {
        updatePage({
            variables: {
                pageId: page.id,
                name: values.name,
                slug: values.slug,
                index: values.index
            }
        }).then(() => setSubmitting(false)).then(() => setShow(false))
    }

    const initialValues = {
        name: page.name,
        slug: page.slug,
        index: page.index,
    }

    return (
        <Modal show={show} onHide={() => setShow(false)} onClick={e => e.stopPropagation()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit page "{page.name}"
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {({values, handleSubmit, handleReset, isSubmitting}) => (
                        <Form onSubmit={handleSubmit} onReset={handleReset}>
                            <Form.Group>
                                <Form.Label>Name:</Form.Label>
                                <Field as={Form.Control} name='name'/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Slug:</Form.Label>
                                <Field as={Form.Control} name='slug' />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Index:</Form.Label>
                                <Field as={Form.Control} name='index' />
                            </Form.Group>
                            <Form.Group>
                                <ButtonGroup className='w-100'>
                                    <Button disabled={loading || isSubmitting} onClick={handleSubmit} type='submit'>Submit</Button>
                                    <Button variant='danger' onClick={() => setShow(false)}>Cancel</Button>
                                </ButtonGroup>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}


export const EditPageModalButton = ({page, ...props}) => {
    const [ show, setShow ] = useState(false);

    const openModal = (e) => {
        e.stopPropagation();
        setShow(true);
    }

    return (
        <React.Fragment>
            <OverlayTrigger overlay={<Tooltip id='edit-page-tooltip-id'>Edit this page</Tooltip>}>
                <Button onClick={openModal} size='sm'>
                    <Pencil size={20}/>
                </Button>
            </OverlayTrigger>
            <EditPageModal show={show} setShow={setShow} page={page} />
        </React.Fragment>
    )
}
