import React from 'react'
import {useQuery} from "@apollo/react-hooks";
import {QUERY_SYSTEM_PROPERTY_GROUPS} from "../../graphql/queries/system-property-group";
import {QUERY_SYSTEM_PROPERTIES} from "../../graphql/systemProperty";
import {QUERY_SYSTEMS} from "../../graphql/system";
import {filterData} from "../../hooks/filter";


export const SustainablePowerDataContext = React.createContext([])


export const SustainablePowerDataProvider = (props) => {
    const {data = {systems: []}, loading: systemPropertiesLoading } = useQuery(QUERY_SYSTEM_PROPERTIES, {
        onError: error => { console.log(error) },
    })

    const {data: {systemPropertyGroups = []} = {}, loading: systemPropertyGroupsLoading} = useQuery(QUERY_SYSTEM_PROPERTY_GROUPS, {
        onError: error => { console.log(error) },
    })

    const {data: {systems = []} = {}, loading: systemsLoading} = useQuery(QUERY_SYSTEMS, {
        onError: error => { console.log(error) },
    })

    const systemProperties = data.systems.reduce((propertyList, system, currentIdx) => {
        const systemMetaData = system.metaData
        const systemMetaDataDescription = (!!systemMetaData) ? systemMetaData.find(smd => smd.systemTypeMetaField.name === 'Description') : undefined;
        let systemDescription;
        if (!!systemMetaDataDescription) {
            systemDescription = systemMetaDataDescription.value;
        } else {
            systemDescription = '';
        }

        const systemProperties = system.systemProperties.map(property => ({
            systemName: system.name,
            systemId: system.id,
            systemDescription: systemDescription,
            systemTypeName: system.systemType.name,
            systemTypeId: system.systemType.id,
            systemPropertyGroupName: property.systemPropertyGroup.name,
            systemPropertyGroupUnit: property.systemPropertyGroup.unit,
            systemPropertyGroupId: property.systemPropertyGroup.id,
            ...property,
            date: new Date(property.date),
            changed: new Date(property.changed),
            calculated: false,
        }))

        return [...propertyList, ...systemProperties]
    }, [])

    // per energy-carrier system, calculate average value of that emission
    const energyCarriersSystemProperties = filterData(systemProperties, {systemTypeName: 'Energy Carriers'}, {systemTypeName: (item, filterValue) => (item.systemTypeName === filterValue)});
    const energyCarrierSystems = systems.filter(s => s.systemType.name === 'Energy Carriers')

    if (systemPropertyGroupsLoading || systemsLoading || systemPropertiesLoading) return (
        <div>
            Loading....
        </div>
    )

    energyCarrierSystems.forEach((system, idx) => {
        const systemId = system.id;
        const s = system.name;

        const gwpfeatures = [
            'CH4 Well to Tank Emission',
            'N2O Well to Tank Emission',
            'CO2 Well to Tank Emission',
        ]
        const gwp100factors = [30.0, 265.0, 1.0];
        const gwp20factors = [85.0, 265.0, 1.0];

        const gwp100 = (!!!system.outcomes) ? 0.0 : gwpfeatures.reduce((result, ft, idx) => {
            return result + ((!!system.outcomes[ft] ? system.outcomes[ft].average : 0.0) * gwp100factors[idx] || 0.0);
        }, 0.0)

        const gwp20 = (!!!system.outcomes) ? 0.0 : gwpfeatures.reduce((result, ft, idx) => {
            return result + ((!!system.outcomes[ft] ? system.outcomes[ft].average : 0.0) * gwp20factors[idx] || 0.0);
        }, 0.0)

        function getSpecialPropertyGroupId(systemPropertyGroupName, arr = []) {
            const item = arr.find(entry => entry.name === systemPropertyGroupName);
            return item.id;
        }

        systemProperties.push({
            systemName: s,
            systemTypeName: 'Energy Carriers',
            systemTypeId: '2',
            systemPropertyGroupName: 'GWP100 Well to Tank Emission',
            calculated: true,
            include: true,
            certainty: 100.0,
            id: 1000000 + idx,
            value: gwp100,
            systemPropertyGroupId: getSpecialPropertyGroupId('GWP100 Well to Tank Emission', systemPropertyGroups),
            systemId: systemId,
        })

        systemProperties.push({systemName: s, systemTypeName: 'Energy Carriers', systemTypeId: '2', systemPropertyGroupName: 'GWP20 Well to Tank Emission', calculated: true, include: true, certainty: 100.0, id: 1000001 + idx,
            value:gwp20, systemPropertyGroupId: getSpecialPropertyGroupId('GWP20 Well to Tank Emission', systemPropertyGroups),
            systemId: systemId,
        })

        const opexPropertiesForThisSystem = filterData(energyCarriersSystemProperties, {
            systemName: s,
            systemPropertyGroupName: 'OpEx',
            include: true,
        }, {
            systemName: (item, filterValue) => (item.systemName === filterValue),
            systemPropertyGroupName: (item, filterValue) => (item.systemPropertyGroupName === filterValue),
            include: (item, filterValue) => (item.include === filterValue),
        })

        opexPropertiesForThisSystem.forEach((opex, opexIdx) => {
            systemProperties.push({
                systemName: s,
                systemTypeName: 'Energy Carriers',
                systemTypeId: '2',
                systemPropertyGroupName: 'OpEx per unit energy',
                calculated: true,
                include: true,
                certainty: 100.0,
                id: 1000002 + idx * opexPropertiesForThisSystem.length + opexIdx,
                value: !!system.outcomes ? (!!system.outcomes['Uncontained Gravimetric Energy Density'] ? 3.6 * opex.value / system.outcomes['Uncontained Gravimetric Energy Density'].average : 0.0) : 0.0,
                systemPropertyGroupId: getSpecialPropertyGroupId('OpEx per unit energy', systemPropertyGroups),
                systemId: systemId,
                timescale: opex.timescale,
            })
        })

    })


    return (
        <SustainablePowerDataContext.Provider value={{
            systemProperties: systemProperties,
            systemPropertyGroups: systemPropertyGroups.map(({systemType, ...spg}) => ({...spg, systemTypeName: systemType.name})),
            systems: systems.map(s => ({...s, systemTypeName: s.systemType.name})),
        }}>
            {props.children}
        </SustainablePowerDataContext.Provider>
    )
}
