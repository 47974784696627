import {Button} from "react-bootstrap";
import React, {useContext} from "react";
import {ChartContext} from "../charts/Chart";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";

export const ResetAllButton = () => {
    const { allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const { chart,setURL, settings, defaultSettings } = useContext(ChartContext)

    return (
        <div className={'mb-3'}>
            <Button onClick={() => {
                Object.keys(defaultSettings).forEach(dS => {
                    settings[dS] = defaultSettings[dS]
                })
                setURL(chart, settings)
                setAllSettings({...allSettings})
            }}>Reset all</Button>
        </div>
    )
}