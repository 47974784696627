import {Button, Col, Row} from "react-bootstrap";
import React, {useContext} from "react";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {ChartContext} from "../charts/Chart";
import {SPGSelector} from "./SPGSelector";
import {FormulaOrSPGSelector} from "./FormulaOrSPGSelector";

export const SelectedFeaturesForm = ({defaultAdd}) => {
    const { allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const { chart, settings, setURL, forStacks } = useContext(ChartContext)

    return (
        <div className={'mb-3'}>
            {settings.selectedFeatures.map((f, i) =>
                <Row noGutters={true}>
                    <Col>
                        {!forStacks && <SPGSelector fKey={i}/>}
                        {forStacks && <FormulaOrSPGSelector fKey={i}/>}
                    </Col>
                    <Col lg={"auto"}>
                        {settings.selectedFeatures.length > 1 &&
                            <Button
                                className={"my-2"}
                                onClick={() => {
                                    settings.selectedFeatures.splice(i, 1)
                                    setURL(chart, settings)
                                    setAllSettings({...allSettings})
                                }}
                            >
                                Delete
                            </Button>
                        }
                    </Col>
                </Row>
            )}
            <Button
                onClick={() => {
                    settings.selectedFeatures = [...settings.selectedFeatures, defaultAdd]
                    setURL(chart, settings)
                    setAllSettings({...allSettings})
                }}
            >
                Add
            </Button>
        </div>
    )
}