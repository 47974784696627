import React, {useContext} from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {HCExporting} from "./HCExporting";
import {ChartContext} from "./Chart";
import HC_exporting from 'highcharts/modules/exporting';
import HC_offline_exporting from 'highcharts/modules/offline-exporting';
HC_exporting(Highcharts);
HC_offline_exporting(Highcharts);

export const BoxBar = ({}) => {
    const { chart, settings, features, entities, isPreview } = useContext(ChartContext)

    const multipleSeries = features.length !== 1

    let xlsxHeaderRow = [
        {type: 'string', value: 'Energy carrier'}
    ]
    features.forEach(f => xlsxHeaderRow.push(
        {type: 'string', value: f.name + " (min)"},
        {type: 'string', value: f.name + " (avg)"},
        {type: 'string', value: f.name + " (max)"},
    ))
    const xlsxRows = [xlsxHeaderRow].concat(entities.map(e => {
        let eRow = [{type: 'string', value: e.name}]
        features.forEach(f => {
            if (f.name in e.values) {
                eRow.push(
                    {type: 'number', value: e.values[f.name]["minimum"]},
                    {type: 'number', value: e.values[f.name]["average"]},
                    {type: 'number', value: e.values[f.name]["maximum"]},
                )
            }
        })
        return eRow
    }))

    const csvRows = entities.map(e => {
        let eRow = {
            "Energy carrier": e.name,
        }
        features.forEach(f => {
            if (f.name in e.values) {
                eRow[f.name + " (min)"] = e.values[f.name]["minimum"]
                eRow[f.name + " (avg)"] = e.values[f.name]["average"]
                eRow[f.name + " (max)"] = e.values[f.name]["maximum"]
            }
        })
        return eRow
    })

    let series = []
    features.forEach((f, j) => {
        series.push({
            type: 'bar',
            data: entities.reduce((acc, e, i) => {
                if (f.name in e.values) {
                    const categoryColor = e.categories.find(c => c.categoryGroupId === settings.colorBy) ? e.categories.find(c => c.categoryGroupId === settings.colorBy).color : '#ff0000'
                    return [...acc, {
                        x: i,
                        y: e.values[f.name]["average"],
                        count: e.values[f.name]["count"],
                        // meta: s.meta.map(m => m.name+": "+m.value).join("<br/>"),
                        name: f.name,
                        unit: f.unit,
                        meta: e.metaData.sort((a, b) => a.systemTypeMetaField.index - b.systemTypeMetaField.index).map(m => m.systemTypeMetaField.name+": "+m.value).join("<br/>"),
                        color: categoryColor,
                    }]
                }
                return acc
            }, []),
            pointWidth: 10,
            states: {
                hover: {
                    borderColor: "#ff0000",
                },
            },
        }, {
            type: 'boxplot',
            data: entities.reduce((acc, e, i) => {
                if (f.name in e.values) {
                    return [...acc, {
                        x: i,
                        low: e.values[f.name]["minimum"],
                        q1: e.values[f.name]["average"],
                        median: e.values[f.name]["average"],
                        q3: e.values[f.name]["average"],
                        high: e.values[f.name]["maximum"],
                    }]
                }
                return acc
            }, []),
            pointWidth: 10,
            color: "black",
            states: {
                hover: {
                    borderColor: "#ff0000",
                },
                inactive: {
                    opacity: 1
                }
            },
        })
    })

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={{
                chart: {
                    inverted: true,
                    height: isPreview ? 300 : (multipleSeries ? 1.75 : 1)*Math.pow(entities.length, 0.65)*80
                },
                title: {
                    text: isPreview ? "" : chart.title
                },
                series: series,
                xAxis: {
                    categories: entities.map(s => s.name),
                    labels: {
                        // step: 1
                    },
                    title: {
                        text: 'Energy Carriers'
                    },
                },
                yAxis: [{
                    title: {
                        text: multipleSeries ? features.map(f => f.name+" ["+(f.unit ? f.unit : '-')+"]").join(", ") : features[0].unit
                    },
                    lineWidth: 1,
                    // gridLineColor: 'grey',
                    gridLineWidth: 1,
                }, {
                    title: {
                        text: multipleSeries ? features.map(f => f.name+" ["+(f.unit ? f.unit : '-')+"]").join(", ") : features[0].unit
                    },
                    lineWidth: 1,
                    gridLineWidth: 0,
                    linkedTo: 0,
                    opposite: true
                }],
                tooltip: {
                    shared: true,
                    useHTML: true,
                    formatter: function () {
                        let output = "<small>" + this.x + "</small><br/>"

                        for (let i = 0; i<this.points.length/2 ; i++) {
                            const bar = this.points[i*2].point
                            const box = this.points[i*2+1].point

                            output += bar.name+": "+bar.y.toFixed(1)+" "+bar.unit+
                                " (min: "+box.low.toFixed(1)+", max: "+box.high.toFixed(1)+", count: "+bar.count+")"+
                                "<br/>"
                        }

                        output += "<br/>" + this.points[0].point.meta
                        return output
                    }
                },
                legend: {
                    enabled: false
                },
                ...HCExporting(true, 1504, isPreview, chart.name, xlsxRows, csvRows)
            }}
        />
    )
}