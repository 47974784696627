import React, { useState } from 'react'
import { Formik, Field } from 'formik'
import {Modal, Button, ButtonGroup, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {MUTATION_UPDATE_CATEGORY} from "../../../../graphql/category";
import {SelectField} from "../../../general/forms/SelectField";
import {QUERY_CATEGORY_GROUPS} from "../../../../graphql/categoryGroup";
import {Pencil} from "react-bootstrap-icons";


const EditCategoryModal = ({category, show, setShow, ...props}) => {
    const [ updateCategory, { loading }] = useMutation(MUTATION_UPDATE_CATEGORY)
    const { data: categoryGroupsData = { categoryGroups: [] }, loading: categoryGroupsLoading } = useQuery(QUERY_CATEGORY_GROUPS)

    const onSubmit = (values, { setSubmitting }) => {
        updateCategory({
            variables: {
                categoryId: category.id,
                name: values.name,
                description: values.description,
                color: values.color,
                categoryGroupId: values.categoryGroupId,
            }
        }).then(() => setSubmitting(false)).then(() => setShow(false))
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit {category.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        name: category.name,
                        description: category.description,
                        color: category.color,
                        categoryGroupId: category.categoryGroupId,
                    }}
                    onSubmit={onSubmit}
                >
                    {({handleReset, handleSubmit, dirty, isSubmitting}) => (
                        <Form onReset={handleReset} onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Label>Category name:</Form.Label>
                                <Field as={Form.Control} name='name'/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Description:</Form.Label>
                                <Field as={Form.Control} name='description' />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Color:</Form.Label>
                                <Field type='color' as={Form.Control} name='color' />
                                <Form.Text>This color can be used in Graphs and for category grouping in tables</Form.Text>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Category group:</Form.Label>
                                <SelectField disabled={categoryGroupsLoading} name='categoryGroupId'>
                                    { categoryGroupsLoading ? (
                                        <option value=''>Loading....</option>
                                    ) : (
                                        categoryGroupsData.categoryGroups.map(categoryGroup => (
                                            <option key={categoryGroup.id} value={categoryGroup.id}>{categoryGroup.name}</option>
                                        ))
                                    )}
                                </SelectField>
                            </Form.Group>
                            <Form.Group>
                                <ButtonGroup>
                                    <Button type='submit' disabled={isSubmitting || loading || categoryGroupsLoading}>Submit</Button>
                                    <Button type='button' disabled={isSubmitting} onClick={() => setShow(false)}>Cancel</Button>
                                </ButtonGroup>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}


export const EditCategoryModalButton = ({category, ...props}) => {
    const [ show, setShow ] = useState(false)

    return (
        <React.Fragment>
            <OverlayTrigger overlay={<Tooltip id={`edit-category-${category.id}-tooltip`}>Edit this category</Tooltip>}>
                <Button onClick={() => setShow(true)} {...props}>
                    <Pencil size={16} />
                </Button>
            </OverlayTrigger>
            <EditCategoryModal category={category} show={show} setShow={setShow} />
        </React.Fragment>
    )
}