import gql from 'graphql-tag';
import {FRAGMENT_CHART_CONTENT} from "./chart";

export const FRAGMENT_PAGE_CONTENT = gql`
    fragment pageContent on PageObject {
        name
        slug
        index
        charts {
            id
            ...chartContent
        }
    }
    ${FRAGMENT_CHART_CONTENT}
`


export const QUERY_PAGES = gql`
    query pages {
        pages {
            id
            ...pageContent
        }
    }
    ${FRAGMENT_PAGE_CONTENT}
`


export const QUERY_PAGE = gql`
    query page($pageId: ID!) {
        page(pageId: $pageId) {
            id
            ...pageContent
        }
    }
    ${FRAGMENT_PAGE_CONTENT}
`


export const MUTATION_CREATE_PAGE = gql`
     mutation createPage($name: String!, $slug: String!, $index: Int) {
        createPage(name: $name, slug: $slug, index: $index) {
            ok
            page {
                id
                ...pageContent
            }
        }
    }
    ${FRAGMENT_PAGE_CONTENT}
`


export const MUTATION_UPDATE_PAGE = gql`
     mutation updatePage($pageId: ID!, $name: String, $slug: String, $index: Int) {
        updatePage(pageId: $pageId, name: $name, slug: $slug, index: $index) {
            ok
            page {
                id
                ...pageContent
            }
        }
    }
    ${FRAGMENT_PAGE_CONTENT}
`


export const MUTATION_DELETE_PAGE = gql`
    mutation deletePage($pageId: ID!) {
        deletePage(pageId: $pageId) {
            ok
            page {
                id
                ...pageContent
            }
        }
    }
    ${FRAGMENT_PAGE_CONTENT}
`
