import React from 'react'
import {useQuery} from "@apollo/react-hooks";
import {QUERY_SYSTEM_PROPERTY_GROUPS} from "../../graphql/queries/system-property-group";
import {QUERY_SYSTEMS} from "../../graphql/system";
import {QUERY_CATEGORIES} from "../../graphql/category";
import {QUERY_STACK_TYPE} from "../../graphql/stackType";
import {QUERY_SYSTEM_TYPES} from "../../graphql/allData";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {QUERY_CHARTS} from "../../graphql/chart";
import {QUERY_PAGES} from "../../graphql/page";
import {QUERY_ENTITY_TYPES} from "../../graphql/entityType";

export const SustainablePowerDataContext2 = React.createContext([])

export const SustainablePowerDataProvider2 = (props) => {
    const [ allSettings, setAllSettings ] = useLocalStorage("chartSettings", {})

    const { data: entityTypesData, loading: entityTypesLoading } = useQuery(QUERY_ENTITY_TYPES, {
        onError: error => { console.log(error) },
    })

    const { data: systemTypesData, loading: systemTypesLoading } = useQuery(QUERY_SYSTEM_TYPES, {
        onError: error => { console.log(error) },
    })
    const { data: systemPropertyGroupsData, loading: systemPropertyGroupsLoading } = useQuery(QUERY_SYSTEM_PROPERTY_GROUPS, {
        onError: error => { console.log(error) },
    })
    const { data: systemsData, loading: systemsLoading } = useQuery(QUERY_SYSTEMS, {
        onError: error => { console.log(error) },
    })
    const { data: stackTypeData, loading: stackTypeLoading } = useQuery(QUERY_STACK_TYPE, {
        variables: {stackTypeId: 6},
        onError: error => { console.log(error) },
    })
    const { data: categoriesData, loading: categoriesLoading } = useQuery(QUERY_CATEGORIES, {
        onError: error => { console.log(error) },
    })

    const { data: pagesData, loading: pagesLoading } = useQuery(QUERY_PAGES, {
        onError: error => { console.log(error) },
    })
    const { data: chartsData, loading: chartsLoading } = useQuery(QUERY_CHARTS, {
        onError: error => { console.log(error) },
    })

    if (entityTypesLoading ||
        systemTypesLoading ||
        systemPropertyGroupsLoading ||
        systemsLoading ||
        stackTypeLoading ||
        categoriesLoading ||
        pagesLoading ||
        chartsLoading
    ) return (
        <div>
            Loading....
        </div>
    )

    // const chartSettings = {
    //     colorBy: {
    //         displayName: "",
    //         description: "",
    //     }
    // }
    //
    // const chartTypeChartSettings = {
    //     "bar": [],
    //     "bubble": [],
    //     "boxBar": ["enabledFeatures", "disabledEntities", "selectedCategories", "colorBy", "sortBy"],
    //     "columnRangeAndBar": [],
    //     "flow": [],
    // }

    const allChartSettingTypes = [
        "id",
        "nullOrId",
        "idOrString",
        "listOfIds",
        "object",
        "objectOrString",
        "listOfObjects",
        "boolean",
        "number",
    ]

    const allChartSettingLegalValues = [
        "systems",
        "stacks",
        "categoryGroups",
        "categories",
        "systemPropertyGroups",
        "formulas",
        "formulasOrSPGs",
        "columns",
    ]

    // const allChartSettings = [
    //     {name: "base", shortName: "b"},
    //     {name: "colorBy", shortName: "cB"},
    //     {name: "disabledColumns", shortName: "dC"},
    //     {name: "disabledEntities", shortName: "dE"},
    //     {name: "enabledFeatures", shortName: "eF"},
    //     {name: "isColumnRange", shortName: "iCR"},
    //     {name: "isTwoSeries", shortName: "iTS"},
    //     {name: "rangeXMax", shortName: "rxMax"},
    //     {name: "rangeXMin", shortName: "rxMin"},
    //     {name: "rangeYMax", shortName: "ryMax"},
    //     {name: "rangeYMin", shortName: "ryMin"},
    //     {name: "selectedCategories", shortName: "sC"},
    //     {name: "selectedFeatures", shortName: "sF"},
    //     {name: "seriesLabels", shortName: "sL"},
    //     {name: "sortBy", shortName: "sB"},
    //     {name: "sortByColumn", shortName: "sBC"},
    //     {name: "sortOrder", shortName: "sO"},
    //     {name: "stacked", shortName: "st"},
    //     {name: "valueLabels", shortName: "vL"},
    // ]

    const allChartFieldProps = {
        "Row": [
            {name: "noGutters", displayName: "No gutters", type: "boolean", default: false, index: 0},
        ],
        "Col": [
            {name: "xl", displayName: "Width relative to parent (XL screens [≥1200px])", type: "colWidths", default: "notset", index: 0},
            {name: "lg", displayName: "Width relative to parent (large screens [≥992px <1200px])", type: "colWidths", default: "notset", index: 1},
            {name: "md", displayName: "Width relative to parent (medium screens [≥768px <992px])", type: "colWidths", default: "notset", index: 2},
            {name: "sm", displayName: "Width relative to parent (small screens [≥576px <768px])", type: "colWidths", default: "notset", index: 3},
            {name: "xs", displayName: "Width relative to parent (XS screens [<576px])", type: "colWidths", default: "notset", index: 4},
        ],
        "CategoriesSelector": [],
        "ColorBySelector": [],
        "CategoryForm": [],
        "EntitiesCheckboxes": [],
        "ColorLegend": [],
        "ResetAllButton": [],
        "RangeField": [],
        "ResetRangesButton": [],
        "RangeForm": [],
        "SPGSelector": [
            {name: "fKey", displayName: "Index of corresponding feature (for bubble chart: X=0,Y=1,Z=2)", type: "int", default: 0, index: 0},
            {name: "forSort", displayName: "For sort", type: "boolean", default: false, index: 1},
            {name: "all", displayName: "Display all system property groups", type: "boolean", default: true, index: 2},
        ],
        "FormulaOrSPGSelector": [
            {name: "fKey", displayName: "Index of corresponding feature (for bubble chart: X=0,Y=1,Z=2)", type: "int", default: 0, index: 0},
            {name: "forSort", displayName: "For sort", type: "boolean", default: false, index: 1},
        ],
        "BaseSelector": [
            {name: "defaultBase", displayName: "Entity id of the default base choice", type: "entities", default: "notset", index: 0},
        ],
        "LabelsCheckboxes": [],
        "Toggle": [
            {name: "sName", displayName: "Chart setting", type: "chartSetting", default: "notset", index: 0},
            {name: "trueText", displayName: "Button text if true", type: "string", default: "", index: 1},
            {name: "falseText", displayName: "Button text if false", type: "string", default: "", index: 2},
        ],
        "FeaturesEnablerCheckboxes": [],
        "FeaturesSortByRadios": [],
        "FeaturesSelector": [],
        "SelectedFeaturesForm": [
            {name: "defaultAdd", displayName: "Default SPG or formula that is added using the \"add\" button", type: "features", default: "notset", index: 0},
        ],
        "GWPSelector": [],
        "ColumnForm": [],
        "Bubble": [],
        "Bar": [],
        "BoxBar": [],
        "ColumnRangeAndBar": [],
        "Flow": [],
    }

    return (
        <SustainablePowerDataContext2.Provider value={{
            allPages: pagesData.pages.sort((a,b) => a.index-b.index),
            allCharts: chartsData.charts,
            allChartSettingTypes: allChartSettingTypes,
            allChartSettingLegalValues: allChartSettingLegalValues,
            allChartFieldProps: allChartFieldProps,
            allEntityTypes: entityTypesData.entityTypes,
            allSystemTypes: systemTypesData.systemTypes,
            allSystemPropertyGroups: systemPropertyGroupsData.systemPropertyGroups,
            allSystems: systemsData.systems.map(sys => {
                    return {...sys, values: sys.outcomes}
                }),
            allStacks: stackTypeData.stackType.stacks.map(stk => {
                    return {...stk, values: stk.outcomes}
                }),
            allFormulas: stackTypeData.stackType.formulas,
            allCategoryGroups: categoriesData.categoryGroups,
            allStackTypeSystemFields: stackTypeData.stackType.systemFields,
            allSettings: allSettings,
            setAllSettings: setAllSettings,
        }}>
            {props.children}
        </SustainablePowerDataContext2.Provider>
    )
}
