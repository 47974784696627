import React, {useContext} from 'react'
import {useQuery} from "@apollo/react-hooks";
import {IS_LOGGED_IN} from "../../graphql/login";


export const LoginContext = React.createContext([])


export const LoginContextProvider = (props) => {
    const { data } = useQuery(IS_LOGGED_IN);

    return (
        <LoginContext.Provider value={data.isLoggedIn}>
            {props.children}
        </LoginContext.Provider>
    )
}


export const useLogin = () => {
    const isLoggedIn = useContext(LoginContext);

    return { isLoggedIn }
}