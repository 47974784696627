import {useMutation} from "@apollo/react-hooks";
import {
    MUTATION_CREATE_SYSTEM_TYPE_META_FIELD,
    MUTATION_UDPATE_SYSTEM_TYPE_META_FIELD,
    MUTATION_DELETE_SYSTEM_TYPE_META_FIELD,
} from "../../../../graphql/systemTypeMetaField";
import {Field, Formik} from "formik";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import React, {useState, useContext} from "react";
import {QUERY_SYSTEM_TYPE} from "../../../../graphql/systemType";


const ModalFormContext = React.createContext(null)


const SystemTypeMetaFieldDeleteModalForm = ({ systemTypeMetaField, ...props}) => {
    const [ deleteSystemTypeMetaField, { loading } ] = useMutation(MUTATION_DELETE_SYSTEM_TYPE_META_FIELD, {
        update: (cache, { data }) => {
            const systemTypeId = data.deleteSystemTypeMetaField.systemTypeId;
            const cachedData = cache.readQuery({query: QUERY_SYSTEM_TYPE, variables: {
                systemTypeId: systemTypeId
            }})


            cache.writeQuery({query: QUERY_SYSTEM_TYPE, variables: { systemTypeId: systemTypeId }, data: {
                systemType: {
                    ...cachedData.systemType,
                    metaFields: cachedData.systemType.metaFields.filter(mf => mf.id !== data.deleteSystemTypeMetaField.id),
                }}})
        }
    })

    const { closeModal } = useContext(ModalFormContext);

    const confirmDelete = () => {
        deleteSystemTypeMetaField({ variables: {data: { systemTypeMetaFieldId: systemTypeMetaField.id }}})
            .then(() => closeModal());
    }

    return (
        <Button type='submit' disabled={loading} onClick={() => confirmDelete()}>
            Confirm { (loading) ? <Spinner animation='border' size='sm' /> : null }
        </Button>
    )
}


const SystemTypeMetaFieldCreateModalForm = ({systemType, ...props}) => {
    const [ createSystemTypeMetaField, { loading } ] = useMutation(MUTATION_CREATE_SYSTEM_TYPE_META_FIELD, {
        update: (cache, { data }) => {
            const cachedData = cache.readQuery({query: QUERY_SYSTEM_TYPE, variables: { systemTypeId: systemType.id }})
            cache.writeQuery({query: QUERY_SYSTEM_TYPE, variables: { systemTypeId: systemType.id}, data: {
                systemType: {
                    ...cachedData.systemType,
                    metaFields: [...cachedData.systemType.metaFields, data.createSystemTypeMetaField],
                }}})
        }
    })

    const { closeModal } = useContext(ModalFormContext);

    return (
        <Formik
            initialValues={{
                name: '',
                description: '',
                index: 0,
            }}
            onSubmit={(values, { setSubmitting}) => {
                createSystemTypeMetaField({
                    variables: {
                        data: {
                            systemTypeId: systemType.id,
                            name: values.name,
                            description: values.description,
                            index: values.index,
                        }
                    }
                }).then(
                    () => setSubmitting(false),
                ).then(
                    () => closeModal(),
                )
            }}
        >
            {({ values, handleSubmit, handleReset }) => (
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Field as={Form.Control} name='name' placeholder='Name' />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Field as={Form.Control} name='description' placeholder='Description' />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Order</Form.Label>
                        <Field as={Form.Control} type='number' step='1' name='index' placeholder='Sorting index' />
                    </Form.Group>
                    <Button type='submit'>Save { (loading) ? <Spinner animation='border' size='sm' /> : null }</Button>
                </Form>
            )}
        </Formik>
    )
}


const SystemTypeMetaFieldsUpdateModalForm = ({systemTypeMetaField, systemTypeId, ...props}) => {
    const [ updateSystemTypeMetaField, { loading } ] = useMutation(MUTATION_UDPATE_SYSTEM_TYPE_META_FIELD, {
        refetchQueries: [{query: QUERY_SYSTEM_TYPE, variables: {systemTypeId: systemTypeId}}]
    })

    const { closeModal } = useContext(ModalFormContext);

    return (
        <Formik
            initialValues={{
                name: systemTypeMetaField.name,
                description: systemTypeMetaField.description,
                index: (systemTypeMetaField.index || ''),
            }}
            onSubmit={(values, { setSubmitting}) => {
                updateSystemTypeMetaField({
                    variables: {
                        data: {
                            systemTypeMetaFieldId: systemTypeMetaField.id,
                            name: values.name,
                            description: values.description,
                            index: values.index,
                        }
                    }
                }).then(
                    () => setSubmitting(false),
                ).then(
                    () => closeModal(),
                )
            }}
        >
            {({ values, handleSubmit, handleReset }) => (
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Field as={Form.Control} name='name' />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Field as={Form.Control} name='description' />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Order</Form.Label>
                        <Field as={Form.Control} type='number' step='1' name='index' placeholder='Sorting index' />
                    </Form.Group>
                    <Button type='submit'>Save { (loading) ? <Spinner animation='border' size='sm' /> : null }</Button>
                </Form>
            )}
        </Formik>
    )
}





const ModalForm = ({title, show, setShow, ...props}) => {
    const closeModal = () => setShow(false);

    return (
        <React.Fragment>
            <ModalFormContext.Provider value={{closeModal: closeModal}}>
                <Modal show={show} onHide={() => setShow(false)}>
                    <Modal.Header>
                        {title}
                    </Modal.Header>
                    <Modal.Body>
                        {props.children}
                    </Modal.Body>
                </Modal>
            </ModalFormContext.Provider>
        </React.Fragment>
    )
}


export const SystemTypeMetaFieldEditModalFormButton = ({systemTypeMetaField, systemTypeId, ...props}) => {
    const [ show, setShow ] = useState(false);

    return (
        <React.Fragment>
            <Button onClick={() => setShow(true)} {...props} />
            <ModalForm show={show} setShow={setShow} title={`Edit ${systemTypeMetaField.name}`}>
                <SystemTypeMetaFieldsUpdateModalForm systemTypeId={systemTypeId} systemTypeMetaField={systemTypeMetaField} />
            </ModalForm>
        </React.Fragment>
    )
}


export const SystemTypeMetaFieldCreateModalFormButton = ({systemType, ...props}) => {
    const [ show, setShow ] = useState(false);

    return (
        <React.Fragment>
            <Button onClick={() => setShow(true)} {...props} />
            <ModalForm show={show} setShow={setShow} title={`Add meta-field to ${systemType.name}`}>
                <SystemTypeMetaFieldCreateModalForm systemType={systemType} />
            </ModalForm>
        </React.Fragment>
    )
}


export const SystemTypeMetaFieldDeleteModalFormButton = ({systemTypeMetaField, ...props}) => {
    const [ show, setShow ] = useState(false);

    return (
        <React.Fragment>
            <Button onClick={() => setShow(true)} {...props} />
            <ModalForm show={show} setShow={setShow} title={`Delete meta-field ${systemTypeMetaField.name}`}>
                <SystemTypeMetaFieldDeleteModalForm systemTypeMetaField={systemTypeMetaField} />
            </ModalForm>
        </React.Fragment>
    )
}