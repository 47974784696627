import React, { useContext } from 'react'
import {SystemFormik} from "./system-formik";
import {Field} from 'formik'
import {Button, Form, Spinner} from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import {useMutation} from "@apollo/react-hooks";
import {MUTATION_CREATE_SYSTEM} from "../../../../graphql/system";
import {QUERY_SYSTEM_TYPE} from "../../../../graphql/systemType";
import {ModalFormContext} from './add-modal';


export const SystemAddForm = (props) => {
    const {closeModal} = useContext(ModalFormContext);
    const { systemTypeId } = useParams();
    const [ createSystem, { loading } ] = useMutation(MUTATION_CREATE_SYSTEM)

    function onSubmit(values, { setSubmitting }) {
        createSystem({
            variables: {
                data: {
                    systemTypeId: systemTypeId,
                    name: values.name,
                }
            },
            refetchQueries: [{query: QUERY_SYSTEM_TYPE, variables: {systemTypeId: systemTypeId}}],
            awaitRefetchQueries: true,
        }).then(
            () => setSubmitting(false)
        ).then(
            () => closeModal()
        )
    }

    return (
        <SystemFormik
            initialValues={{
                name: '',
            }}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleReset, isSubmitting}) => (
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Field as={Form.Control} name='name' placeholder='Name' />
                    </Form.Group>
                    <Button type='submit'>Save { (loading || isSubmitting) ? <Spinner animation='border' size='sm' /> : null }</Button>
                </Form>
                )}
        </SystemFormik>
    )
}