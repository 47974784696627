import gql from 'graphql-tag';


export const MUTATION_CREATE_SYSTEM_TYPE_META_FIELD = gql`
    mutation createSystemTypeMetaField($data: CreateSystemTypeMetaFieldInput!) {
        createSystemTypeMetaField(
            data: $data
        ) {
            id
            name
            description
            index
        }
    }
`


export const MUTATION_UDPATE_SYSTEM_TYPE_META_FIELD = gql`
    mutation updateSystemTypeMetaField($data: UpdateSystemTypeMetaFieldInput!) {
        updateSystemTypeMetaField(
            data: $data
        ) {
            id
            name
            description
            index
        }
    }
`


export const MUTATION_DELETE_SYSTEM_TYPE_META_FIELD = gql`
    mutation deleteSystemTypeMetaField($data: DeleteSystemTypeMetaFieldInput!) {
        deleteSystemTypeMetaField(
            data: $data
        ) {
            id
            name
            description
            systemTypeId
            index
        }
    }
`