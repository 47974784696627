import gql from 'graphql-tag';
import {FRAGMENT_STACK_TYPE_FORMULA_FIELD_CONTENT} from "./stackTypeFormulaField";


export const FRAGMENT_STACK_TYPE_FORMULA_CONTENT = gql`
    fragment stackTypeFormulaContent on StackTypeFormulaObject {
        name
        formula
        stackTypeId
        hide
        unit
        color
        description
        index
        fields {
            id
            ...stackTypeFormulaFieldContent
        }
    }
    ${FRAGMENT_STACK_TYPE_FORMULA_FIELD_CONTENT}
`


export const MUTATION_CREATE_STACK_TYPE_FORMULA = gql`
    mutation createStackTypeFormula($stackTypeId: ID!, $name: String!, $formula: String) {
        createStackTypeFormula(
            stackTypeId: $stackTypeId,
            name: $name, 
            formula: $formula,
        ) {
            ok
            stackTypeFormula {
                id
                ...stackTypeFormulaContent
            }
        }
    }
    ${FRAGMENT_STACK_TYPE_FORMULA_CONTENT}
`


export const MUTATION_UDPATE_STACK_TYPE_FORMULA = gql`
    mutation updateStackTypeFormula($stackTypeFormulaId: ID!, $name: String, $description: String, $formula: String, $index: Int, $color: String) {
        updateStackTypeFormula(
            stackTypeFormulaId: $stackTypeFormulaId,
            name: $name, 
            formula: $formula,
            color: $color,
            index: $index,
            description: $description,
        ) {
            ok
            stackTypeFormula {
                id
                ...stackTypeFormulaContent
            }
        }
    }
    ${FRAGMENT_STACK_TYPE_FORMULA_CONTENT}
`


export const MUTATION_DELETE_STACK_TYPE_FORMULA = gql`
    mutation deleteStackTypeFormula($stackTypeFormulaId: ID!) {
        deleteStackTypeFormula(
            stackTypeFormulaId: $stackTypeFormulaId,
        ) {
            ok
            stackTypeFormula {
                id
                ...stackTypeFormulaContent
            }
        }
    }
    ${FRAGMENT_STACK_TYPE_FORMULA_CONTENT}
`