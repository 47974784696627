import {useMutation} from "@apollo/react-hooks";
import {
    MUTATION_CREATE_STACK_TYPE_SYSTEM_FIELD,
    MUTATION_UDPATE_STACK_TYPE_SYSTEM_FIELD,
    MUTATION_DELETE_STACK_TYPE_SYSTEM_FIELD,
} from "../../../../../graphql/stackTypeSystemField";
import {Field, Formik} from "formik";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import React, {useState, useContext} from "react";
import {QUERY_STACK_TYPE} from "../../../../../graphql/stackType";


const ModalFormContext = React.createContext(null)


const StackTypeSystemFieldDeleteModalForm = ({ stackTypeSystemField, ...props}) => {
    const [ deleteStackTypeSystemField, { loading } ] = useMutation(MUTATION_DELETE_STACK_TYPE_SYSTEM_FIELD, {
        update: (cache, { data }) => {
            const stackTypeId = data.deleteStackTypeSystemField.stackTypeSystemField.stackTypeId;
            const cachedData = cache.readQuery({query: QUERY_STACK_TYPE, variables: {
                stackTypeId: stackTypeId
            }})


            cache.writeQuery({query: QUERY_STACK_TYPE, variables: { stackTypeId: stackTypeId }, data: {
                stackType: {
                    ...cachedData.stackType,
                    systemFields: cachedData.stackType.systemFields.filter(mf => mf.id !== data.deleteStackTypeSystemField.stackTypeSystemField.id),
                }}})
        }
    })

    const { closeModal } = useContext(ModalFormContext);

    const confirmDelete = () => {
        deleteStackTypeSystemField({ variables: { stackTypeSystemFieldId: stackTypeSystemField.id }})
            .then(() => closeModal());
    }

    return (
        <Button type='submit' disabled={loading} onClick={() => confirmDelete()}>
            Confirm { (loading) ? <Spinner animation='border' size='sm' /> : null }
        </Button>
    )
}


const StackTypeSystemFieldCreateModalForm = ({stackType, ...props}) => {
    const [ createStackTypeSystemField, { loading } ] = useMutation(MUTATION_CREATE_STACK_TYPE_SYSTEM_FIELD, {
        update: (cache, { data }) => {
            const cachedData = cache.readQuery({query: QUERY_STACK_TYPE, variables: { stackTypeId: stackType.id }})
            cache.writeQuery({query: QUERY_STACK_TYPE, variables: { stackTypeId: stackType.id}, data: {
                stackType: {
                    ...cachedData.stackType,
                    systemFields: [...cachedData.stackType.systemFields, data.createStackTypeSystemField.stackTypeSystemField],
                }}})
        }
    })

    const { closeModal } = useContext(ModalFormContext);

    return (
        <Formik
            initialValues={{
                name: '',
                description: '',
                index: 0,
            }}
            onSubmit={(values, { setSubmitting}) => {
                createStackTypeSystemField({
                    variables: {
                        stackTypeId: stackType.id,
                        name: values.name,
                        description: values.description,
                        index: values.index,
                    }
                }).then(
                    () => setSubmitting(false),
                ).then(
                    () => closeModal(),
                )
            }}
        >
            {({ values, handleSubmit, handleReset }) => (
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Field as={Form.Control} name='name' placeholder='Name' />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Field as={Form.Control} name='description' placeholder='Description' />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Order</Form.Label>
                        <Field as={Form.Control} type='number' step='1' name='index' placeholder='Sorting index' />
                    </Form.Group>
                    <Button type='submit'>Save { (loading) ? <Spinner animation='border' size='sm' /> : null }</Button>
                </Form>
            )}
        </Formik>
    )
}


const StackTypeSystemFieldsUpdateModalForm = ({stackTypeSystemField, stackTypeId, ...props}) => {
    const [ updateStackTypeSystemField, { loading } ] = useMutation(MUTATION_UDPATE_STACK_TYPE_SYSTEM_FIELD, {
        refetchQueries: [{query: QUERY_STACK_TYPE, variables: {stackTypeId: stackTypeId}}]
    })

    const { closeModal } = useContext(ModalFormContext);

    return (
        <Formik
            initialValues={{
                name: stackTypeSystemField.name,
                description: stackTypeSystemField.description,
                index: (stackTypeSystemField.index || ''),
            }}
            onSubmit={(values, { setSubmitting}) => {
                updateStackTypeSystemField({
                    variables: {
                        stackTypeSystemFieldId: stackTypeSystemField.id,
                        name: values.name,
                        description: values.description,
                        index: values.index,
                    }
                }).then(
                    () => setSubmitting(false),
                ).then(
                    () => closeModal(),
                )
            }}
        >
            {({ values, handleSubmit, handleReset }) => (
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Field as={Form.Control} name='name' />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Field as={Form.Control} name='description' />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Order</Form.Label>
                        <Field as={Form.Control} type='number' step='1' name='index' placeholder='Sorting index' />
                    </Form.Group>
                    <Button type='submit'>Save { (loading) ? <Spinner animation='border' size='sm' /> : null }</Button>
                </Form>
            )}
        </Formik>
    )
}





const ModalForm = ({title, show, setShow, ...props}) => {
    const closeModal = () => setShow(false);

    return (
        <React.Fragment>
            <ModalFormContext.Provider value={{closeModal: closeModal}}>
                <Modal show={show} onHide={() => setShow(false)}>
                    <Modal.Header>
                        {title}
                    </Modal.Header>
                    <Modal.Body>
                        {props.children}
                    </Modal.Body>
                </Modal>
            </ModalFormContext.Provider>
        </React.Fragment>
    )
}


export const StackTypeSystemFieldEditModalFormButton = ({stackTypeSystemField, stackTypeId, ...props}) => {
    const [ show, setShow ] = useState(false);

    return (
        <React.Fragment>
            <Button onClick={() => setShow(true)} {...props} />
            <ModalForm show={show} setShow={setShow} title={`Edit ${stackTypeSystemField.name}`}>
                <StackTypeSystemFieldsUpdateModalForm stackTypeId={stackTypeId} stackTypeSystemField={stackTypeSystemField} />
            </ModalForm>
        </React.Fragment>
    )
}


export const StackTypeSystemFieldCreateModalFormButton = ({stackType, ...props}) => {
    const [ show, setShow ] = useState(false);

    return (
        <React.Fragment>
            <Button onClick={() => setShow(true)} {...props} />
            <ModalForm show={show} setShow={setShow} title={`Add meta-field to ${stackType.name}`}>
                <StackTypeSystemFieldCreateModalForm stackType={stackType} />
            </ModalForm>
        </React.Fragment>
    )
}


export const StackTypeSystemFieldDeleteModalFormButton = ({stackTypeSystemField, ...props}) => {
    const [ show, setShow ] = useState(false);

    return (
        <React.Fragment>
            <Button onClick={() => setShow(true)} {...props} />
            <ModalForm show={show} setShow={setShow} title={`Delete meta-field ${stackTypeSystemField.name}`}>
                <StackTypeSystemFieldDeleteModalForm stackTypeSystemField={stackTypeSystemField} />
            </ModalForm>
        </React.Fragment>
    )
}