import React, { useState } from 'react'
import {ButtonGroup, Modal, Button, Row, Spinner, Col} from "react-bootstrap";
import {useMutation} from "@apollo/react-hooks";
import {
    MUTATION_DELETE_SYSTEM_PROPERTY,
    QUERY_SYSTEM_PROPERTIES
} from "../../../graphql/systemProperty";


const DeleteDataModal = ({show, setShow, entry, ...props}) => {
    const [ deleteSystemProperty, { loading } ] = useMutation(MUTATION_DELETE_SYSTEM_PROPERTY, {
        variables: {data: {systemPropertyId: entry.id}},
        update: (cache, { data }) => {
            const cachedData = cache.readQuery({query: QUERY_SYSTEM_PROPERTIES})
            const systemIdx = cachedData.systems.findIndex(s => s.id === data.deleteSystemProperty.systemId);
            const newSystemsArray = [...cachedData.systems]
            newSystemsArray[systemIdx] = {
                ...cachedData.systems[systemIdx],
                systemProperties: cachedData.systems[systemIdx].systemProperties.filter(sp => sp.id !== data.deleteSystemProperty.systemProperty.id)
            }

            cache.writeQuery({query: QUERY_SYSTEM_PROPERTIES, data: { systems: newSystemsArray } })
        }
    })

    function handleConfirm() {
        deleteSystemProperty().then(
            () => setShow(false)
        )
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>Delete a row</Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        This will delete the following entry:
                    </Col>
                </Row>
                <Row><Col>{entry.systemTypeName} / {entry.systemName} / {entry.systemPropertyGroupName}</Col></Row>
                <Row><Col>{entry.note}</Col></Row>
                <Row><Col>{entry.explanation}</Col></Row>
                <Row><Col>{entry.value} [{entry.systemPropertyGroupUnit ? entry.systemPropertyGroupUnit : '--'}]</Col></Row>
                <Row>
                    <Col>
                        <ButtonGroup>
                            { (loading) ? <Button disabled><Spinner animation='border' size='sm' /></Button> : null }
                            <Button onClick={handleConfirm}>Confirm</Button>
                            <Button onClick={() => setShow(false)}>Cancel</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}


export const DeleteDataButton = ({entry, ...props}) => {
    const [ show, setShow ] = useState(false);

    return (
        <React.Fragment>
            <Button onClick={() => setShow(!show)} {...props} />
            <DeleteDataModal entry={entry} show={show} setShow={setShow} />
        </React.Fragment>
    )
}