import {usePagination} from "../../../hooks/pagination";
import React from "react";
import {ListGroup} from "react-bootstrap";
import {TableRow} from "./TableRow";
import {TableFilter} from "./TableFilter";
import {TableHeader} from "./TableHeader";


export const PaginatedTable = ({systemProperties, filterHelpers, ...props}) => {
    const { data: paginatedSystemProperties, firstItem, lastItem, nextPage, previousPage, numberOfItems, page } = usePagination(systemProperties)
    const { filter, setFilter } = filterHelpers

    return (
        <React.Fragment>
            <div className='sticky-top bg-white'>
                <TableHeader />
                <TableFilter filter={filter} setFilter={setFilter} {...{numberOfItems, firstItem, lastItem, nextPage, page, previousPage}} />
            </div>
            <ListGroup>
                {paginatedSystemProperties.map(e => <TableRow key={e.id} entry={e}/>)}
            </ListGroup>
        </React.Fragment>
    )
}