import gql from 'graphql-tag';


export const FRAGMENT_SYSTEM_CONTENT = gql`
    fragment systemContent on SystemObject {
        name
        systemTypeId
        systemType {
            id
            name
        }
        outcomes
        metaData {
            id
            value
            systemTypeMetaField {
                id
                name
                index
            }
        }
        categories {
            id
            name
            categoryGroupId
            categoryGroupName
            color
            index
        }
    }
`


export const QUERY_SYSTEMS = gql`
    query systems {
        systems {
            id
            ...systemContent
        }
    }
    ${FRAGMENT_SYSTEM_CONTENT}
`


export const MUTATION_UPDATE_SYSTEM = gql`
    mutation updateSystem($data: UpdateSystemInput!) {
        updateSystem(data: $data) {
            id
            ...systemContent
        }
    }
    ${FRAGMENT_SYSTEM_CONTENT}
`


export const MUTATION_CREATE_SYSTEM = gql`
    mutation createSystem($data: CreateSystemInput!) {
        createSystem(data: $data) {
            id
            ...systemContent
        }
    }  
    ${FRAGMENT_SYSTEM_CONTENT}
`