import {useParams} from "react-router";
import {useQuery} from "@apollo/react-hooks";
import {Card, Col, ListGroup, Row} from "react-bootstrap";
import React, {useState} from "react";
import {QUERY_PAGE} from "../../../../graphql/page";
import {AddChartModalButton} from "../charts/add-chart";
import {Chart} from "../charts/chart";

export const Page = ({}) => {
    const { pageId } = useParams();

    const { data: data, loading: loading } = useQuery(QUERY_PAGE, { variables: { pageId: pageId }})

    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col>
                        <Card.Title>Page: {loading ? 'Loading...' : data.page.name}</Card.Title>
                    </Col>
                    <Col className='text-right'>
                        <AddChartModalButton />
                    </Col>
                </Row>
            </Card.Header>
            <ListGroup variant='flush'>
                <ListGroup.Item>
                    <Row>
                        <Col sm={3}><b>Name:</b></Col>
                        <Col sm={2}><b>Type:</b></Col>
                        <Col sm={3}><b>Title:</b></Col>
                        <Col sm={3}><b>Features:</b></Col>
                    </Row>
                </ListGroup.Item>
                { loading ? 'Loading...' : data.page.charts.sort((a, b) => a.index-b.index).map((ch) =>
                    <Chart key={ch.id} chart={ch}/>
                )}
            </ListGroup>
        </Card>
    )
}