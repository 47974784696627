import {Button} from "react-bootstrap";
import React, {useContext} from "react";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {ChartContext} from "../charts/Chart";

export const ResetRangesButton = () => {
    const { allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const { chart, setURL, settings } = useContext(ChartContext)

    return (
        <Button className={'mt-2 mb-3'} onClick={() => {
            settings.rangeXMin = null
            settings.rangeXMax = null
            settings.rangeYMin = null
            settings.rangeYMax = null
            setURL(chart, settings)
            setAllSettings({...allSettings})
        }}>Reset ranges</Button>
    )
}