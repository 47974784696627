import gql from 'graphql-tag';
import { FRAGMENT_CATEGORY_CONTENT } from "./category";


export const FRAGMENT_CATEGORY_GROUP_CATEGORIES_ARRAY = gql`
    fragment categoryGroupCategoriesArray on CategoryGroupObject {
        categories {
            id
            ...categoryContent
        }
    }
    ${FRAGMENT_CATEGORY_CONTENT}
`


export const FRAGMENT_CATEGORY_GROUP_CONTENT = gql`
    fragment categoryGroupContent on CategoryGroupObject {
        name
        description
    }
`


export const QUERY_CATEGORY_GROUPS = gql`
    query categoryGroups {
        categoryGroups {
            id
            ...categoryGroupContent
        }
    }
    ${FRAGMENT_CATEGORY_GROUP_CONTENT}
`


export const QUERY_CATEGORY_GROUP = gql`
    query categoryGroup($categoryGroupId: ID!) {
        categoryGroup(categoryGroupId: $categoryGroupId) {
            id
            ...categoryGroupContent
            ...categoryGroupCategoriesArray
        }
    }
    ${FRAGMENT_CATEGORY_GROUP_CONTENT}
    ${FRAGMENT_CATEGORY_GROUP_CATEGORIES_ARRAY}
`


export const MUTATION_CREATE_CATEGORY_GROUP = gql`
    mutation createCategoryGroup($data: CreateCategoryGroupInput!) {
        createCategoryGroup(data: $data) {
            id
            ...categoryGroupContent
            ...categoryGroupCategoriesArray
        }
    }
    ${FRAGMENT_CATEGORY_GROUP_CATEGORIES_ARRAY}
    ${FRAGMENT_CATEGORY_GROUP_CONTENT}
`


export const MUTATION_UPDATE_CATEGORY_GROUP = gql`
    mutation updateCategoryGroup($data: UpdateCategoryGroupInput!) {
        updateCategoryGroup(data: $data) {
            id
            ...categoryGroupContent
        }
    }
    ${FRAGMENT_CATEGORY_GROUP_CONTENT}
`


export const MUTATION_DELETE_CATEGORY_GROUP = gql`
    mutation deleteCategoryGroup($data: DeleteCategoryGroupInput!) {
        deleteCategoryGroup(data: $data) {
            id
            ...categoryGroupContent
        }
    }
    ${FRAGMENT_CATEGORY_GROUP_CONTENT}
`


export const MUTATION_ADD_CATEGORY_GROUP_TO_ENTITY_TYPE = gql`
    mutation addCategoryGroupToEntityType($data: AddCategoryGroupToEntityTypeInput!) {
        addCategoryGroupToEntityType(data: $data) {
            id
            categoryGroups {
                id
            }
        }
    }    
`


export const MUTATION_REMOVE_CATEGORY_GROUP_FROM_ENTITY_TYPE = gql`
    mutation removeCategoryGroupFromEntityType($data: RemoveCategoryGroupFromEntityTypeInput!) {
        removeCategoryGroupFromEntityType(data: $data) {
            id
            categoryGroups {
                id
            }
        }
    }   
`