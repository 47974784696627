import React, {useState} from 'react';
import {Modal, Row, Col, OverlayTrigger, Tooltip, Button, ButtonGroup, Form} from 'react-bootstrap';
import {Trash} from "react-bootstrap-icons";
import {useMutation} from "@apollo/react-hooks";
import { MUTATION_DELETE_CATEGORY } from "../../../../graphql/category";
import { QUERY_CATEGORY_GROUP } from "../../../../graphql/categoryGroup";
import {useParams} from "react-router";


export const DeleteCategoryModal = ({show, setShow, category, ...props}) => {
    const { categoryGroupId } = useParams();
    const [deleteCategory, {loading}] = useMutation(MUTATION_DELETE_CATEGORY, {
        refetchQueries: [{query: QUERY_CATEGORY_GROUP, variables: { categoryGroupId: categoryGroupId}}],
        variables: {categoryId: category.id},
    })

    const onSubmit = () => {
        deleteCategory().then(() => setShow(false))
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Delete a category
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        This will delete the categoy: {category.name}. Confirm?
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ButtonGroup>
                            <Button disabled={loading} onClick={onSubmit}>Confirm</Button>
                            <Button disabled={loading} variant='danger' onClick={() => setShow(false)}>Cancel</Button>
                        </ButtonGroup>
                    </Col>
                </Row>

            </Modal.Body>
        </Modal>
    )
}


export const DeleteCategoryModalButton = ({category, ...props}) => {
    const [show, setShow] = useState(false);

    const onClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShow(true);
    }

    return (
        <React.Fragment>
            <OverlayTrigger
                overlay={
                    <Tooltip id={`delete-category-${category.id}-tooltip-id`}>
                        Delete this category
                    </Tooltip>}
            >
                <Button variant='danger' onClick={onClick} {...props}>
                    <Trash size={20}/>
                </Button>
            </OverlayTrigger>
            <DeleteCategoryModal category={category} show={show} setShow={setShow}/>
        </React.Fragment>
    )
}
