import React from "react";
import {CategoriesSelector} from "./CategoriesSelector";
import {ColorBySelector} from "./ColorBySelector";
import {Col, Row} from "react-bootstrap";

export const CategoryForm = ({}) => {
    return (
        <Row>
            <Col>
                <b>Categories to filter by:</b>
                <CategoriesSelector/>
            </Col>
            <Col lg={4}>
                <b>Color by:</b>
                <ColorBySelector/>
            </Col>
        </Row>
    )
}