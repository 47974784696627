import React, {useRef, useEffect, useState, useContext} from 'react';
import {Navbar, Nav, Container, Row, Col, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'chartjs-plugin-colorschemes';
import {BrowserRouter as Router, Route, Switch, Link, useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {PersonFill, Person, Gear} from 'react-bootstrap-icons'
import {Login} from "./components/modals/LoginModal";
import * as ChartDataLabels from 'chartjs-plugin-datalabels';
import * as chartjs from 'chart.js'
import {Table} from "./components/views/table/Table";
import {SustainablePowerDataContext2, SustainablePowerDataProvider2} from "./components/providers/SustainablePowerDataProvider2";
import {LoginContext, LoginContextProvider} from "./components/providers/LoginContextProvider";
import {SustainablePowerApolloProvider} from "./components/providers/ApolloProvider";
import {Settings} from "./components/views/settings/Settings";
import {Home} from './components/views/home/Home';
import {SustainablePowerDataProvider} from "./components/providers/SustainablePowerDataProvider";
import {Chart} from "./components/general/charts/Chart";
import {WellToWakeTable} from "./components/general/table/well-to-wake-table";
import {ChartCard} from "./components/general/cards/ChartCard";
import {EnergyCarriersTable} from "./components/general/table/energy-carriers-table";


chartjs.plugins.unregister(ChartDataLabels)


const ChartBase = ({options, style, ...props}) => {
    const chartRef = useRef(null);
    const [chart, setChart] = useState(null);

    useEffect(() => {
        if (!!chartRef && !!chartRef.current) {
            const tempChart = new Chart(chartRef.current, options)
            setChart(tempChart);

            return function cleanup() {
                tempChart.destroy();
            }
        }
    }, [chartRef, options])

    const downloadImage = (props) => {
        // Create a hyperlink element.
        if (!!chart) {
            var ctx = chart.ctx;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();

            let link = document.createElement('a');

            // Set image URL and filename.
            link.href = chart.canvas.toDataURL(1.0);

            link.download = 'IMAGE.png';
            document.body.append(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    return (
        <React.Fragment>
            <div className='position-relative' style={{minHeight: 400, ...style}}>
                <canvas ref={chartRef}/>
            </div>
            <Button onClick={downloadImage}>Download</Button>
        </React.Fragment>
    )
}


const AppNavLinks = () => {
    const location = useLocation();

    const { allPages } = useContext(SustainablePowerDataContext2)

    let activeKey = 'home'
    if (location.pathname.indexOf('/table') === 0) {
        activeKey = 'table'
    } else {
        let i = 0
        while (i<allPages.length && location.pathname.indexOf('/'+allPages[i].slug) === -1) {
            i++
        }
        if (i<allPages.length) {
            activeKey = allPages[i].slug
        }
    }

    return (
        <Nav variant='pills' defaultActiveKey='/' activeKey={activeKey} className='justify-content-lg-center'>
            <Nav.Item>
                <Nav.Link eventKey='home' as={Link} to='/'>Home</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey='table' as={Link} to='/table'>Data table</Nav.Link>
            </Nav.Item>
            {allPages.map(p => {
                return (
                    <Nav.Item>
                        <Nav.Link eventKey={p.slug} as={Link} to={"/"+p.slug}>{p.name}</Nav.Link>
                    </Nav.Item>
                )
            })}
        </Nav>
    )
}


const AppNavBar = () => {
    const isLoggedIn = useContext(LoginContext)
    let history = useHistory();

    return (
        <Navbar bg='sustainable' expand='md' className='font-weight-bold'>
            <Row className='w-100'>
                <Col xl={2} lg={12} md={12} sm={12}>
                    <Navbar.Brand><img alt='Sustainable power logo' src='/logo EU-MARIN ESSF.PNG' height={75}/></Navbar.Brand>
                </Col>
                <Col lg={2} className='d-xl-none' />
                <Col xl={8} lg={8} md={9} sm={12} className='mt-auto align-content-xl-center'>
                    <AppNavLinks/>
                </Col>
                <Col lg={2} md={3} sm={12} className='mt-auto my-0'>
                    <div className='w-100 text-right'>
                        { isLoggedIn ? (
                            <Button disabled={!isLoggedIn} onClick={() => history.push(`/settings`)} className='mr-2 font-weight-bold'>
                                <Gear size={20} />
                            </Button>
                        ) : null}
                        <Login className='font-weight-bold'>
                            {isLoggedIn ? (
                                <div>
                                    <PersonFill size={20} color='white'/>
                                </div>
                            ) : (
                                <div>
                                    <Person size={20} color='white'/>
                                </div>
                            )}
                        </Login>
                    </div>
                </Col>
            </Row>
        </Navbar>
    )
}


const AppNavSwitch = () => {
    const { allPages } = useContext(SustainablePowerDataContext2)

    return (
        <Switch>
            <Route path='/' exact={true}>
                <Home/>
            </Route>
            <Route path='/table' exact={true}>
                <SustainablePowerDataProvider>
                    <Table/>
                </SustainablePowerDataProvider>
            </Route>
            <Route path='/settings' exact={false}>
                <Settings />
            </Route>
            {allPages.map(p => {
                return (
                    <Route path={"/"+p.slug} exact={false}>
                        <Page page={p}/>
                    </Route>
                )
            })}
        </Switch>
    )
}

const Page = ({page}) => {
    let { path, url } = useRouteMatch();
    const isLoggedIn = useContext(LoginContext)

    const charts = page.charts.filter(ch => (isLoggedIn || !ch.secured)).sort((a,b) => a.index-b.index)

    return (
        <Switch>
            <Route path={path+"/table"} exact={true}>
                {page.id === "1" && <EnergyCarriersTable/>}
                {page.id === "2" && <WellToWakeTable/>}
            </Route>
            {charts.map(ch =>
                <Route path={path+'/'+ch.slug} exact={true}>
                    <Chart page={page} chart={ch}/>
                </Route>
            )}
            <Route path={path} exact={true}>
                <Row>
                    {(page.id === "1" || page.id === "2") &&
                        <ChartCard title={"Table"} linkUri={url+"/table"}>
                            <img src={page.id === "1" ? "/table.png" : "/table-wtw.png"} className={"w-100"}/>
                        </ChartCard>
                    }
                    {charts.map(ch =>
                        <ChartCard title={ch.title} sm={4} linkUri={url+"/"+ch.slug}>
                            {!ch.cardImg && <Chart page={page} chart={ch} isPreview={true}/>}
                            {ch.cardImg && <img src={"/"+ch.cardImg}/>}
                        </ChartCard>
                    )}
                </Row>
            </Route>
        </Switch>
    )
}

function App() {
    return (
        <SustainablePowerApolloProvider>
            <LoginContextProvider>
                <Router basename='/'>
                    {/*<div className='app d-flex flex-column min-vh-100'>*/}
                    <SustainablePowerDataProvider2>
                        <AppNavBar/>
                        <Container fluid>
                            <Row className='my-2 mx-3'>
                                <Col>
                                    <AppNavSwitch/>
                                </Col>
                            </Row>
                        </Container>
                    </SustainablePowerDataProvider2>
                    {/*</div>*/}
                </Router>
            </LoginContextProvider>
        </SustainablePowerApolloProvider>
    );
}

export default App;
