import React, { useState } from 'react';
import {Modal, OverlayTrigger, Tooltip, Button, ButtonGroup, Form} from 'react-bootstrap';
import {Pencil} from "react-bootstrap-icons";
import {useMutation} from "@apollo/react-hooks";
import {MUTATION_UPDATE_CATEGORY_GROUP} from "../../../../graphql/categoryGroup";
import {Formik, Field} from 'formik'


export const EditCategoryGroupModal = ({categoryGroup, show, setShow, ...props}) => {
    const [ updateCategoryGroup, { loading }] = useMutation(MUTATION_UPDATE_CATEGORY_GROUP)

    const onSubmit = (values, {setSubmitting}) => {
        updateCategoryGroup({
            variables: {
                data: {
                    categoryGroupId: categoryGroup.id,
                    name: values.name,
                    description: values.description
                }
            }
        }).then(() => setSubmitting(false)).then(() => setShow(false))
    }

    const initialValues = {
        name: categoryGroup.name,
        description: categoryGroup.description,
    }

    return (
        <Modal show={show} onHide={() => setShow(false)} onClick={e => e.stopPropagation()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit category group {categoryGroup.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {({values, handleSubmit, handleReset, isSubmitting}) => (
                        <Form onSubmit={handleSubmit} onReset={handleReset}>
                            <Form.Group>
                                <Form.Label>Name:</Form.Label>
                                <Field as={Form.Control} name='name'/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Description:</Form.Label>
                                <Field as={Form.Control} type='textarea' name='description' />
                            </Form.Group>
                            <Form.Group>
                                <ButtonGroup className='w-100'>
                                    <Button disabled={loading || isSubmitting} onClick={handleSubmit} type='submit'>Submit</Button>
                                    <Button variant='danger' onClick={() => setShow(false)}>Cancel</Button>
                                </ButtonGroup>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}


export const EditCategoryGroupModalButton = ({categoryGroup, ...props}) => {
    const [ show, setShow ] = useState(false);

    const openModal = (e) => {
        e.stopPropagation();
        setShow(true);
    }

    return (
        <React.Fragment>
            <OverlayTrigger overlay={<Tooltip id='edit-category-group-tooltip-id'>Edit this category group</Tooltip>}>
                <Button onClick={openModal} size='sm'>
                    <Pencil size={20}/>
                </Button>
            </OverlayTrigger>
            <EditCategoryGroupModal show={show} setShow={setShow} categoryGroup={categoryGroup} />
        </React.Fragment>
    )
}
