import React, {useState} from 'react';
import {Modal, Row, Col, OverlayTrigger, Tooltip, Button, ButtonGroup, Form} from 'react-bootstrap';
import {Trash} from "react-bootstrap-icons";
import {useMutation} from "@apollo/react-hooks";
import {
    MUTATION_DELETE_CATEGORY_GROUP,
    QUERY_CATEGORY_GROUPS
} from "../../../../graphql/categoryGroup";


export const DeleteCategoryGroupModal = ({show, setShow, categoryGroup, ...props}) => {
    const [deleteCategoryGroup, {loading}] = useMutation(MUTATION_DELETE_CATEGORY_GROUP, {
        refetchQueries: [{query: QUERY_CATEGORY_GROUPS}],
        variables: { data: { categoryGroupId: categoryGroup.id } },
    })

    const onSubmit = () => {
        deleteCategoryGroup().then(() => setShow(false))
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Delete a category group
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        This will delete the category group: {categoryGroup.name}. Confirm?
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        <ButtonGroup className='w-100'>
                            <Button disabled={loading} onClick={onSubmit}>Confirm</Button>
                            <Button disabled={loading} variant='danger' onClick={() => setShow(false)}>Cancel</Button>
                        </ButtonGroup>
                    </Col>
                </Row>

            </Modal.Body>
        </Modal>
    )
}


export const DeleteCategoryGroupModalButton = ({categoryGroup, ...props}) => {
    const [show, setShow] = useState(false);

    const onClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShow(true);
    }

    return (
        <React.Fragment>
            <OverlayTrigger
                overlay={
                    <Tooltip id={`delete-category-group-${categoryGroup.id}-tooltip-id`}>
                        Delete this category group
                    </Tooltip>}
            >
                <Button variant='danger' onClick={onClick} size='sm'>
                    <Trash size={20}/>
                </Button>
            </OverlayTrigger>
            <DeleteCategoryGroupModal categoryGroup={categoryGroup} show={show} setShow={setShow}/>
        </React.Fragment>
    )
}
