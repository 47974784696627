import React, {useContext} from "react";
import {ChartContext} from "./Chart";
import chroma from "chroma-js";
import {HCExporting} from "./HCExporting";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HC_exporting from 'highcharts/modules/exporting';
import HC_offline_exporting from 'highcharts/modules/offline-exporting';
import HighchartsMore from 'highcharts/highcharts-more';
HighchartsMore(Highcharts);
HC_exporting(Highcharts);
HC_offline_exporting(Highcharts);

export const ColumnRangeAndBar = ({}) => {
    const { chart, settings, features, entities, isPreview } = useContext(ChartContext)

    const height = isPreview ? 300 : (settings.isTwoSeries ? 1.75 : 1)*Math.pow(entities.length, 0.65)*120

    const baseEntityIndex = entities.findIndex(e => e.id === settings.base)

    const exportFeatures = settings.isColumnRange ? features.filter(f => !f.name.includes('WTW')) : features

    let xlsxHeaderRow = [
        {type: 'string', value: 'Stack'}
    ]
    exportFeatures.forEach(f => xlsxHeaderRow.push({type: 'string', value: f.name}))
    const xlsxRows = [xlsxHeaderRow].concat(entities.map(e => {
        let eRow = [{type: 'string', value: e.name}]
        exportFeatures.forEach(f => eRow.push({type: 'number', value: e.values[f.name]}))
        return eRow
    }))

    const csvRows = entities.map(e => {
        let eRow = {
            "Stack": e.name,
        }
        exportFeatures.forEach(f => eRow[f.name] = e.values[f.name])
        return eRow
    })

    const labels = entities.map(e => e.name)
    const series = features.map(f => {
        if (settings.isColumnRange) {
            return {
                name: f.name,
                unit: f.unit,
                data: entities.map(e => {
                    const categoryColor = e.categories.find(c => c.categoryGroupId === settings.colorBy) ?
                        e.categories.find(c => c.categoryGroupId === settings.colorBy).color :
                        '#800080'

                    if (f.name.includes('WTT')) {
                        return {
                            low: e.values[f.name] < 0 ? e.values[f.name] : 0,
                            high: e.values[f.name] < 0 ? 0 : e.values[f.name],
                            color: categoryColor,
                        }
                    } else if (f.name.includes('TTW')) {
                        return {
                            low: e.values[f.name.slice(0, -3) + 'WTT'],
                            high: e.values[f.name.slice(0, -3) + 'WTT'] + e.values[f.name],
                            color: chroma(categoryColor).darken(1).hex(),
                        }
                    } else {
                        return {
                            low: e.values[f.name.slice(0, -3) + 'WTT'] + e.values[f.name.slice(0, -3) + 'TTW'],
                            high: e.values[f.name.slice(0, -3) + 'WTT'] + e.values[f.name.slice(0, -3) + 'TTW'],
                        }
                    }
                }),
                dataLabels: [{
                    enabled: f.name.includes('WTW') && settings.valueLabels,
                    formatter: function () {
                        return this.y.toFixed(1)
                    }
                }, {
                    enabled: f.name.includes('WTT') && settings.isTwoSeries && settings.seriesLabels,
                    formatter: function () {
                        return f.name.split('-')[0]
                    }
                }],
                tooltip: {
                    // shared: true,
                    pointFormatter: function () {
                        const value = (this.series.name.includes("WTT") && this.high === 0 || this.series.name.includes("WTW")) ? this.low : this.high - this.low
                        // return "<span style='border-radius: 50%; height: 7px; width: 7px; display: inline-block; background-color: "+this.series.color+";'></span> "+this.series.name + ": " + value.toFixed(3) + " " + this.series.options.unit + "<br/>"
                        return this.series.name + ": " + value.toFixed(1) + " " + this.series.options.unit + "<br/>"
                    }
                },
                states: {
                    hover: {
                        // brightness: 0.5,
                        borderColor: "#ff0000",
                    },
                },
                grouping: false,
                // pointPadding: twoSeries ? (f.name.includes('WTT')?-0.1:0.1) : (f.name.includes('WTT')?-0.2:0.2),
                pointWidth: settings.isTwoSeries ? (f.name.includes('WTT') ? 12 : 6) : (f.name.includes('WTT') ? 20 : 10),
                pointPlacement: settings.isTwoSeries ? (f.name.includes('100') ? 0.18 : -0.18) : 0

            }
        } else {
            const baseStack = entities.find(e => e.id === settings.base)

            return {
                name: f.name,
                unit: settings.base ? '%' : f.unit,
                data: entities.map(e => {
                    const categoryColor = e.categories.find(c => c.categoryGroupId === settings.colorBy) ?
                        e.categories.find(c => c.categoryGroupId === settings.colorBy).color :
                        '#800080'
                    return {
                        y: settings.base ? (baseStack.values[f.name]<0 ? -1 : 1)*(100.0 + (100.0 * (e.values[f.name] - baseStack.values[f.name]) / baseStack.values[f.name])) : e.values[f.name],
                        value: e.values[f.name],
                        color: categoryColor
                    }
                }),
                states: {
                    hover: {
                        // brightness: 0.5,
                        borderColor: "#ff0000",
                    },
                },
                dataLabels: [{
                    enabled: settings.valueLabels,
                    formatter: function () {
                        return this.y.toFixed(1) + (settings.base ? ' %' : '')
                    }
                }, {
                    align: "left",
                    inside: true,
                    enabled: settings.isTwoSeries && settings.seriesLabels,
                    formatter: function () {
                        return f.name.split('-')[0]
                    }
                }],
                tooltip: {
                    pointFormatter: function () {
                        // return "<span style='border-radius: 50%; height: 7px; width: 7px; display: inline-block; background-color: "+this.series.color+";'></span> " +
                        return f.name + ": " +
                            (settings.base ? this.value.toFixed(1) : this.y.toFixed(1)) + " " +
                            f.unit + "<br/>"
                    }
                },
                pointWidth: settings.isTwoSeries ? 10 : 15,
                pointPlacement: settings.isTwoSeries ? (f.name.includes('100') ? -0.3 : 0.3) : 0
            }
        }
    })

    const options = {
        chart: {
            type: settings.isColumnRange ? 'columnrange' : 'bar',
            inverted: true,
            height: height,
        },
        title: {
            text: isPreview ? '' : "Emissions (Global warming potentials) for stacks"
        },
        series: series,
        // colors: features.map(f => f.color),
        xAxis: {
            categories: labels,
            labels: {},
            plotBands: settings.base ? [
                {
                    color: 'orange',
                    from: baseEntityIndex-0.5,
                    to: baseEntityIndex+0.5
                }
            ] : null
        },
        yAxis: [{
            title: {
                text: 'gram CO2 eq. per kWh'
            },
            lineWidth: 1,
            // gridLineColor: 'grey',
            gridLineWidth: 1,
            // plotLines: [{
            //     value: 0,
            //     color: 'black',
            //     width: 1,
            // }]
        }, {
            title: {
                text: 'gram CO2 eq. per kWh'
            },
            lineWidth: 1,
            gridLineWidth: 0,
            linkedTo: 0,
            opposite: true
        }],
        legend: {
            enabled: false,
            verticalAlign: "top",
            labelFormat: "{name} [{options.unit}]"
        },
        tooltip: {
            shared: true,
            useHTML: true,
            followPointer: true,
            // positioner: (labelWidth, labelHeight, point) => { return {x: point.plotX-100, y: point.plotY} }
        },
        ...HCExporting(true, 1504, isPreview, chart.name, xlsxRows, csvRows)

    }

    return (
        <HighchartsReact
            immutable={true}
            highcharts={Highcharts}
            options={options}
        />
    )
}