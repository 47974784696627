import React, {useContext} from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HC_exporting from 'highcharts/modules/exporting';
import HC_offline_exporting from 'highcharts/modules/offline-exporting';
import HighchartsSankey from 'highcharts/modules/sankey';
import {HCExporting} from "./HCExporting";
import {entityInSelectedCategories} from "../functions/entityInSelectedCategories";
import chroma from "chroma-js";
import {ChartContext} from "./Chart";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
HighchartsSankey(Highcharts);
HC_exporting(Highcharts);
HC_offline_exporting(Highcharts);

export const Flow = ({}) => {
    const { allStacks, allSystems } = useContext(SustainablePowerDataContext2)
    const { settings, features, categoryGroups, categories, allColumns, chart } = useContext(ChartContext)

    const emissionFormula = features[0]

    const columns = allColumns.filter(col => !settings.disabledColumns.includes(col.id))

    let columnGroups = [
        categoryGroups.find(cg => cg.id === "2").categories.sort((a, b) => a.index - b.index),
        categoryGroups.find(cg => cg.id === "3").categories.sort((a, b) => a.index - b.index),
        allSystems.filter(sys => sys.systemTypeId === "3").sort((a, b) => a.index - b.index),
        allSystems.filter(sys => sys.systemTypeId === "5").sort((a, b) => a.index - b.index),
        [
            {name: '=<0%', interval: [Number.NEGATIVE_INFINITY, 0], color: '#69B34C'},
            {name: '0-25%', interval: [Number.EPSILON, 0.25], color: '#ACB334'},
            {name: '25-50%', interval: [0.25, 0.5], color: '#FAB733'},
            {name: '50-75%', interval: [0.5, 0.75], color: '#FF8E15'},
            {name: '75-100%', interval: [0.75, 1 - Number.EPSILON], color: '#FF4E11'},
            {name: '100%', interval: [1, 1], color: '#000000'},
            {name: '>100%', interval: [1 + Number.EPSILON, Number.POSITIVE_INFINITY], color: '#FF0D0D'},
        ]
    ].filter((col, i) => !settings.disabledColumns.includes(i + ""))

    let nodes = []
    for (let i = 0; i < columnGroups.length; i++) {
        for (let j = 0; j < columnGroups[i].length; j++) {
            let id
            if (columnGroups[i][j].name === "Metal") {
                id = columns[i].name === "Resource" ? "Metal (Resource)" : "Metal (Carrier)"
            } else if (columnGroups[i][j].name === "None") {
                id = columns[i].name === "Energy Conversion" ? "None (Conversion)" : "None (Power Dist & Drive)"
            } else {
                id = columnGroups[i][j].name
            }
            nodes.push({
                id: id,
                column: i,
                color: "color" in columnGroups[i][j] ? columnGroups[i][j].color : '#000000'
            })
        }
    }

    const baseEmission = allStacks.find(stk => stk.id === settings.base).values[emissionFormula.name]

    let connections = []
    allStacks.filter(stk => stk.systems.length > 0).filter(stk => entityInSelectedCategories(stk, settings.selectedCategories, categories))
        .sort((a, b) => {
            if (settings.sortByColumn === "0" || settings.sortByColumn === "1") {
                const aCat = settings.sortByColumn === "0" ?
                    a.categories.find(c => c.categoryGroupId === "2") :
                    a.categories.find(c => c.categoryGroupId === "3")
                const bCat = settings.sortByColumn === "0" ?
                    b.categories.find(c => c.categoryGroupId === "2") :
                    b.categories.find(c => c.categoryGroupId === "3")

                if (aCat !== undefined) {
                    if (bCat !== undefined) {
                        if (aCat.index === null) {
                            if (bCat.index === null) {
                                return 0
                            }
                            return 1
                        }
                        if (bCat.index === null) {
                            return -1
                        }
                        return aCat.index - bCat.index
                    }
                    return -1
                } else {
                    if (bCat !== undefined) {
                        return 1
                    }
                    return 0
                }
            }
            if (settings.sortByColumn === "2" || settings.sortByColumn === "3") {
                const aSysIndex = settings.sortByColumn === "2" ?
                    a.systems.find(sys => sys.system.systemTypeId === "3").system.index :
                    a.systems.find(sys => sys.system.systemTypeId === "5").system.index
                const bSysIndex = settings.sortByColumn === "2" ?
                    b.systems.find(sys => sys.system.systemTypeId === "3").system.index :
                    b.systems.find(sys => sys.system.systemTypeId === "5").system.index

                if (aSysIndex === null) {
                    if (bSysIndex === null) {
                        return 0
                    }
                    return 1
                }
                if (bSysIndex === null) {
                    return -1
                }
                return aSysIndex - bSysIndex
            }
            if (settings.sortByColumn === "4") {
                return b.values[emissionFormula.name] - a.values[emissionFormula.name]
            }
        })
        .forEach(stk => {
            let stackColumns = []
            const col = stk.categories.find(c => c.categoryGroupId === "2")
            const color = col !== undefined ? col.color : '#800080'
            if (!settings.disabledColumns.includes("0")) {
                if (col !== undefined) {
                    stackColumns.push(col.name !== "Metal" ? col.name : "Metal (Resource)")
                } else {
                    stackColumns.push(undefined)
                }
            }
            if (!settings.disabledColumns.includes("1")) {
                const col = stk.categories.find(c => c.categoryGroupId === "3")
                if (col !== undefined) {
                    stackColumns.push(col.name !== "Metal" ? col.name : "Metal (Carrier)")
                } else {
                    stackColumns.push(undefined)
                }
            }
            if (!settings.disabledColumns.includes("2")) {
                const colName = stk.systems.find(sys => sys.stackTypeSystemFieldId === "4").system.name
                stackColumns.push(colName !== "None" ? colName : "None (Conversion)")
            }
            if (!settings.disabledColumns.includes("3")) {
                let colName;
                if (chart.id === "12") {
                    colName = stk.systems.find(sys => sys.stackTypeSystemFieldId === "6").system.name
                    stackColumns.push(colName !== "None" ? colName : "None (Power Dist & Drive EC)")
                } else {
                    colName = stk.systems.find(sys => sys.stackTypeSystemFieldId === "2").system.name
                    stackColumns.push(colName !== "None" ? colName : "None (Power Dist & Drive)")
                }

            }
            if (!settings.disabledColumns.includes("4")) {
                const relativeEmission = stk.values[emissionFormula.name] / baseEmission
                stackColumns.push(columnGroups[columnGroups.length - 1].find(g => g.interval[0] <= relativeEmission && g.interval[1] >= relativeEmission).name)
            }

            for (let j = 0; j < stackColumns.length - 1; j++) {
                if (stackColumns[j] !== undefined && stackColumns[j + 1] !== undefined) {
                    connections.push({
                        name: stk.name,
                        header: stackColumns.join(" → "),
                        value: emissionFormula.name + ": " + (stk.values[emissionFormula.name]===null ? "NULL" : stk.values[emissionFormula.name].toFixed(1)),
                        from: stackColumns[j],
                        to: stackColumns[j + 1],
                        weight: 1,
                        color: stk.values[emissionFormula.name]===null ? "#000" : chroma(color).alpha(0.5).hex(),
                        selected: false,
                        events: {
                            update: function (event) {
                                if (event.options.line === this.name) {
                                    this.selected = event.options.selected
                                    // this.color = event.options.selected ? '#000000' : this.custom
                                }
                            },
                            mouseOver: function () {
                                this.series.data.filter(line => line.name === this.name).forEach(line => line.update({
                                    line: this.name,
                                    selected: true
                                }))
                            },
                            mouseOut: function () {
                                this.series.data.filter(line => line.name === this.name).forEach(line => line.update({
                                    line: this.name,
                                    selected: false
                                }))
                            }
                        }
                    })
                }
            }
        })


    let columnTickMarks = []
    let columnTickMarkLocations = []
    for (let i = 0; i < columns.length; i++) {
        columnTickMarks[(360/(columns.length-1))*i] = columns[i].name
        columnTickMarkLocations.push((360/(columns.length-1))*i)
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={{
                chart: {
                    showAxes: true,
                    height: 700
                },
                title: {
                    text: ""
                },
                series: [{
                    keys: ['from', 'to', 'weight'],
                    data: connections,
                    nodes: nodes,
                    // dataLabels: ,
                    tooltip: {
                        headerFormat: '',
                        // nodeFormat: '{point.name}: <b>{point.sum}</b><br/>',
                        pointFormat: '<span style="font-size: 10px">{point.header}</span><br/><b>{point.name}</b><br/>{point.value} g/kWh<br/>'
                    },
                    type: 'sankey',
                    linkOpacity: 1,
                    states: {
                        hover: {},
                        inactive: {},
                        select: {
                            color: '#000000',
                            // lineWidth: 10,
                            // lineWidthPlus: 5
                        }
                    },
                }],
                xAxis: {
                    categories: columnTickMarks,
                    max: 360,
                    opposite: true,
                    lineWidth: 0,
                    tickmarkPlacement: 'on',
                    tickPositions: columnTickMarkLocations,
                    labels: {
                        format: '<b>{value}</b>'
                    },
                },
                yAxis: {
                    visible: false
                },
                ...HCExporting(false, 1794)
            }}
        />
    )
}