import React, {useContext} from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HC_exporting from 'highcharts/modules/exporting';
import HC_offline_exporting from 'highcharts/modules/offline-exporting';
import HC_export_data from 'highcharts/modules/export-data';
import {HCExporting} from "./HCExporting";
import {onEntityHover} from "../fields/EntitiesCheckboxes";
import {ChartContext} from "./Chart";
HC_exporting(Highcharts);
HC_offline_exporting(Highcharts);
HC_export_data(Highcharts);

export const Bubble = ({}) => {
    const { chart, forStacks, settings, features, entities, valueForFormulaOrSPG, isPreview } = useContext(ChartContext)

    const data = entities.map(e => {
        return {
            name: e.name,
            x: forStacks ? valueForFormulaOrSPG(e, features[0]) : e.values[features[0].name]["average"],
            y: forStacks ? valueForFormulaOrSPG(e, features[1]) : e.values[features[1].name]["average"],
            z: forStacks ? valueForFormulaOrSPG(e, features[2]) : e.values[features[2].name]["average"],
            color: e.categories.find(c => c.categoryGroupId === settings.colorBy) !== undefined ? e.categories.find(c => c.categoryGroupId === settings.colorBy).color : '#800080',
            events: !isPreview ? {
                mouseOver: () => onEntityHover(e.id)
            } : {}
        }
    })

    const xlsxRows = [[
        {type: 'string', value: forStacks ? "Stack" : "Energy Carrier"},
        {type: 'string', value: features[0].displayName},
        {type: 'string', value: features[1].displayName},
        {type: 'string', value: features[2].displayName}
    ]].concat(data.map(e => [
        {type: 'string', value: e.name},
        {type: 'number', value: e.x},
        {type: 'number', value: e.y},
        {type: 'number', value: e.z}
    ]))

    const csvRows = data.map(e => {
        return {
            [forStacks ? "Stack" : "Energy Carrier"]: e.name,
            [features[0].displayName]: e.x,
            [features[1].displayName]: e.y,
            [features[2].displayName]: e.z
        }
    })

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={{
                chart: {
                    type: 'bubble',
                    height: isPreview ? 300 : 600,
                    plotBorderWidth: 1,
                    zoomType: 'xy'
                },
                legend: {
                    // enabled: false,
                    align: "right",
                    verticalAlign: "middle",
                    layout: 'vertical',
                    bubbleLegend: {
                        enabled: !isPreview,
                    }
                },
                title: {
                    text: isPreview ? "" : chart.title
                },
                xAxis: {
                    gridLineWidth: 1,
                    title: {
                        text: features[0].displayName + (features[0].unit === "" ? "" : ' [' + features[0].unit + ']')
                    },
                    // labels: {
                    //     format: '{value} '+axes.x.unit
                    // },
                },
                yAxis: {
                    startOnTick: false,
                    endOnTick: false,
                    title: {
                        text: features[1].displayName + (features[1].unit === "" ? "" : ' [' + features[1].unit + ']')
                    },
                    // labels: {
                    //     format: '{value} '+axes.y.unit
                    // },
                    maxPadding: 0.2,
                },
                tooltip: {
                    useHTML: true,
                    headerFormat: '<table>',
                    // pointFormat: '<tr><th colspan="2"><h3>{point.name}</h3></th></tr>' +
                    //     '<tr><th>'+axes.x.name+'</th><td>{point.x} '+axes.x.unit+'</td></tr>' +
                    //     '<tr><th>'+axes.y.name+'</th><td>{point.y} '+axes.y.unit+'</td></tr>' +
                    //     '<tr><th>'+axes.z.name+'</th><td>{point.z} '+axes.z.unit+'</td></tr>',
                    pointFormatter: function () {
                        return '<tr><th colspan="2"><h3>' + this.name + '</h3></th></tr>' +
                            '<tr><th>' + features[0].displayName + '</th><td>' + this.x.toFixed(3) + ' ' + features[0].unit + '</td></tr>' +
                            '<tr><th>' + features[1].displayName + '</th><td>' + this.y.toFixed(3) + ' ' + features[1].unit + '</td></tr>' +
                            '<tr><th>' + features[2].displayName + '</th><td>' + this.z.toFixed(3) + ' ' + features[2].unit + '</td></tr>'
                    },
                    footerFormat: '</table>',
                    followPointer: true
                },
                series: [{
                    dataLabels: {
                        // allowOverlap: true,
                        enabled: true,
                        // format: '{point.name}',
                        formatter: function () {
                            if (this.point.name.length>10) {
                                const nameParts = this.point.name.split(' ')
                                let letterAmount = 0
                                let i = 0
                                while (!(letterAmount >= Math.ceil(this.point.name.length/2))) {
                                    letterAmount += nameParts[i].length+1
                                    i++
                                }
                                return nameParts.slice(0, i).join(' ') + "<br/>" + nameParts.slice(i).join(' ')
                            }
                            return this.point.name
                        }
                    },
                    showInLegend: false,
                    data: data,
                }],
                ...HCExporting(true, 2016, isPreview, chart.name, xlsxRows, csvRows)
            }}
        />
    )
}