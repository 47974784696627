import gql from 'graphql-tag';


export const QUERY_SYSTEM_PROPERTY_GROUPS = gql`
    query getSystemPropertyGroups {
        systemPropertyGroups {
            id
            name
            shortName
            description
            color
            unit
            index
            systemType { id name }
        }
    }
`


export const MUTATION_UPDATE_SYSTEM_PROPERTY_GROUP = gql`
    mutation updateSystemPropertyGroup($data: UpdateSystemPropertyGroupInput!) {
        updateSystemPropertyGroup(
            data: $data
        ) {
            id
            name
            description
            color
            unit
            systemType { id name }
        }
    }
`