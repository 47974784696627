import React from "react";
import {Button, Card, Form, Row, Col, ListGroup, ButtonGroup} from "react-bootstrap";
import {useQuery, useMutation} from "@apollo/react-hooks";
import { QUERY_SYSTEM_TYPE } from "../../../../graphql/systemType";
import './../SystemTypeCard.css';
import { Formik, Field } from 'formik'
import { useParams } from 'react-router-dom';
import {MUTATION_UPDATE_SYSTEM} from "../../../../graphql/system";
import { SystemAddButton} from "./add-button";
import {QUERY_ENTITY_TYPE} from "../../../../graphql/entityType";
import {SelectField} from "../../../general/forms/SelectField";
import {QUERY_CATEGORIES} from "../../../../graphql/category";


const SystemForm = ({system, entityType, metaFields, ...props}) => {
    const [ updateSystem ] = useMutation(MUTATION_UPDATE_SYSTEM)

    const processedMetaData = metaFields.map(mf => {
        const fieldData = system.metaData.find(md => md.systemTypeMetaField.id === mf.id)
        return {...mf,
            systemMetaDataId: (!!fieldData) ? fieldData.id : undefined,
            value: (!!fieldData) ? (fieldData.value || ''): ''}
    })

    const { data: categoriesData, loading: categoriesLoading } = useQuery(QUERY_CATEGORIES)

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                name: system.name,
                categories: entityType.categoryGroups.map(cg => {
                    const chosenCat = system.categories.find(c => c.categoryGroupId === cg.id);
                    return !!chosenCat ? {categoryGroupId: cg.id, categoryId: chosenCat.id} : {categoryGroupId: cg.id, categoryId: ''}
                }),
                metaData: processedMetaData.map(pmd => ({
                    systemId: system.id,
                    value: pmd.value,
                    systemTypeMetaFieldId: pmd.id,
                    systemMetaDataId: pmd.systemMetaDataId,
                    name: pmd.name}))
            }}
            onSubmit={(values, { setSubmitting }) => updateSystem({
                variables: {
                    data: {
                        systemId: system.id,
                        name: values.name,
                        metaData: values.metaData.map(md => ({
                            systemId: system.id,
                            value: md.value,
                            systemTypeMetaFieldId: md.systemTypeMetaFieldId,
                            systemMetaDataId: md.systemMetaDataId,
                        })),
                        categories: values.categories.filter(c => c.categoryId !== ''),
                    }
                }
            }).then(() => setSubmitting(false))
            }
        >
            {({ values, handleSubmit, handleReset, resetForm, dirty} ) => (
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                    <Row className='mb-3'>
                        <Col>
                            <h5>{system.name}</h5>
                        </Col>
                        <Col className='text-right'>
                            <ButtonGroup>
                                <Button type='submit' variant={(dirty) ? 'warning' : 'primary'}>Submit</Button>
                                <Button type='reset' onClick={() => resetForm()}>Reset</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group as={Row}>
                                <Col md={3}>
                                    <Form.Label>Name</Form.Label>
                                </Col>
                                <Col>
                                    <Field as={Form.Control} name='name'/>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            {values.metaData.map((md, idx) => (
                                <Form.Group as={Row} key={idx}>
                                    <Col md={3}>
                                        <Form.Label>{md.name}</Form.Label>
                                    </Col>
                                    <Col>
                                        <Field as={Form.Control} name={`metaData[${idx}].value`} />
                                    </Col>
                                </Form.Group>
                            ))}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6>Categories</h6>
                        </Col>
                    </Row>
                    { (categoriesLoading) ? (
                        <Row><Col>Loading category group data.....</Col></Row>
                    ) : (
                        entityType.categoryGroups.map((categoryGroup, cgIdx) => (
                            <Form.Group as={Row} key={categoryGroup.id}>
                                <Col>
                                    <Form.Label>{categoryGroup.name}</Form.Label>
                                </Col>
                                <Col>
                                    <SelectField name={`categories[${cgIdx}].categoryId`}>
                                        <option value=''>No category</option>
                                        { categoriesData.categoryGroups.find(c => c.id === categoryGroup.id).categories.map(c => (
                                            <option key={c.id} value={c.id}>{c.name}</option>
                                        ))}
                                    </SelectField>
                                </Col>
                            </Form.Group>
                        ))
                    )}
                </Form>
            )}
        </Formik>
    )
}


const SystemSettingsTable = ({systemTypeId, systems, metaFields, ...props}) => {
    const { data, loading } = useQuery(QUERY_ENTITY_TYPE, {
        variables: {entityTypeId: systemTypeId}
    })

    if (loading) return 'Loading.....'

    return (
        <ListGroup variant='flush'>
            {systems.map(s => {
                return (
                    <ListGroup.Item key={s.id} className='px-0'>
                        <SystemForm entityType={data.entityType} system={s} metaFields={metaFields} />
                    </ListGroup.Item>
                )
            })}
        </ListGroup>
    )
}


export const Systems = ({...props}) => {
    const { systemTypeId } = useParams();

    const { data, loading } = useQuery(QUERY_SYSTEM_TYPE, {
        variables: { systemTypeId: systemTypeId }
    })

    if (loading) return <b>Loading....</b>

    return (
        <Card>
            <Card.Header>
                <div className='d-flex'>
                    <h3 className='mr-auto'>Systems</h3>
                    <SystemAddButton>Add system</SystemAddButton>
                </div>
            </Card.Header>
            <Card.Body>
                <SystemSettingsTable systemTypeId={systemTypeId} systems={data.systemType.systems} metaFields={data.systemType.metaFields} />
            </Card.Body>
        </Card>
    )
}