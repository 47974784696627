import gql from 'graphql-tag';
import {FRAGMENT_STACK_TYPE_FORMULA_CONTENT} from "./stackTypeFormula";
import {FRAGMENT_STACK_CONTENT} from "./stack";


export const QUERY_STACK_TYPES = gql`
    query stackTypes {
        stackTypes {
            id
            name
        }
    }
`


export const QUERY_STACK_TYPE = gql`
    query stackType($stackTypeId: ID!) {
        stackType(stackTypeId: $stackTypeId) {
            id
            name
            systemFields {
                id
                name
                description
                index
                systemTypeId
            }
            formulas {
                id
                ...stackTypeFormulaContent
            }
            stacks {
                id
                ...stackContent
            }
            categoryGroups {
                id
                name
            }
        }
    }
    ${FRAGMENT_STACK_TYPE_FORMULA_CONTENT}
    ${FRAGMENT_STACK_CONTENT}
`