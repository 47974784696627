import React, { useState } from 'react';
import {Modal, OverlayTrigger, Tooltip, Button, ButtonGroup, Form} from 'react-bootstrap';
import {Plus} from "react-bootstrap-icons";
import {useMutation} from "@apollo/react-hooks";
import {MUTATION_CREATE_CATEGORY_GROUP, QUERY_CATEGORY_GROUPS} from "../../../../graphql/categoryGroup";
import {Formik, Field} from 'formik'


export const AddCategoryGroupModal = ({show, setShow, ...props}) => {
    const [ createCategoryGroup, { loading }] = useMutation(MUTATION_CREATE_CATEGORY_GROUP, {
        refetchQueries: [{query: QUERY_CATEGORY_GROUPS}],
    })

    const onSubmit = (values, {setSubmitting}) => {
        createCategoryGroup({
            variables: {
                data: {
                    name: values.name,
                    description: values.description
                }
            }
        }).then(() => setSubmitting(false)).then(() => setShow(false))
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Add a new category group
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        name: '',
                        description: '',
                    }}
                    onSubmit={onSubmit}
                >
                    {({handleSubmit, handleReset, isSubmitting}) => (
                        <Form onSubmit={handleSubmit} onReset={handleReset}>
                            <Form.Group>
                                <Form.Label>Name:</Form.Label>
                                <Field as={Form.Control} name='name'/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Description:</Form.Label>
                                <Field as={Form.Control} type='textarea' name='description' />
                            </Form.Group>
                            <Form.Group>
                                <ButtonGroup className='w-100'>
                                    <Button disabled={loading || isSubmitting} type='submit'>Submit</Button>
                                    <Button variant='danger' onClick={() => setShow(false)}>Cancel</Button>
                                </ButtonGroup>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}


export const AddCategoryGroupModalButton = ({props}) => {
    const [ show, setShow ] = useState(false);

    return (
        <React.Fragment>
            <OverlayTrigger overlay={<Tooltip id='add-category-group-tooltip-id'>Add a category group</Tooltip>}>
                <Button onClick={() => setShow(true)} size='sm'>
                    <Plus size={20}/>
                </Button>
            </OverlayTrigger>
            <AddCategoryGroupModal show={show} setShow={setShow} />
        </React.Fragment>
    )
}
