import gql from "graphql-tag";


export const IS_LOGGED_IN = gql`
    query IsUserLoggedIn {
        isLoggedIn @client
    }
`;


export const LOGIN_QUERY = gql`
    mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password ) {
            tokens {
                accessToken
            }
            user {
                id
                email
            }
        }
    }
`;


export const USER_QUERY = gql`
    query User {
        user {
            id
            email
        }
    }
`