import gql from 'graphql-tag';
import {FRAGMENT_CHART_FIELD_CONTENT} from "./chartField";
import {FRAGMENT_CHART_SETTING_CONTENT} from "./chartSetting";

export const FRAGMENT_CHART_CONTENT = gql`
    fragment chartContent on ChartObject {
        pageId
        entityTypeId
        index
        secured
        type
        name
        features
        slug
        title
        cardImg
        chartFields {
            id
            ...chartFieldContent
        }
        chartSettings {
            id
            ...chartSettingContent
        }
    }
    ${FRAGMENT_CHART_FIELD_CONTENT}
    ${FRAGMENT_CHART_SETTING_CONTENT}
`


export const QUERY_CHARTS = gql`
    query charts {
        charts {
            id
            ...chartContent
        }
    }
    ${FRAGMENT_CHART_CONTENT}
`


export const QUERY_CHART = gql`
    query page($chartId: ID!) {
        chart(chartId: $chartId) {
            id
            ...chartContent
        }
    }
    ${FRAGMENT_CHART_CONTENT}
`


export const MUTATION_CREATE_CHART = gql`
     mutation createChart($pageId: ID!, $entityTypeId: ID!, $index: Int, $secured: Boolean, $type: String!, $name: String!, $features: JSONString!, $slug: String!, $title: String, $cardImg: String) {
        createChart(pageId: $pageId, entityTypeId: $entityTypeId, index: $index, secured: $secured, type: $type, name: $name, features: $features, slug: $slug, title: $title, cardImg: $cardImg) {
            ok
            chart {
                id
                ...chartContent
            }
        }
    }
    ${FRAGMENT_CHART_CONTENT}
`


export const MUTATION_UPDATE_CHART = gql`
     mutation updateChart($chartId: ID!, $pageId: ID, $entityTypeId: ID, $index: Int, $secured: Boolean, $type: String, $name: String, $features: JSONString, $slug: String, $title: String, $cardImg: String) {
        updateChart(chartId: $chartId, pageId: $pageId, entityTypeId: $entityTypeId, index: $index, secured: $secured, type: $type, name: $name, features: $features, slug: $slug, title: $title, cardImg: $cardImg) {
            ok
            chart {
                id
                ...chartContent
            }
        }
    }
    ${FRAGMENT_CHART_CONTENT}
`


export const MUTATION_DELETE_CHART = gql`
    mutation deleteChart($chartId: ID!) {
        deleteChart(chartId: $chartId) {
            ok
            chart {
                id
                ...chartContent
            }
        }
    }
    ${FRAGMENT_CHART_CONTENT}
`
