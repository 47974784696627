import React, {Fragment, useContext} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {ChartContext} from "../charts/Chart";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";

export const ColumnForm = ({}) => {
    const { allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const { chart, settings, setURL, allColumns } = useContext(ChartContext)

    return (
        <Fragment>
            <Row>
                <Col><b>Show/hide columns:</b></Col>
                <Col lg={3}><b>Sort by:</b></Col>
            </Row>
            <Form.Group>
                {allColumns.map(col =>
                    <Row>
                        <Col>
                            <Form.Check
                                type={'checkbox'}
                                value={col.id}
                                label={col.name}
                                checked={!settings.disabledColumns.includes(col.id)}
                                onChange={e => {
                                    if (settings.disabledColumns.includes(e.target.value)) {
                                        settings.disabledColumns = settings.disabledColumns.filter(colId => colId !== e.target.value)
                                    } else {
                                        settings.disabledColumns.push(e.target.value)
                                    }
                                    setURL(chart, settings)
                                    setAllSettings({...allSettings})
                                }}
                            />
                        </Col>
                        <Col lg={3}>
                            <Form.Check
                                type={'radio'}
                                value={col.id}
                                checked={settings.sortByColumn === col.id}
                                onChange={e => {
                                    settings.sortByColumn = e.target.value
                                    setURL(chart, settings)
                                    setAllSettings({...allSettings})
                                }}
                            />
                        </Col>
                    </Row>
                )}
            </Form.Group>
        </Fragment>
    )
}