import React, { useContext } from 'react'
import {StackFormik} from "./stack-formik";
import {Button, ButtonGroup, Form, Spinner} from 'react-bootstrap'
import {useMutation} from "@apollo/react-hooks";
import { MUTATION_DELETE_STACK} from "../../../../../graphql/stack";
import {ModalFormContext} from './delete-modal';
import {QUERY_STACK_TYPE} from "../../../../../graphql/stackType";
import {useParams} from "react-router";


export const StackDeleteForm = ({stack, ...props}) => {
    const {stackTypeId} = useParams();
    const {closeModal} = useContext(ModalFormContext);
    const [ deleteStack, { loading } ] = useMutation(MUTATION_DELETE_STACK, {
        update: (cache, { data: { deleteStack }}) => {
            const oldData = cache.readQuery({query: QUERY_STACK_TYPE, variables: {stackTypeId: stackTypeId}})
            console.log(oldData);
            const newData = {stackType: {...oldData.stackType, stacks: oldData.stackType.stacks.filter(s => s.id !== deleteStack.id)}}
            console.log(newData);

            cache.writeQuery({query: QUERY_STACK_TYPE, data: newData})

        }
    })

    function onSubmit(values, { setSubmitting }) {
        deleteStack({variables: {data: { stackId: stack.id }}}).then(
            () => setSubmitting(false)
        ).then(
            () => closeModal()
        )
    }

    return (
        <StackFormik initialValues={{}} onSubmit={onSubmit} >
            {({handleSubmit, isSubmitting}) => (
                <Form onSubmit={handleSubmit}>
                    <p>This will delete stack {stack.name}. Confirm?</p>
                    <ButtonGroup>
                        <Button type='submit'>
                            Confirm {(loading || isSubmitting) ? <Spinner animation='border' size='sm'/> : null}
                        </Button>
                        <Button type='button' onClick={() => closeModal()}>Cancel</Button>
                    </ButtonGroup>

                </Form>
            )}
        </StackFormik>
    )
}