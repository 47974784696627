import React, {useContext} from "react";
import {Form} from "react-bootstrap";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {ChartContext} from "../charts/Chart";

export function onEntityHover(eId) {
    const entityCheckboxLabel = document.getElementById("check"+eId)
    entityCheckboxLabel.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
    })
    entityCheckboxLabel.style.backgroundColor = "#ffff00"
    setTimeout(() => {entityCheckboxLabel.style.backgroundColor = "#ffffff"}, 1000)
}

export const EntitiesCheckboxes = ({}) => {
    const { allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const { chart, setURL, settings, entitiesGrouped, missingDataEntities, entitiesFiltered } = useContext(ChartContext)

    function handleEnabledEntitiesChange(event) {
        if (settings.disabledEntities.find(eId => eId === event.target.value)) {
            settings.disabledEntities = settings.disabledEntities.filter(eId => eId !== event.target.value)
        } else {
            settings.disabledEntities.push(event.target.value)
        }
        setURL(chart, settings)
        setAllSettings({...allSettings})
    }

    return (
        <Form.Group style={{overflowY: "auto", maxHeight: "20em"}} className={'mt-2 mb-3'}>
            {entitiesGrouped.map(e => {
                return <Form.Check>
                    <Form.Check.Input
                        type={'checkbox'}
                        value={e.id}
                        onChange={handleEnabledEntitiesChange}
                        checked={
                            !settings.disabledEntities.includes(e.id)
                            // !(disableMissingDataEntities && missingDataEntities.find(mdE => mdE.id === e.id)!==undefined || settings.disabledEntities.includes(e.id))
                        }
                        disabled={
                            entitiesFiltered!==undefined && entitiesFiltered.find(ibE => ibE.id === e.id)===undefined ||
                            chart.type === "bubble" && missingDataEntities.find(mdE => mdE.id === e.id)!==undefined ||
                            (settings.base !== undefined && e.id === settings.base)
                        }
                    />
                    <Form.Check.Label id={"check"+e.id}>
                        {e.name}
                        {missingDataEntities.find(mdE => mdE.id === e.id)!==undefined ?
                            <span style={{fontSize: "12px"}}> [missing data: {missingDataEntities.find(mdE => mdE.id === e.id).missingFeatures.join(", ")}]</span> :
                            (entitiesFiltered!==undefined && entitiesFiltered.find(ibE => ibE.id === e.id)===undefined ? <span style={{fontSize: "12px"}}> [out of bounds]</span> : "")}
                    </Form.Check.Label>
                </Form.Check>
            })}
        </Form.Group>
    )
}