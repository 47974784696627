import React, { useContext } from 'react';
import {ListGroup, Row, Col} from "react-bootstrap";
import { InsertDataButton } from "./InsertDataModal";
import {LoginContext} from "../../providers/LoginContextProvider";


export const TableHeader = (props) => {
    const isLoggedIn = useContext(LoginContext)

    return (
        <ListGroup className='mb-3'>
            <ListGroup.Item>
                <Row>
                    <Col sm={2}>
                        System type
                    </Col>
                    <Col sm={2}>
                        System name
                    </Col>
                    <Col sm={2}>
                        Feature
                    </Col>
                    <Col sm={1}>
                        Certainty
                    </Col>
                    <Col sm={2}>
                        <Row>
                            <Col sm={8} className='text-right'>
                                Value
                            </Col>
                            <Col>
                                Unit
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={1}>
                        Timescale
                    </Col>
                    <Col sm={1}>
                        Changed
                    </Col>
                    <Col sm={1}>
                        { (isLoggedIn) ? <InsertDataButton className='w-100'>Insert</InsertDataButton> : null }
                    </Col>
                </Row>
            </ListGroup.Item>
        </ListGroup>
    )
}