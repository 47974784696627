import gql from 'graphql-tag';


export const QUERY_SYSTEM_TYPES = gql`
    query getSystemTypes {
        systemTypes {
            id
            name
            metaFields {
                id
                name
                description
                index
            }
            systemPropertyGroups {
                id
                systemTypeId
                name
                unit
                index
            }
        }
    }
`
