import React, {useState} from "react";
import {Button} from "react-bootstrap";
import {ModalForm} from "./add-modal";
import {SystemAddForm} from "./add-form";
import {QUERY_SYSTEM_TYPE} from "../../../../graphql/systemType";
import {useParams} from "react-router";
import {useQuery} from "@apollo/react-hooks";


export const SystemAddButton = ({...props}) => {
    const { systemTypeId } = useParams();
    const [ show, setShow ] = useState(false);
    const { loading, data } = useQuery(QUERY_SYSTEM_TYPE, {variables: {systemTypeId: systemTypeId}})

    if (loading) return 'Loading....'

    return (
        <React.Fragment>
            <Button onClick={() => setShow(true)} {...props} />
            <ModalForm show={show} setShow={setShow} title={`Add a system to ${data.systemType.name}`}>
                <SystemAddForm />
            </ModalForm>
        </React.Fragment>
    )
}