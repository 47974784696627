import { useState } from 'react';
import {useQuery} from "@apollo/react-hooks";


export function filterData(data = [], filter = {}, functions = {}) {
    return data.filter(e => {
        return Object.keys(filter).reduce((shouldInclude, key) => {
            return shouldInclude && ((!filter[key]) || functions[key](e, filter[key]))
        }, true)
    })
}


export const useFilter = (data = [{}], functions = {}, initialFilter = {}) => {
    const [ filter, setFilter ] = useState(initialFilter)
    const filteredData = filterData(data, filter, functions)

    return [ filteredData, filter, setFilter ]
}


export const useDatabaseFilter = (data, { query, variables }) => {
    const { data: filterData, loading } = useQuery(query, {
        variables: variables,
    })

    let filteredData = [];
    if (!loading) {
        const spf = filterData.systemPropertyFilter
        filteredData = data.filter(entry => {
            return (
                ((spf.systemIds.length === 0) || (spf.systemIds.findIndex(sId => sId === entry.systemId) > -1))
                &&
                ((spf.systemTypeIds.length === 0) || (spf.systemTypeIds.findIndex(stId => stId === entry.systemTypeId) > -1))
                &&
                ((spf.systemPropertyGroupIds.length === 0) || (spf.systemPropertyGroupIds.findIndex(spgId => spgId === entry.systemPropertyGroupId) > -1))
            )
        })
    }

    return { filteredData, loading }
}