import React, {Fragment, useContext} from "react";
import {ErrorBoundary} from 'react-error-boundary';
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {Button, Col, Row} from "react-bootstrap";
import {isEqual} from "lodash";
import {sortAlphabetical} from "../functions/SortAlphabetical";
import {isObject} from "../functions/isObject";
import {entityInSelectedCategories} from "../functions/entityInSelectedCategories";
import {CategoriesSelector} from "../fields/CategoriesSelector";
import {ColorBySelector} from "../fields/ColorBySelector";
import {CategoryForm} from "../fields/CategoryForm";
import {EntitiesCheckboxes} from "../fields/EntitiesCheckboxes";
import {ColorLegend} from "../fields/ColorLegend";
import {ResetAllButton} from "../fields/ResetAllButton";
import {RangeField} from "../fields/RangeField";
import {ResetRangesButton} from "../fields/ResetRangesButton";
import {RangeForm} from "../fields/RangeForm";
import {SPGSelector} from "../fields/SPGSelector";
import {FormulaOrSPGSelector} from "../fields/FormulaOrSPGSelector";
import {BaseSelector} from "../fields/BaseSelector";
import {LabelsCheckboxes} from "../fields/LabelsCheckboxes";
import {Toggle} from "../fields/Toggle";
import {FeaturesEnablerCheckboxes} from "../fields/FeaturesEnablerCheckboxes";
import {FeaturesSortByRadios} from "../fields/FeaturesSortByRadios";
import {FeaturesSelector} from "../fields/FeaturesSelector";
import {SelectedFeaturesForm} from "../fields/SelectedFeaturesForm";
import {GWPSelector} from "../fields/GWPSelector";
import {ColumnForm} from "../fields/ColumnForm";
import {Bubble} from "./Bubble";
import {Bar} from "./Bar";
import {BoxBar} from "./BoxBar";
import {ColumnRangeAndBar} from "./ColumnRangeAndBar";
import {Flow} from "./Flow";

function ErrorFallback({error, resetErrorBoundary}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    )
}

const Field = ({field, chartFields}) => {
    const components = {
        "Row": Row,
        "Col": Col,
        "CategoriesSelector": CategoriesSelector,
        "ColorBySelector": ColorBySelector,
        "CategoryForm": CategoryForm,
        "EntitiesCheckboxes": EntitiesCheckboxes,
        "ColorLegend": ColorLegend,
        "ResetAllButton": ResetAllButton,
        "RangeField": RangeField,
        "ResetRangesButton": ResetRangesButton,
        "RangeForm": RangeForm,
        "SPGSelector": SPGSelector,
        "FormulaOrSPGSelector": FormulaOrSPGSelector,
        "BaseSelector": BaseSelector,
        "LabelsCheckboxes": LabelsCheckboxes,
        "Toggle": Toggle,
        "FeaturesEnablerCheckboxes": FeaturesEnablerCheckboxes,
        "FeaturesSortByRadios": FeaturesSortByRadios,
        "FeaturesSelector": FeaturesSelector,
        "SelectedFeaturesForm": SelectedFeaturesForm,
        "GWPSelector": GWPSelector,
        "ColumnForm": ColumnForm,
        "Bubble": Bubble,
        "Bar": Bar,
        "BoxBar": BoxBar,
        "ColumnRangeAndBar": ColumnRangeAndBar,
        "Flow": Flow,
    }

    if (!(field.type in components)) {
        return (
            <Fragment>
                <span style={{backgroundColor: "red"}}>{field.type} is undefined</span><br/>
            </Fragment>
        )
    }

    const SpecificField = components[field.type]

    if (chartFields.find(chF => chF.parentFieldId + "" === field.id) === undefined) {
        return (
            <Fragment>
                {field.label && <b>{field.label}:</b>}
                <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                >
                    <SpecificField
                        label={field.label}
                        {...JSON.parse(field.props)}
                    />
                </ErrorBoundary>
            </Fragment>
        )
    }
    return (
        <Fragment>
            {field.label && <b>{field.label}:</b>}
            <SpecificField
                {...JSON.parse(field.props)}
            >
                {chartFields.filter(chF => chF.parentFieldId + "" === field.id).sort((a, b) => a.index - b.index).map(field2 =>
                    <Field
                        field={field2}
                        chartFields={chartFields}
                    />
                )}
            </SpecificField>
        </Fragment>
    )
}

function setURL (chart, settings) {
    const init = []

    chart.chartSettings.filter(chS => !chS.hidden).forEach(chS => {
        if (chS.type === "id" ||
            chS.type === "nullOrId" && settings[chS.name] !== null ||
            chS.type === "string" ||
            chS.type === "idOrString" ||
            chS.type === "number" && settings[chS.name] !== null
        ) {
            init.push([[chS.shortName], settings[chS.name]])
        }
        if (chS.type === "listOfIds" && settings[chS.name].length > 0) {
            init.push([[chS.shortName], settings[chS.name].join(".")])
        }
        if (chS.type === "listOfStrings" && settings[chS.name].length > 0) {
            settings[chS.name].forEach(str => {
                init.push([[chS.shortName], str])
            })
        }
        if (chS.type === "object") {
            init.push([[chS.shortName], settings[chS.name].groupId + '-' + settings[chS.name].id])
        }
        if (chS.type === "objectOrString") {
            init.push([[chS.shortName], typeof settings[chS.name] === "object" ? settings[chS.name].groupId + '-' + settings[chS.name].id : settings[chS.name]])
        }
        if (chS.type === "listOfObjects") {
            settings[chS.name].forEach(obj => {
                init.push([[chS.shortName], obj.groupId + '-' + obj.id])
            })
        }
        if (chS.type === "boolean" && settings[chS.name]) {
            init.push([[chS.shortName], 1])
        }
    })

    const newQueryParams = new URLSearchParams(init)
    window.history.replaceState({}, '', `${window.location.pathname}?${newQueryParams.toString()}`)
}

export const ChartContext = React.createContext([])

export const Chart = ({page, chart, isPreview=false}) => {
    const {
        allStacks, allSystems,
        allCategoryGroups,
        allFormulas, allSystemPropertyGroups,
        allEntityTypes, allSystemTypes,
        allSettings, setAllSettings
    } = useContext(SustainablePowerDataContext2)

    const entityType = allEntityTypes.find(eT => eT.id === chart.entityTypeId)
    const forStacks = entityType.discriminator === "stack_type"

    const categoryGroups = allCategoryGroups
        .filter(cg => !cg.hide)
        .filter(cg => entityType.categoryGroups.find(cg2 => cg2.id === cg.id)!==undefined)
        .sort((a, b) => a.index-b.index)

    const categories = categoryGroups.reduce((categories, cg) => [...categories, ...cg.categories], [])

    const systemPropertyGroups = allSystemPropertyGroups
        .filter(spg => forStacks || spg.systemType.id === chart.entityTypeId)
        .sort((a, b) => sortAlphabetical(a.name, b.name))
        .sort((a, b) => b.index - a.index)
        .sort((a, b) => a.index === null ? 1 : -1)
        .map(spg => {
            return {groupId: spg.systemType.id, ...spg}
        })

    const formulas = allFormulas.filter(f => !f.hide).map(f => {
        return {groupId: "6", ...f}
    })

    const formulasOrSPGs = formulas.concat(systemPropertyGroups)

    const hasValueForFormulaOrSPG = (stk, f) => {
        if (f.groupId === "6") {
            return f.name in stk.values
        }
        return f.name in allSystems.find(sys =>
                sys.id === stk.systems.find(stkSys =>
                        stkSys.system.systemTypeId === f.systemType.id
                    ).systemId
            ).values
    }

    const valueForFormulaOrSPG = (stk, f) => {
        if (f.groupId === "6") {
            return stk.values[f.name]
        }
        return allSystems.find(sys =>
                sys.id === stk.systems.find(stkSys =>
                        stkSys.system.systemTypeId === f.systemType.id
                    ).systemId
            ).values[f.name]["average"]
    }

    const columns = [
        {id: "0", name: 'Resource'},
        {id: "1", name: 'Energy Carrier'},
        {id: "2", name: 'Energy Conversion'},
        {id: "3", name: 'Distribution & Drive'},
        {id: "4", name: 'Emission Level'}
    ]

    const groups = {
        "systems": allSystems,
        "stacks": allStacks,
        "categoryGroups": categoryGroups,
        "categories": categories,
        "systemPropertyGroups": systemPropertyGroups,
        "formulas": formulas,
        "formulasOrSPGs": formulasOrSPGs,
        "columns": columns,
    }

    let defaultSettings = {}
    chart.chartSettings.sort((a, b) => a.id - b.id).forEach(chS => {
        defaultSettings[chS.name] = JSON.parse(chS.default)
    })

    let settings
    if (isPreview) {
        settings = defaultSettings
    }
    else {
        if (!(page.name in allSettings) || !isObject(allSettings[page.name]) || !(chart.name in allSettings[page.name])) {
            // return illegalSettings("emptyLocalStorage")
            doReset()
        }
        settings = allSettings[page.name][chart.name]
        const settingsCopy = {...settings}

        function doReset() {
            if (!(page.name in allSettings) || !isObject(allSettings[page.name])) {
                allSettings[page.name] = {}
            }
            allSettings[page.name][chart.name] = defaultSettings
            setURL(chart, allSettings[page.name][chart.name])
            setAllSettings({...allSettings})
        }

        function illegalSettings(errorMessage) {
            return (
                <Fragment>
                    <b>Chart cannot be displayed because of illegal chart settings. <i>[ERROR: {errorMessage}]</i></b>
                    <br/>
                    <Button className={'mt-2'} onClick={doReset}>Reset settings</Button>
                </Fragment>
            )
        }

        function isIdIllegal (id, group) {
            return !Number.isInteger(Number(id)) || group.find(item => item.id === id) === undefined
        }
        function isObjectIllegal (obj, group) {
            return !isObject(obj) || !("groupId" in obj) || !("id" in obj) ||
                group.find(item => item.groupId === obj.groupId && item.id === obj.id) === undefined
        }
        function isListIllegal (list, group, isIllegalFunc) {
            return !Array.isArray(list) || list.some(item => isIllegalFunc(item, group))
        }

        function areSettingsIllegal() {
            if (settings === undefined) return true

            return chart.chartSettings.some(chS => {
                if (!(chS.name in settings)) return true

                const setting = settings[chS.name]

                const legalValues = groups[chS.legalValues]
                return (
                    chS.type === "id" && isIdIllegal(setting, legalValues) ||
                    chS.type === "nullOrId" && setting !== null && isIdIllegal(setting, legalValues) ||
                    chS.type === "idOrString" && !["abc","sum"].includes(setting) && isIdIllegal(setting, legalValues) ||
                    chS.type === "listOfIds" && isListIllegal(setting, legalValues, isIdIllegal) ||
                    chS.type === "object" && isObjectIllegal(setting, legalValues) ||
                    chS.type === "objectOrString" && !["abc","sum"].includes(setting) && isObjectIllegal(setting, legalValues) ||
                    chS.type === "listOfObjects" && isListIllegal(setting, legalValues, isObjectIllegal) ||
                    chS.type === "boolean" && typeof setting !== "boolean" ||
                    chS.type === "number" && isNaN(Number(setting))
                )
            })
        }

        const paramString = window.location.search
        const queryParams = new URLSearchParams(paramString)
        if (paramString !== '') {
            chart.chartSettings.forEach(chS => {
                if (chS.hidden) {
                    if (!(chS.name in settings)) {
                        settings[chS.name] = JSON.parse(chS.default)
                    }
                } else {
                    if (chS.type === "id" || chS.type === "string" || chS.type === "idOrString") {
                        settings[chS.name] = queryParams.has(chS.shortName) ? queryParams.get(chS.shortName) : JSON.parse(chS.default)
                    }
                    if (chS.type === "nullOrId" || chS.type === "number") {
                        settings[chS.name] = queryParams.get(chS.shortName)
                    }
                    if (chS.type === "listOfIds") {
                        settings[chS.name] = queryParams.get(chS.shortName) ? queryParams.get(chS.shortName).split(".") : []
                    }
                    if (chS.type === "listOfStrings") {
                        settings[chS.name] = queryParams.getAll(chS.shortName)
                    }
                    if (chS.type === "object") {
                        settings[chS.name] = queryParams.has(chS.shortName) ?
                            { groupId: queryParams.get(chS.shortName).split('-')[0], id: queryParams.get(chS.shortName).split('-')[1] } :
                            JSON.parse(chS.default)
                    }
                    if (chS.type === "objectOrString") {
                        settings[chS.name] = queryParams.has(chS.shortName) ? (
                            queryParams.get(chS.shortName).includes('-') ?
                            { groupId: queryParams.get(chS.shortName).split('-')[0], id: queryParams.get(chS.shortName).split('-')[1] } :
                            queryParams.get(chS.shortName)
                        ) : JSON.parse(chS.default)
                    }
                    if (chS.type === "listOfObjects") {
                        settings[chS.name] = queryParams.getAll(chS.shortName).map(obj => {
                            return {groupId: obj.split('-')[0], id: obj.split('-')[1]}
                        })
                    }
                    if (chS.type === "boolean") {
                        settings[chS.name] = queryParams.has(chS.shortName)
                    }
                }
            })

            if (!isEqual(settings, settingsCopy)) {
                setAllSettings({...allSettings})
            }
            if (areSettingsIllegal()) {
                return illegalSettings("illegalURLParameters")
            }
        } else {
            if (areSettingsIllegal()) {
                return illegalSettings("illegalLocalStorage")
            }
            setURL(chart, settings)
        }
    }

    let allEntities
    if (forStacks) {
        allEntities = allStacks.filter(stk => stk.systems.length > 0).sort((a, b) => sortAlphabetical(a.name, b.name))
    } else {
        allEntities = allSystems.filter(sys => sys.systemTypeId === chart.entityTypeId).sort((a, b) => sortAlphabetical(a.name, b.name))
    }

    let features
    let missingDataEntities = []
    let entitiesFiltered

    if ("selectedFeatures" in settings) {
        if (forStacks) {
            features = settings.selectedFeatures
                .map(sF => formulasOrSPGs.find(f => f.groupId === sF.groupId && f.id === sF.id))
                .map(f => {
                    return {
                        ...f,
                        displayName: (f.groupId === "6" ? "Stack" : allSystemTypes.find(sysT => sysT.id === f.groupId).name) + " / " + f.name
                    }
                })

            missingDataEntities = allEntities.reduce((acc1, e) => {
                const missingFeatures = features.reduce((acc2, f) => {
                    if (e.values === null || !hasValueForFormulaOrSPG(e, f)) {
                        return [...acc2, (f.groupId === "6" ? "Stack" : allSystemTypes.find(sysT => sysT.id === f.groupId).name) + " / " + f.name]
                    }
                    return acc2
                }, [])

                if (missingFeatures.length > 0) return [...acc1, {...e, missingFeatures: missingFeatures}]
                return acc1
            }, [])
        } else {
            features = settings.selectedFeatures
                .map(sF => systemPropertyGroups.find(spg => spg.id === sF))
                .map(f => {
                    return {...f, displayName: f.name}
                })

            missingDataEntities = allEntities.reduce((acc1, e) => {
                const missingFeatures = features.reduce((acc2, f) => {
                    if (e.values === null || !(f.name in e.values)) {
                        return [...acc2, f.name]
                    }
                    return acc2
                }, [])

                if (missingFeatures.length > 0) return [...acc1, {...e, missingFeatures: missingFeatures}]
                return acc1
            }, [])
        }
    }
    if ("enabledFeatures" in settings) {
        if (forStacks) {
            features = settings.enabledFeatures.map(eF => formulas.find(f => f.id === eF))
        }
        else {
            features = settings.enabledFeatures.map(eF => systemPropertyGroups.find(spg => spg.id === eF))
        }
    }

    if (chart.type === "bubble") {
        if (forStacks) {
            entitiesFiltered = allEntities
                .filter(e => missingDataEntities.find(mdE => mdE.id === e.id) === undefined)
                .filter(e =>
                    (settings.rangeXMin === null || valueForFormulaOrSPG(e, features[0]) > Number(settings.rangeXMin)) &&
                    (settings.rangeXMax === null || valueForFormulaOrSPG(e, features[0]) < Number(settings.rangeXMax)) &&
                    (settings.rangeYMin === null || valueForFormulaOrSPG(e, features[1]) > Number(settings.rangeYMin)) &&
                    (settings.rangeYMax === null || valueForFormulaOrSPG(e, features[1]) < Number(settings.rangeYMax))
                )
        } else {
            entitiesFiltered = allEntities
                .filter(e => missingDataEntities.find(mdE => mdE.id === e.id) === undefined)
                .filter(e =>
                    (settings.rangeXMin === null || e.values[features[0].name]["average"] > Number(settings.rangeXMin)) &&
                    (settings.rangeXMax === null || e.values[features[0].name]["average"] < Number(settings.rangeXMax)) &&
                    (settings.rangeYMin === null || e.values[features[1].name]["average"] > Number(settings.rangeYMin)) &&
                    (settings.rangeYMax === null || e.values[features[1].name]["average"] < Number(settings.rangeYMax))
                )
        }
    } else if (chart.type === "bar" && "selectedFeatures" in settings) {
        entitiesFiltered = allEntities.filter(e =>
            missingDataEntities.find(mdE => mdE.id === e.id) === undefined ||
            missingDataEntities.find(mdE => mdE.id === e.id).missingFeatures.length < features.length
        )
    } else if (chart.type === "boxBar") {
        entitiesFiltered = allEntities
            .filter(e => e.values !== null)
            .filter(e => features.some(f => f.name in e.values))
    } else {
        entitiesFiltered = allEntities
    }

    let entitiesSorted
    if (!("sortBy" in settings)) {
        entitiesSorted = entitiesFiltered
    } else {
        if (settings.sortBy === "abc") {
            entitiesSorted = entitiesFiltered.sort((a, b) => sortAlphabetical(a.name, b.name))
        } else if (settings.sortBy === "sum") {
            entitiesSorted = entitiesFiltered.sort((a, b) => {
                if (forStacks) {
                    return features.reduce((acc, f) => {
                        if (hasValueForFormulaOrSPG(b, f)) {
                            return acc + valueForFormulaOrSPG(b, f)
                        }
                        return acc
                    }, 0) - features.reduce((acc, f) => {
                        if (hasValueForFormulaOrSPG(a, f)) {
                            return acc + valueForFormulaOrSPG(a, f)
                        }
                        return acc
                    }, 0)
                }

                return features.reduce((acc, f) => {
                    if (f.name in b.values) {
                        return acc + b.values[f.name]["average"]
                    }
                    return acc
                }, 0) - features.reduce((acc, f) => {
                    if (f.name in a.values) {
                        return acc + a.values[f.name]["average"]
                    }
                    return acc
                }, 0)
            })
        } else {
            let sortByFeature
            if (forStacks) {
                if (isObject(settings.sortBy)) {
                    sortByFeature = formulasOrSPGs.find(f => f.groupId === settings.sortBy.groupId && f.id === settings.sortBy.id)
                    entitiesSorted = entitiesFiltered.filter(e => hasValueForFormulaOrSPG(e, sortByFeature)).sort((a, b) => valueForFormulaOrSPG(b, sortByFeature) - valueForFormulaOrSPG(a, sortByFeature))
                        .concat(entitiesFiltered.filter(e => !hasValueForFormulaOrSPG(e, sortByFeature)))
                } else {
                    sortByFeature = formulas.find(f => f.id === settings.sortBy)
                    entitiesSorted = entitiesFiltered.filter(e => sortByFeature.name in e.values).sort((a, b) => b.values[sortByFeature.name] - a.values[sortByFeature.name])
                        .concat(entitiesFiltered.filter(e => !(sortByFeature.name in e.values)))
                }
            } else {
                sortByFeature = systemPropertyGroups.find(spg => spg.id === settings.sortBy)
                entitiesSorted = entitiesFiltered.filter(e => sortByFeature.name in e.values).sort((a, b) => b.values[sortByFeature.name]["average"] - a.values[sortByFeature.name]["average"])
                    .concat(entitiesFiltered.filter(e => !(sortByFeature.name in e.values)))
            }
        }

        if ("sortOrder" in settings && settings.sortOrder) {
            entitiesSorted.reverse()
        }
    }

    let entitiesGrouped = chart.type === "bubble" ?
        allEntities :
        entitiesSorted.concat(allEntities.filter(e => entitiesSorted.find(e2 => e2.id===e.id)===undefined))
    entitiesGrouped = entitiesGrouped.filter(e => entityInSelectedCategories(e, settings.selectedCategories, categories))
        .concat(entitiesGrouped.filter(e => !entityInSelectedCategories(e, settings.selectedCategories, categories)))
    if ("base" in settings) {
        entitiesGrouped = entitiesGrouped.filter(e => e.id === settings.base).concat(entitiesGrouped.filter(e => e.id !== settings.base))
    }

    const entities = entitiesSorted.filter(e => "disabledEntities" in settings && !settings.disabledEntities.includes(e.id) || "base" in settings && e.id === settings.base)

    const charts = {
        "bar": Bar,
        "bubble": Bubble,
        "boxBar": BoxBar,
        "columnRangeAndBar": ColumnRangeAndBar,
        "flow": Flow,
    }

    const SpecificChart = charts[chart.type]

    return (
        <ChartContext.Provider value={{
            chart: chart,
            forStacks: forStacks,
            setURL: setURL,
            settings: settings,
            defaultSettings: defaultSettings,
            features: features,
            categoryGroups: categoryGroups,
            categories: categories,
            systemPropertyGroups: systemPropertyGroups,
            formulas: formulas,
            formulasOrSPGs: formulasOrSPGs,
            allColumns: columns,
            hasValueForFormulaOrSPG: hasValueForFormulaOrSPG,
            valueForFormulaOrSPG: valueForFormulaOrSPG,
            allEntities: allEntities,
            missingDataEntities: missingDataEntities,
            entitiesFiltered: entitiesFiltered,
            entitiesGrouped: entitiesGrouped,
            disabledEntities: chart.chartSettings.find(chS => chS.name === "disabledEntities") !== undefined ?
                chart.chartSettings.find(chS => chS.name === "disabledEntities").default :
                [],
            entities: !isPreview ? entities : entities.sort(() => 0.5 - Math.random()).slice(0, 4),
            isPreview: isPreview
        }}>
            {isPreview && chart.type in charts && <SpecificChart/>}
            {isPreview && !(chart.type in charts) &&
                <span style={{backgroundColor: "red"}}>{chart.type} is undefined</span>
            }
            {!isPreview && chart.chartFields.filter(chF => chF.parentFieldId === null).sort((a, b) => a.index-b.index).map(field =>
                <Field
                    field={field}
                    chartFields={chart.chartFields}
                />
            )}
        </ChartContext.Provider>
    )
}