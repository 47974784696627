import gql from 'graphql-tag'


export const FRAGMENT_STACK_TYPE_FORMULA_FIELD_CONTENT = gql`
    fragment stackTypeFormulaFieldContent on StackTypeFormulaFieldObject {
        name
        systemPropertyGroupId
        stackTypeSystemFieldId
        stackTypeFormulaId
        systemPropertyGroup {
            id
            name
        }
        stackTypeSystemField {
            id
            name
        }
    }   
`


export const MUTATION_CREATE_STACK_TYPE_FORMULA_FIELD = gql`
    mutation createStackTypeFormulaField($stackTypeFormulaId: ID!, $name: String!, $stackTypeSystemFieldId: ID!, $systemPropertyGroupId: ID!) {
        createStackTypeFormulaField(
            stackTypeFormulaId: $stackTypeFormulaId,
            name: $name, 
            stackTypeSystemFieldId: $stackTypeSystemFieldId,
            systemPropertyGroupId: $systemPropertyGroupId,
            
        ) {
            ok
            stackTypeFormulaField {
                id
                ...stackTypeFormulaFieldContent
            }
        }
    }
    ${FRAGMENT_STACK_TYPE_FORMULA_FIELD_CONTENT}
`


export const MUTATION_UDPATE_STACK_TYPE_FORMULA_FIELD = gql`
    mutation updateStackTypeFormulaField($stackTypeFormulaFieldId: ID!, $name: String, $stackTypeSystemFieldId: ID, $systemPropertyGroupId: ID) {
        updateStackTypeFormulaField(
            stackTypeFormulaFieldId: $stackTypeFormulaFieldId,
            name: $name, 
            stackTypeSystemFieldId: $stackTypeSystemFieldId,
            systemPropertyGroupId: $systemPropertyGroupId,
        ) {
            ok
            stackTypeFormulaField {
                id
                ...stackTypeFormulaFieldContent
            }
        }
    }
    ${FRAGMENT_STACK_TYPE_FORMULA_FIELD_CONTENT}
`


export const MUTATION_DELETE_STACK_TYPE_FORMULA_FIELD = gql`
    mutation deleteStackTypeFormulaField($stackTypeFormulaFieldId: ID!) {
        deleteStackTypeFormulaField(
            stackTypeFormulaFieldId: $stackTypeFormulaFieldId,
        ) {
            ok
            stackTypeFormulaField {
                id
                ...stackTypeFormulaFieldContent
            }
        }
    }
    ${FRAGMENT_STACK_TYPE_FORMULA_FIELD_CONTENT}
`