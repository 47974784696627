import React from "react";
import {Modal} from "react-bootstrap";


export const ModalFormContext = React.createContext(null)


export const ModalForm = ({title, show, setShow, ...props}) => {
    const closeModal = () => setShow(false);

    return (
        <React.Fragment>
            <ModalFormContext.Provider value={{closeModal: closeModal}}>
                <Modal show={show} onHide={() => setShow(false)}>
                    <Modal.Header>
                        {title}
                    </Modal.Header>
                    <Modal.Body>
                        {props.children}
                    </Modal.Body>
                </Modal>
            </ModalFormContext.Provider>
        </React.Fragment>
    )
}