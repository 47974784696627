import React, {useContext, useMemo} from 'react';
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {TableDisplay, sortNumbers, sortStrings} from "./table";
import {isObject} from "../functions/isObject";

const page = "stacks"
const chart = "table"

export const WellToWakeTable = () => {
    const { allStacks, allSystems, allCategoryGroups, allStackTypeSystemFields, allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    if (!(page in allSettings) || !isObject(allSettings[page]) || !(chart in allSettings[page])) {
        // return illegalParameters("emptyLocalStorage")
        if (!(page in allSettings) || !isObject(allSettings[page])) {
            allSettings[page] = {}
        }
        allSettings[page][chart] = {
            groupBy: '2'
        }
        setAllSettings({...allSettings})
    }
    const filter = allSettings[page][chart]

    const categoryGroups = allCategoryGroups
        .filter(cg => !cg.hide)
        .sort((a, b) => a.index - b.index)

    if ((!Number.isInteger(Number(filter.groupBy)) || categoryGroups.find(cg => cg.id === filter.groupBy) === undefined) && filter.groupBy !== "ungrouped") {
        filter.groupBy = '2'
        setAllSettings({...allSettings})
    }

    const columns = useMemo(() => [
        { accessor: 'name', Header: 'Solution name', sortType: (rowA, rowB, columnId, desc) => sortStrings(rowA, rowB, columnId, desc) },
        { accessor: 'energyCarrier', Header: 'Energy carrier', sortType: (rowA, rowB, columnId, desc) => sortStrings(rowA, rowB, columnId, desc, true), tooltip: allStackTypeSystemFields.find(sTsF => sTsF.name === 'Energy carrier').description },
        { accessor: 'preTreatment', Header: 'Pre-treatment', sortType: (rowA, rowB, columnId, desc) => sortStrings(rowA, rowB, columnId, desc, true), tooltip: allStackTypeSystemFields.find(sTsF => sTsF.name === 'Pre-treatment').description },
        { accessor: 'energyConversion', Header: 'Energy conversion', sortType: (rowA, rowB, columnId, desc) => sortStrings(rowA, rowB, columnId, desc, true), tooltip: allStackTypeSystemFields.find(sTsF => sTsF.name === 'Energy conversion').description },
        { accessor: 'afterTreatment', Header: 'After-treatment', sortType: (rowA, rowB, columnId, desc) => sortStrings(rowA, rowB, columnId, desc, true), tooltip: allStackTypeSystemFields.find(sTsF => sTsF.name === 'After-treatment').description },
        { accessor: 'powerDistribution', Header: 'Power distribution', sortType: (rowA, rowB, columnId, desc) => sortStrings(rowA, rowB, columnId, desc), tooltip: allStackTypeSystemFields.find(sTsF => sTsF.name === 'Power distribution').description },
        { accessor: 'efficiency', Header: 'Efficiency', disableSortBy: true, columns: [
            { accessor: 'chainEfficiency', Header: 'TTW Chain Efficiency', decimals: 2, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc)},
            { accessor: 'WTWEfficiency', Header: 'WTW Chain Efficiency', decimals: 2, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc)},
        ]},
        { accessor: 'emissions', Header: 'Global warming potential', disableSortBy: true, columns: [
            { accessor: 'GWP100', Header: 'GWP100', disableSortBy: true, columns: [
                { accessor: 'GWP100-WTT', Header: 'WTT', decimals: 1, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc) },
                { accessor: 'GWP100-TTW', Header: 'TTW', decimals: 1, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc) },
                { accessor: 'GWP100-WTW', Header: 'WTW', decimals: 1, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc) },
            ]},
            { accessor: 'GWP20', Header: 'GWP20', disableSortBy: true, columns: [
                { accessor: 'GWP20-WTT', Header: 'WTT', decimals: 1, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc) },
                { accessor: 'GWP20-TTW', Header: 'TTW', decimals: 1, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc) },
                { accessor: 'GWP20-WTW', Header: 'WTW', decimals: 1, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc) },
            ]},
        ]},
        { accessor: 'costsOpEx', Header: 'OpEx per unit energy', columns: [
            { accessor: 'OpEx', Header: '€/kWh', decimals: 3, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc)},
        ]},
    ], [])

    const data = useMemo(() => allStacks.filter(stk => stk.systems.length > 0).sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) { return -1 }
            if (a.name.toUpperCase() > b.name.toUpperCase()) { return 1 }
            return 0
        }).map(stk => {
            return {
                group: stk.categories.find(c => c.categoryGroupId === filter.groupBy)!==undefined ?
                    stk.categories.find(c => c.categoryGroupId === filter.groupBy).name :
                    undefined,
                name: stk.name,
                energyCarrier: {
                    value: stk.systems.find(sys => sys.system.systemTypeId === '2').system.name,
                    tooltip: allSystems.find(sys =>
                        sys.id === stk.systems.find(stkSys => stkSys.system.systemTypeId === '2').systemId
                    ).metaData.map(mD =>
                        <span><b>{mD.systemTypeMetaField.name}</b>: {mD.value}<br/></span>
                    )
                },
                preTreatment: {
                    value: stk.systems.find(sys => sys.system.systemTypeId === '1').system.name,
                    tooltip: allSystems.find(sys =>
                        sys.id === stk.systems.find(stkSys => stkSys.system.systemTypeId === '1').systemId
                    ).metaData.map(mD =>
                        <span><b>{mD.systemTypeMetaField.name}</b>: {mD.value}<br/></span>
                    )
                },
                energyConversion: {
                    value: stk.systems.find(sys => sys.system.systemTypeId === '3').system.name,
                    tooltip: allSystems.find(sys =>
                        sys.id === stk.systems.find(stkSys => stkSys.system.systemTypeId === '3').systemId
                    ).metaData.map(mD =>
                        <span><b>{mD.systemTypeMetaField.name}</b>: {mD.value}<br/></span>
                    )
                },
                afterTreatment: {
                    value: stk.systems.find(sys => sys.system.systemTypeId === '4').system.name,
                    tooltip: allSystems.find(sys =>
                        sys.id === stk.systems.find(stkSys => stkSys.system.systemTypeId === '4').systemId
                    ).metaData.map(mD =>
                        <span><b>{mD.systemTypeMetaField.name}</b>: {mD.value}<br/></span>
                    )
                },
                powerDistribution: stk.systems.find(sys => sys.system.systemTypeId === '5').system.name,
                chainEfficiency: stk.values['TTW Chain Efficiency'],
                WTWEfficiency: stk.values['WTW Chain Efficiency'],
                'GWP100-WTT': stk.values['GWP100-WTT'],
                'GWP100-TTW': stk.values['GWP100-TTW'],
                'GWP100-WTW': stk.values['GWP100-WTW'],
                'GWP20-WTT': stk.values['GWP20-WTT'],
                'GWP20-TTW': stk.values['GWP20-TTW'],
                'GWP20-WTW': stk.values['GWP20-WTW'],
                OpEx: stk.values['OpEx per unit energy']
            }
    }), [allStacks])

    return (
        <TableDisplay
            data={data}
            columns={columns}
            page={page}
            categoryGroups={categoryGroups}
        />
    )
}