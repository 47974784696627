import React, {useContext, useState} from "react";
import {Button, ButtonGroup, Form, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {Plus} from "react-bootstrap-icons";
import {useMutation} from "@apollo/react-hooks";
import {MUTATION_CREATE_PAGE, QUERY_PAGES} from "../../../../graphql/page";
import {Field, Formik} from "formik";
import {SustainablePowerDataContext2} from "../../../providers/SustainablePowerDataProvider2";

const AddPageModal = ({show, setShow, ...props}) => {
    const {allPages} = useContext(SustainablePowerDataContext2)

    const [ createPage, { loading }] = useMutation(MUTATION_CREATE_PAGE, {
        refetchQueries: [{query: QUERY_PAGES}],
    })

    const onSubmit = (values, {setSubmitting}) => {
        createPage({
            variables: {
                name: values.name,
                slug: values.slug,
                index: values.index
            }
        }).then(() => setSubmitting(false)).then(() => setShow(false))
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Add a new page
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        name: '',
                        slug: '',
                        index: allPages.reduce((acc, page) => page.index>acc ? page.index : acc, 0)+1,
                    }}
                    onSubmit={onSubmit}
                >
                    {({handleSubmit, handleReset, isSubmitting}) => (
                        <Form onSubmit={handleSubmit} onReset={handleReset}>
                            <Form.Group>
                                <Form.Label>Name:</Form.Label>
                                <Field as={Form.Control} name='name'/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Slug:</Form.Label>
                                <Field as={Form.Control} name='slug' />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Index:</Form.Label>
                                <Field as={Form.Control} name='index' />
                            </Form.Group>
                            <Form.Group>
                                <ButtonGroup className='w-100'>
                                    <Button disabled={loading || isSubmitting} type='submit'>Submit</Button>
                                    <Button variant='danger' onClick={() => setShow(false)}>Cancel</Button>
                                </ButtonGroup>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export const AddPageModalButton = ({props}) => {
    const [ show, setShow ] = useState(false);

    return (
        <React.Fragment>
            <OverlayTrigger overlay={<Tooltip id='add-page-tooltip-id'>Add a page</Tooltip>}>
                <Button onClick={() => setShow(true)} size='sm'>
                    <Plus size={20}/>
                </Button>
            </OverlayTrigger>
            <AddPageModal show={show} setShow={setShow} />
        </React.Fragment>
    )
}