import React, {useContext} from "react";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {Col, Form, Row} from "react-bootstrap";
import {ChartContext} from "../charts/Chart";

export const GWPSelector = ({}) => {
    const { allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const { chart, settings, setURL, features, formulas } = useContext(ChartContext)

    const allFormulas = JSON.parse(chart.features).map(fId => formulas.find(f => f.id === fId))

    let first
    if (settings.isColumnRange) {
        first = "WTT+TTW"
    } else {
        if (features[0].name.includes("WTW")) {
            first = "WTW"
        }
        else if (features[0].name.includes("WTT")) {
            first = "WTT"
        }
        else {
            first = "TTW"
        }
    }
    let second
    if (settings.isTwoSeries) {
        second = "100+20"
    } else {
        if (features[0].name.includes("100")) {
            second = "100"
        } else {
            second = "20"
        }
    }

    function onFirstChanged(e) {
        let enabledFormulas
        if (e.target.value === "WTT+TTW") {
            if (second === "100+20") {
                enabledFormulas = allFormulas
                settings.sortBy = enabledFormulas.find(f => f.name.includes("WTW") && f.name.includes("100")).id
            } else {
                enabledFormulas = allFormulas.filter(f => f.name.includes(second))
                settings.sortBy = enabledFormulas.find(f => f.name.includes("WTW")).id
            }
            settings.isColumnRange = true
        } else {
            if (second === "100+20") {
                enabledFormulas = allFormulas.filter(f => f.name.includes(e.target.value))
                settings.sortBy = enabledFormulas.find(f => f.name.includes("100")).id
            } else {
                enabledFormulas = allFormulas.filter(f => f.name.includes(e.target.value)).filter(f => f.name.includes(second))
                settings.sortBy = enabledFormulas[0].id
            }
            settings.isColumnRange = false
        }
        settings.enabledFeatures = enabledFormulas.map(f => f.id)
        setURL(chart, settings)
        setAllSettings({...allSettings})
    }

    function onSecondChanged(e) {
        let enabledFormulas
        if (e.target.value === "100+20") {
            if (first === "WTT+TTW") {
                enabledFormulas = allFormulas
                settings.sortBy = enabledFormulas.find(f => f.name.includes("WTW") && f.name.includes("100")).id
            } else {
                enabledFormulas = allFormulas.filter(f => f.name.includes(first))
                settings.sortBy = enabledFormulas.find(f => f.name.includes("100")).id
            }
            settings.isTwoSeries = true
        } else {
            if (first === "WTT+TTW") {
                enabledFormulas = allFormulas.filter(f => f.name.includes(e.target.value))
                settings.sortBy = enabledFormulas.find(f => f.name.includes("WTW")).id
            } else {
                enabledFormulas = allFormulas.filter(f => f.name.includes(e.target.value)).filter(f => f.name.includes(first))
                settings.sortBy = enabledFormulas[0].id
            }
            settings.isTwoSeries = false
        }
        settings.enabledFeatures = enabledFormulas.map(f => f.id)
        setURL(chart, settings)
        setAllSettings({...allSettings})
    }

    return (
        <Form.Group as={Row} className={"mt-2 mb-3"}>
            <Col>
                <Form.Control name={'second'} as='select' value={second} onChange={onSecondChanged}>
                    <option value={'100'}>GWP100</option>
                    <option value={'20'}>GWP20</option>
                    <option value={'100+20'}>GWP100 & 20</option>
                </Form.Control>
            </Col>
            <Col>
                <Form.Control name={'first'} as='select' value={first} onChange={onFirstChanged}>
                    <option value={'WTT'}>WTT</option>
                    <option value={'TTW'}>TTW</option>
                    <option value={'WTW'}>WTW</option>
                    <option value={'WTT+TTW'}>WTW (WTT+TTW)</option>
                </Form.Control>
            </Col>
        </Form.Group>
    )
}