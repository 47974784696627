import gql from 'graphql-tag';


export const FRAGMENT_STACK_CONTENT = gql`
    fragment stackContent on StackObject {
        name
        outcomes
        systems {
            id
            systemId
            stackTypeSystemFieldId
            stackTypeSystemFieldName
            system {
                name
                systemTypeId
                index
            }           
        }
        categories {
            id
            name
            color
            categoryGroupId
            categoryGroupName
            index
        }
    }
`


export const MUTATION_UPDATE_STACK = gql`
    mutation updateStack($data: UpdateStackInput!) {
        updateStack(data: $data) {
            id
            ...stackContent
        }
    }
    ${FRAGMENT_STACK_CONTENT}
`


export const MUTATION_CREATE_STACK = gql`
    mutation createStack($data: CreateStackInput!) {
        createStack(data: $data) {
            id
            ...stackContent
        }
    }  
    ${FRAGMENT_STACK_CONTENT}
`


export const MUTATION_DELETE_STACK = gql`
    mutation deleteStack($data: DeleteStackInput!) {
        deleteStack(data: $data) {
            id
        }
    } 
`