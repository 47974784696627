import {OverlayTrigger, Tooltip, Button, ButtonGroup, Col, Collapse, Row} from "react-bootstrap";
import {InfoCircle, Calculator, Pencil, Trash, BarChart, CheckCircle, X} from "react-bootstrap-icons";
import React, {useState} from "react";
import {useLogin} from "../../providers/LoginContextProvider";
import {DeleteDataButton} from "./DeleteDataModal";


function roundoff(value, decimals = 2) {
    const f = Math.pow(10.0, decimals)
    return '' + Math.round(value * f) / f;
}


export const TableRowDisplay = ({entry, setEditing, ...props}) => {
    const { isLoggedIn } = useLogin();
    const [ expand, setExpand ] = useState(false)

    return (
        <React.Fragment>
            <Row>
                <Col sm={2} className='text-truncate text-nowrap'>{entry.systemTypeName}</Col>
                <Col sm={2} className='text-truncate text-nowrap'>
                    <OverlayTrigger
                        placement='bottom-start'
                        overlay={
                            <Tooltip id={`tooltip-entry-${entry.id}-system-name`}>
                                {entry.systemDescription}
                            </Tooltip>
                        }
                    >
                        <div>
                            {entry.systemName}
                        </div>
                    </OverlayTrigger>
                </Col>
                <Col sm={2} className='text-truncate text-nowrap'>{entry.systemPropertyGroupName}</Col>
                <Col sm={1}>
                    <Row>
                        <OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip id={`tooltip-entry-${entry.id}-included`}>
                                    { entry.include ? 'Included in aggregation' : 'Excluded from aggregation' }
                                </Tooltip>
                            }
                        >
                            <Col xs={4} className='mx-0 pr-1 text-center'>
                                { (entry.include) ? <CheckCircle size={18} /> : <X size={18}/> }
                            </Col>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip id={`tooltip-entry-${entry.id}-certainty`}>
                                    Certainty of the value
                                </Tooltip>
                            }
                        >
                            <Col xs={8} className='mx-0 pl-1 text-center'>
                                <BarChart size={18}/> {entry.certainty | 0.00} %
                            </Col>
                        </OverlayTrigger>
                    </Row>
                </Col>
                <Col sm={2}>
                    <Row>
                        <Col xs={1}/>
                        <Col xs={7} className='text-right'>
                            {roundoff(entry.value)}
                        </Col>
                        <Col>
                            {entry.systemPropertyGroupUnit}
                        </Col>
                    </Row>
                </Col>
                <Col sm={1}>
                    { entry.timescale }
                </Col>
                <Col sm={1}>
                    { !!entry.changed ? entry.changed.toLocaleString('nl-NL', {day: 'numeric', month: 'numeric', year: 'numeric'}) : null }
                </Col>
                <Col sm={1}>
                    <ButtonGroup className='w-100'>
                        <Button size='sm' onClick={() => setExpand(!expand)} aria-controls={`entry-${entry.id}-info`} aria-expanded={expand}>
                            <InfoCircle color='white' size={14}/>
                        </Button>
                        { isLoggedIn ? (
                            <React.Fragment>
                                <Button size='sm' disabled={entry.calculated} onClick={() => setEditing(true)}>
                                    {entry.calculated ? <Calculator size={14} /> : <Pencil color='white' size={14}/> }
                                </Button>
                                <DeleteDataButton disabled={entry.calculated} size='sm' entry={entry}>
                                    <Trash color='white' size={14}/>
                                </DeleteDataButton>
                            </React.Fragment>
                        ) : null}
                    </ButtonGroup>
                </Col>
            </Row>
            <Collapse in={expand}>
                <div id={`entry-${entry.id}-info`}>
                    <Row>
                        <Col sm={2}>- Reference</Col><Col>{entry.reference}</Col>
                    </Row>
                    <Row>
                        <Col sm={2}>- Reference detail</Col><Col>{entry.referenceDetail}</Col>
                    </Row>
                    { isLoggedIn ? (
                        <Row>
                            <Col sm={2}>- Reference (internal)</Col><Col>{entry.referenceMarin}</Col>
                        </Row>
                    ) : null }
                    <Row>
                        <Col sm={2}>- Explanation</Col><Col>{entry.explanation}</Col>
                    </Row>
                    <Row>
                        <Col sm={2}>- Notes</Col><Col>{entry.note}</Col>
                    </Row>
                </div>
            </Collapse>
        </React.Fragment>
    )
}