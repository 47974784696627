import {Form} from "react-bootstrap";
import React, {useContext} from "react";
import {ChartContext} from "../charts/Chart";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";

export const FeaturesEnablerCheckboxes = ({}) => {
    const { allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const { chart, settings, setURL, forStacks, systemPropertyGroups, formulas } = useContext(ChartContext)

    const features = forStacks ? formulas : systemPropertyGroups

    function handleEnabledFeaturesChange(e) {
        if (settings.enabledFeatures.find(eF => eF === e.target.value) !== undefined) {
            settings.enabledFeatures = settings.enabledFeatures.filter(eF => eF !== e.target.value)
        } else {
            settings.enabledFeatures.push(e.target.value)
        }
        setURL(chart, settings)
        setAllSettings({...allSettings})
    }

    return (
        <div className={"mt-2 mb-3"}>
            {JSON.parse(chart.features).map(fId => {
                return (
                    <Form.Check>
                        <Form.Check.Input
                            type={"checkbox"}
                            value={fId}
                            onChange={handleEnabledFeaturesChange}
                            checked={settings.enabledFeatures.find(eF => eF === fId) !== undefined}
                        />
                        <Form.Check.Label>{features.find(f => f.id === fId).name}</Form.Check.Label>
                    </Form.Check>
                )
            })}
        </div>
    )
}