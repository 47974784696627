import React, {useContext, useState} from "react";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {sortAlphabetical} from "../../general/functions/SortAlphabetical";
import Select from "react-select";

export const FormulaOrSPGSelector = ({field, forSort=false, notSet=false, ...props}) => {
    const { allFormulas, allSystemTypes } = useContext(SustainablePowerDataContext2)

    let options = []
    if (notSet) {
        options.push({
            label: "Not set",
            options: [{
                label: "Not set",
                value: "notset"
            }]
        })
    }
    if (forSort) {
        options.push({
            label: "General",
            options: [{
                label: "Alphabetical",
                value: "abc"
            }, {
                label: "Sum of bars",
                value: "sum"
            }]
        })
    }
    options.push({
        label: "Stack formulas",
        options: allFormulas
            .filter(f => !f.hide)
            .sort((a, b) => sortAlphabetical(a.name, b.name))
            .sort((a, b) => a.index - b.index)
            .map(f => {
                return {
                    label: f.name,
                    group: "Stack",
                    value: {
                        groupId: "6",
                        id: f.id
                    },
                    // isDisabled:,
                    // isHidden:
                }
            })
    })
    allSystemTypes.sort((a, b) => ["1", "2", "3", "5", "4"].indexOf(a.id) - ["1", "2", "3", "5", "4"].indexOf(b.id)).forEach(sysT => {
        options.push({
            label: sysT.name,
            options: sysT.systemPropertyGroups
                .sort((a, b) => sortAlphabetical(a.name, b.name))
                .sort((a, b) => b.index - a.index)
                .sort((a, b) => a.index === null ? 1 : -1)
                .map(spg => {
                    return {
                        label: spg.name,
                        group: sysT.name,
                        value: {
                            groupId: sysT.id,
                            id: spg.id
                        }
                    }
                })
        })
    })

    const [value, setValue] = useState(field.value)

    const selectedFeatureOption = options
        .reduce((acc, optionGroup) => [...acc, ...optionGroup.options], [])
        .find(f =>
            typeof value === "string" && f.value === value ||
            typeof value !== "string" && f.value.groupId === value.groupId && f.value.id === value.id
        )
    if ("group" in selectedFeatureOption) {
        selectedFeatureOption.label = selectedFeatureOption.group + " / " + selectedFeatureOption.label
    }

    return (
        <Select
            className={'my-2'}
            onChange={(e) => setValue(e.value)}
            value={selectedFeatureOption}
            options={options}
        />
    )
}