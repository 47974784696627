import gql from 'graphql-tag';

export const FRAGMENT_CHART_FIELD_CONTENT = gql`
    fragment chartFieldContent on ChartFieldObject {
        parentFieldId
        chartId
        type
        index
        props
        label
    }
`


export const MUTATION_CREATE_CHART_FIELD = gql`
     mutation createChartField($chartId: ID!, $parentFieldId: ID, $index: Int!, $type: String!, $props: JSONString, $label: String) {
        createChartField(chartId: $chartId, parentFieldId: $parentFieldId, index: $index, type: $type, props: $props, label: $label) {
            ok
            chartField {
                id
                ...chartFieldContent
            }
        }
    }
    ${FRAGMENT_CHART_FIELD_CONTENT}
`


export const MUTATION_UPDATE_CHART_FIELD = gql`
     mutation updateChartField($chartFieldId: ID!, $chartId: ID, $parentFieldId: ID, $index: Int, $type: String, $props: JSONString, $label: String) {
        updateChartField(chartFieldId: $chartFieldId, chartId: $chartId, parentFieldId: $parentFieldId, index: $index, type: $type, props: $props, label: $label) {
            ok
            chartField {
                id
                ...chartFieldContent
            }
        }
    }
    ${FRAGMENT_CHART_FIELD_CONTENT}
`


export const MUTATION_DELETE_CHART_FIELD = gql`
    mutation deleteChartField($chartFieldId: ID!) {
        deleteChartField(chartFieldId: $chartFieldId) {
            ok
            chartField {
                id
                ...chartFieldContent
            }
        }
    }
    ${FRAGMENT_CHART_FIELD_CONTENT}
`

