import React, {useState} from "react";
import {Button} from "react-bootstrap";
import {ModalForm} from "./delete-modal";
import {StackDeleteForm} from "./delete-form";


export const StackDeleteButton = ({stack, ...props}) => {
    const [ show, setShow ] = useState(false);

    return (
        <React.Fragment>
            <Button onClick={() => setShow(true)} {...props} />
            <ModalForm show={show} setShow={setShow} title={`Delete stack ${stack}`}>
                <StackDeleteForm stack={stack} />
            </ModalForm>
        </React.Fragment>
    )
}