import {useHistory} from "react-router";
import {ButtonGroup, Col, Nav, Row} from "react-bootstrap";
import React from "react";
import {DeletePageModalButton} from "./delete-page";
import {EditPageModalButton} from "./edit-page";

export const PageMenuItem = ({page, ...props}) => {
    const history = useHistory();

    const onClickEditButton = (e) => {
        e.stopPropagation();
    }

    return (
        <Nav.Item key={page.id}>
            <Nav.Link
                eventKey={`/settings/pages/${page.id}`}
                onSelect={() => history.push(`/settings/pages/${page.id}`)}
            >
                <Row>
                    <Col>{page.name}</Col>
                    <Col className='text-right'>
                        <ButtonGroup onClick={onClickEditButton} onKeyDown={onClickEditButton}>
                            <DeletePageModalButton page={page} />
                            <EditPageModalButton page={page} />
                        </ButtonGroup>
                    </Col>
                </Row>
            </Nav.Link>
        </Nav.Item>
    )
}