import React, {useRef} from "react";
import {Field, Formik} from "formik";
import {Button, ButtonGroup, Col, Form, Row} from "react-bootstrap";
import {FileCheck, CheckCircle, InfoCircle, X, Hourglass} from "react-bootstrap-icons";
import {useMutation} from "@apollo/react-hooks";
import {MUTATION_UPDATE_SYSTEM_PROPERTY} from "../../../graphql/systemProperty";
import 'react-datetime/css/react-datetime.css'


export const TableRowForm = ({entry, setEditing, ...props}) => {
    const formRef = useRef(null);

    const [ updateSystemProperty ] = useMutation(MUTATION_UPDATE_SYSTEM_PROPERTY)

    function handleSubmit(values, { setSubmitting }) {
        updateSystemProperty({
            variables: {
                data: {systemPropertyId: entry.id, ...values}
            }
        }).then(() => setSubmitting(false)).then(() => setEditing(false))
    }

    function handleReset(values) {
        setEditing(false);
    }



    return (
        <Formik
            innerRef={formRef}
            initialValues={{
                value: entry.value,
                reference: entry.reference,
                referenceMarin: entry.referenceMarin,
                referenceDetail: entry.referenceDetail,
                note: entry.note,
                certainty: entry.certainty,
                include: entry.include,
                explanation: entry.explanation,
                timescale: entry.timescale,
            }}
            onSubmit={handleSubmit}
            onReset={handleReset}
        >
            {({values, handleSubmit, handleReset, resetForm, setFieldValue, setFieldTouched, submitForm, dirty, isSubmitting}) => (
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                    <Form.Group as={Row} className='mb-0'>
                        <Col sm={2} className='text-truncate text-nowrap'>{entry.systemTypeName}</Col>
                        <Col sm={2} className='text-truncate text-nowrap'>{entry.systemName}</Col>
                        <Col sm={2} className='text-truncate text-nowrap'>{entry.systemPropertyGroupName}</Col>
                        <Col sm={1}>
                            <Row>
                                <Col xs={4}>
                                    <Button
                                        size='sm'
                                        type='button'
                                        onClick={() => setFieldValue('include', !values.include)}
                                        onBlur={() => setFieldTouched('include', true)}
                                    >
                                        {(!!values.include) ? <CheckCircle size={18}/> : <X size={18}/>}
                                    </Button>
                                </Col>
                                <Col>
                                    <Field size='sm' as={Form.Control} type='number' step='10' name='certainty' />
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={2}>
                            <Row>
                                <Col sm={8}>
                                    <Field size='sm' as={Form.Control} type='number' step={0.001} name='value' className='text-right' />
                                </Col>
                                <Col>
                                    {entry.systemPropertyGroupUnit}
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={1}>
                            <Field as={Form.Control} type='number' step={5} name='timescale' />
                        </Col>
                        <Col sm={1}>
                            <b>
                                { new Date().toLocaleString('nl-NL', {day: 'numeric', month: 'numeric', year: 'numeric'}) }
                            </b>
                        </Col>
                        <Col sm={1}>
                            <ButtonGroup size='sm' className='w-100'>
                                <Button size='sm'>
                                    <InfoCircle color='white' size={18}/>
                                </Button>
                                <Button size='sm' onClick={submitForm} variant={(dirty) ? 'warning' : 'primary'}>
                                    {(isSubmitting) ? <Hourglass size={18} /> : <FileCheck color='white' size={18}/> }
                                </Button>
                                <Button size='sm' onClick={resetForm}>
                                    <X color='white' size={18}/>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Form.Group>
                    <Row className='mt-3'>
                        <Col sm={5}>
                            <Form.Group as={Row}>
                                <Col sm={2}>
                                    Reference
                                </Col>
                                <Col>
                                    <Field size='sm' as={Form.Control} name='reference'/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col sm={2}>
                                    Reference detail
                                </Col>
                                <Col>
                                    <Field size='sm' as={Form.Control} name='referenceDetail'/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col sm={2}>
                                    Reference (internal)
                                </Col>
                                <Col>
                                    <Field size='sm' as={Form.Control} name='referenceMarin'/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col sm={2}>
                                    Note
                                </Col>
                                <Col>
                                    <Field size='sm' as={Form.Control} name='note' />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col sm={2}>
                                    Explanation
                                </Col>
                                <Col>
                                    <Field size='sm' as={Form.Control} name='explanation' />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>

                </Form>
            )}
        </Formik>
    )
}