import {Button} from "react-bootstrap";
import React, {useContext} from "react";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {ChartContext} from "../charts/Chart";

export const Toggle = ({sName, trueText="On", falseText="Off"}) => {
    const { allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const { chart, settings, setURL } = useContext(ChartContext)

    return (
        <div className={'mt-2 mb-3'}>
            <Button
                onClick={() => {
                    settings[sName] = !settings[sName]
                    setURL(chart, settings)
                    setAllSettings({...allSettings})
                }}
            >
                {settings[sName] ? trueText : falseText}
            </Button>
        </div>
    )
}