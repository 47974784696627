import React from 'react'
import gql from 'graphql-tag'
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'


const httpLink = createHttpLink({
    uri: '/api/graphql'
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('sustainablepower-token');
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    }
});

const cache = new InMemoryCache({
    cacheRedirects: {
        Query: {}
    }
});

const typeDefs = gql`
    extend type ReadingQuery {
        isLoggedIn: Boolean!
    }
`;

const resolvers = {};

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: cache,
    typeDefs,
    resolvers,
});

const defaultData = {
    isLoggedIn: !!localStorage.getItem('sustainablepower-token'),
    userName: ''
};

cache.writeData({
    data: defaultData
    });


export const SustainablePowerApolloProvider = (props) => {
    return (
        <ApolloProvider client={client}>
            {props.children}
        </ApolloProvider>
    )
}