import React, {useState} from "react";
import {Button} from "react-bootstrap";
import {ModalForm} from "./add-modal";
import {StackAddForm} from "./add-form";
import {useParams} from "react-router";
import {useQuery} from "@apollo/react-hooks";
import {QUERY_STACK_TYPE} from "../../../../../graphql/stackType";


export const StackAddButton = ({...props}) => {
    const { stackTypeId } = useParams();
    const [ show, setShow ] = useState(false);
    const { loading, data } = useQuery(QUERY_STACK_TYPE, {variables: {stackTypeId: stackTypeId}})

    if (loading) return 'Loading....'

    return (
        <React.Fragment>
            <Button onClick={() => setShow(true)} {...props} />
            <ModalForm show={show} setShow={setShow} title={`Add a stack to ${data.stackType.name}`}>
                <StackAddForm />
            </ModalForm>
        </React.Fragment>
    )
}