import {useQuery} from "@apollo/react-hooks";
import {QUERY_SYSTEM_TYPES} from "../../../graphql/allData";
import {Button, ButtonGroup, Col, Form, FormGroup, InputGroup, Row} from "react-bootstrap";
import {CaretLeft, CaretRight, X} from "react-bootstrap-icons";
import React from "react";


export const TableFilter = ({filter, setFilter, nextPage, previousPage, page, firstItem, lastItem, numberOfItems, ...props}) => {
    // Add multi-select for the

    const {data: { systemTypes = [] } = {}, loading } = useQuery(
        QUERY_SYSTEM_TYPES,
        {
            onError: (error) => console.log(error),
        }
    )

    if (loading) return <b>Loading...</b>

    const systemPropertyGroups = systemTypes.filter(systemType => systemType.id === filter.systemTypeId).reduce(
        (systemPropertyGroups, systemType) => [...systemPropertyGroups, ...systemType.systemPropertyGroups], []
    )

    return (
        <Form>
            <FormGroup className='mb-0 pb-3' as={Row}>
                <Col sm={2}>
                    <InputGroup>
                        <Form.Control value={filter.systemTypeId} as='select' onChange={(e) => setFilter({...filter, systemTypeId: e.target.value})}>
                            <option value={-1}>System type</option>
                            { systemTypes.map(systemType => <option key={systemType.id} value={systemType.id}>{systemType.name}</option>)}
                        </Form.Control>
                        <InputGroup.Append>
                            <Button size='sm' onClick={() => setFilter({...filter, systemTypeId: -1})}>
                                <X color='white' size={24}/>
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                <Col sm={2}>
                    <InputGroup>
                        <Form.Control
                            value={filter.systemName}
                            onChange={(e) => setFilter({...filter, systemName: e.target.value})}
                            placeholder='System name'
                        />
                        <InputGroup.Append>
                            <Button size='sm' onClick={(e) => setFilter({...filter, systemName: ''})}>
                                <X size={24} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                <Col sm={2}>
                    <InputGroup>
                        <Form.Control value={filter.systemPropertyGroupId} as='select' onChange={(e) => setFilter({...filter, systemPropertyGroupId: e.target.value})}>
                            <option value={-1}>Feature</option>
                            { systemPropertyGroups.map(systemPropertyGroup => <option key={systemPropertyGroup.id} value={systemPropertyGroup.id}>{systemPropertyGroup.name}</option>)}
                        </Form.Control>
                        <InputGroup.Append>
                            <Button size='sm' onClick={(e) => setFilter({...filter, systemPropertyGroupId: -1})}>
                                <X size={24} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                <Col sm={4}/>
                <Col sm={2} className='text-right'>
                    <ButtonGroup size='sm'>
                        <Button onClick={previousPage}><CaretLeft color='white' size={24} /></Button>
                        <Button disabled>{`${firstItem} - ${lastItem} of ${numberOfItems}`}</Button>
                        <Button onClick={nextPage}><CaretRight color='white' size={24} /></Button>
                    </ButtonGroup>
                </Col>
            </FormGroup>
        </Form>
    )
}