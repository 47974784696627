import {ListGroup} from "react-bootstrap";
import React, { useState } from "react";
import {TableRowForm} from "./TableRowForm";
import {TableRowDisplay} from "./TableRowDisplay";


export const TableRow = ({entry, ...props}) => {
    const [ editing, setEditing ] = useState(false)

    return (
        <ListGroup.Item className='py-1'>
            {editing ?
                <TableRowForm entry={entry} setEditing={setEditing} /> :
                <TableRowDisplay entry={entry} setEditing={setEditing} />}
        </ListGroup.Item>
    )
}