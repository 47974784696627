import {useFilter} from "../../../hooks/filter";
import React from "react";
import { PaginatedTable } from "./PaginatedTable";


export const FilteredTable = ({systemProperties, ...props}) => {
    const [filteredSystemProperties, filter, setFilter] = useFilter(
        systemProperties,
        {
            systemTypeId: (item, filterValue) => ((item.systemTypeId === filterValue) || (filterValue === -1)),
            systemPropertyGroupId: (item, filterValue) => ((item.systemPropertyGroupId === filterValue) || (filterValue === -1)),
            systemName: (item, filterValue) => ((item.systemName.toLowerCase().indexOf(filterValue.toLowerCase()) > -1) || (filterValue === '')),
        },
        {
            systemTypeId: -1,
            systemPropertyGroupId: -1,
            systemName: '',
        },
    )

    return (
        <PaginatedTable systemProperties={filteredSystemProperties} filterHelpers={{filter, setFilter}} />
    )
}