import React, { useState } from 'react'
import {Button, Row, Col, Card, InputGroup, ListGroup, Form, Spinner} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {QUERY_STACK_TYPE} from "../../../../../graphql/stackType";
import { MUTATION_UDPATE_STACK_TYPE_FORMULA } from '../../../../../graphql/stackTypeFormula';
import {StackTypeFormulaFieldCreateModalFormButton} from "./fields/create";
import { Check } from 'react-bootstrap-icons';


const StackFormulaDescriptionField = ({stackFormula, ...props}) => {
    const [ description, setDescription ] = useState(stackFormula.description)
    const [ updateStackTypeFormula, { loading } ] = useMutation(MUTATION_UDPATE_STACK_TYPE_FORMULA)

    return (
        <InputGroup {...props}>
            <Form.Control id={`formula-${stackFormula.id}-description-field`} type='text' value={description || ''} onChange={(e) => setDescription(e.target.value)} />
            <InputGroup.Append>
                <Button variant={(stackFormula.description === description) ? 'primary' : 'warning'} disabled={loading} onClick={() => updateStackTypeFormula({variables: {stackTypeFormulaId: stackFormula.id, description: description}})}>
                    {loading ? <Spinner animation='border' size='sm' /> : <Check />}
                </Button>
            </InputGroup.Append>
        </InputGroup>
    )
}


const StackColorPicker = ({stackFormula, ...props}) => {
    const [ color, setColor ] = useState(stackFormula.color)
    const [ updateStackTypeFormula, { loading } ] = useMutation(MUTATION_UDPATE_STACK_TYPE_FORMULA)

    return (
        <InputGroup {...props}>
            <Form.Control type='color' value={color || ''} onChange={(e) => setColor(e.target.value)} />
            <InputGroup.Append>
                <Button disabled={loading} onClick={() => updateStackTypeFormula({variables: {stackTypeFormulaId: stackFormula.id, color: color}})}>
                    <Check />
                </Button>
            </InputGroup.Append>
        </InputGroup>
    )
}


const StackFormulaCard = ({stackFormula, ...props}) => {
    function onKeyDown(e) {
        if ((e.charCode || e.keyCode) === 13) {
            e.preventDefault();
        }
    }

    return (
        <Card>
            <Card.Header>
                <h3>Stack formulas: {stackFormula.name}</h3>
            </Card.Header>
            <Card.Body>
                <ListGroup variant='flush'>
                    <ListGroup.Item>
                        <h2>Formula</h2>
                        <p>{stackFormula.formula}</p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <h2>Fields</h2>
                    </ListGroup.Item>
                    { stackFormula.fields.map(f => (
                        <ListGroup.Item>
                            {f.name} (Calculates {f.systemPropertyGroup.name} for the system defined in {f.stackTypeSystemField.name})
                        </ListGroup.Item>
                    ))}

                    <ListGroup.Item>
                        <h2>Edit</h2>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <Row>
                            <Col sm={6}>
                                <Form onKeyDown={onKeyDown}>
                                    <Form.Group>
                                        <StackTypeFormulaFieldCreateModalFormButton stackTypeFormula={stackFormula}>
                                            Add a formula field
                                        </StackTypeFormulaFieldCreateModalFormButton>
                                    </Form.Group>
                                    <Form.Group>
                                        <StackColorPicker stackFormula={stackFormula} />
                                    </Form.Group>
                                    <Form.Group>
                                        <StackFormulaDescriptionField stackFormula={stackFormula} />
                                    </Form.Group>
                                    <Button className='d-none' type='submit' id={`formula-${stackFormula.id}-submit-button`}>Submit</Button>
                                </Form>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
            </Card.Body>
        </Card>
    )
}


export const StackFormulas = (props) => {
    const { stackTypeId } = useParams();

    const { data, loading } = useQuery(QUERY_STACK_TYPE, {
        variables: { stackTypeId: stackTypeId },
        onError: error => console.log(error),
    })

    if (loading) return 'Loading.....'

    return (
        <React.Fragment>
            { data.stackType.formulas.map((stackFormula) => (
                <StackFormulaCard key={stackFormula.id} stackFormula={stackFormula} />
            ))}
        </React.Fragment>



    )
}