import React, {useState} from 'react';
import {Row, Col, Modal, Form, Button, ButtonGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useMutation} from "@apollo/react-hooks";
import {MUTATION_REMOVE_CATEGORY_GROUP_FROM_ENTITY_TYPE} from "../../../../../graphql/categoryGroup";
import {QUERY_ENTITY_TYPE} from "../../../../../graphql/entityType";
import {Trash} from "react-bootstrap-icons";


const RemoveCategoryGroupFromEntityTypeModal = ({entityType, categoryGroup, show, setShow, ...props}) => {
    const [ removeCategoryGroupFromEntity, { loading }] = useMutation(MUTATION_REMOVE_CATEGORY_GROUP_FROM_ENTITY_TYPE, {
        variables: { data: { entityTypeId: entityType.id, categoryGroupId: categoryGroup.id } },
        refetchQueries: [{query: QUERY_ENTITY_TYPE, variables: {entityTypeId: entityType.id}}],
    })

    const onSubmit = () => {
        removeCategoryGroupFromEntity().then(() => setShow(false))
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header>
                <Modal.Title>Remove category group from {entityType.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        This will remove category group {categoryGroup.name} from entity type {entityType.name}. All
                        category associations between systems belonging to this entity type and categories in this
                        group will be removed. Confirm?
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ButtonGroup className='w-100'>
                            <Button disabled={loading} type='button' onClick={onSubmit}>Confirm</Button>
                            <Button disabled={loading} type='button' variant='danger' onClick={() => setShow(false)}>Cancel</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}


export const RemoveCategoryGroupFromEntityTypeModalButton = ({categoryGroup, entityType, ...props}) => {
    const [show, setShow] = useState(false)

    return (
        <React.Fragment>
            <OverlayTrigger
                overlay={<Tooltip id='remove-category-group-from-entity-type-tooltip'>Remove this category group from this entity type</Tooltip>}>
                <Button onClick={() => setShow(true)} variant='danger'>
                    <Trash />
                </Button>
            </OverlayTrigger>
            <RemoveCategoryGroupFromEntityTypeModal categoryGroup={categoryGroup} show={show} setShow={setShow} entityType={entityType}/>
        </React.Fragment>
    )
}