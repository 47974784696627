import zipcelx from "zipcelx-es5-cjs/src/zipcelx";
import { parse } from "json2csv";
import { saveAs } from 'file-saver';

export const HCExporting = (hasExporting=true, sourceWidth, isPreview, chartName, xlsxRows, csvRows) => {
    return {
        navigation: {
            buttonOptions: {
                theme: {
                    style: {
                        color: '#039',
                        textDecoration: 'underline'
                    }
                }
            }
        },
        exporting: hasExporting ? {
            enabled: !isPreview,
            sourceWidth: sourceWidth,
            csv: {
                decimalPoint: '.',
                itemDelimiter: ','
            },
            menuItemDefinitions: {
                exportXLSX: {
                    text: 'Export to XLSX',
                    onclick: function () {
                        zipcelx({
                            filename: chartName,
                            sheet: {
                                data: xlsxRows
                            }
                        })
                    }
                },
                exportCSV: {
                    text: 'Export to CSV',
                    onclick: function () {
                        const csv = parse(csvRows)
                        const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
                        saveAs(blob, chartName+".csv")
                    }
                }
            },
            buttons: {
                contextButton: {
                    enabled: false
                },
                exportButton: {
                    align: "left",
                    text: 'Download / Export',
                    menuItems: [
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG',
                        'separator',
                        'exportCSV',
                        'exportXLSX'
                    ],
                },
            }
        } : {
            enabled: true,
            sourceWidth: sourceWidth,
            buttons: {
                contextButton: {
                    enabled: false
                },
                exportButton: {
                    align: "left",
                    text: 'Download',
                    menuItems: [
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG'
                    ],
                },
            }
        },
        credits: {
            enabled: false
        },
    }
}