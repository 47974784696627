import {useMutation, useQuery} from "@apollo/react-hooks";
import {QUERY_STACK_TYPE} from "../../../../../../graphql/stackType";
import React, {useContext, useState} from "react";
import {Field, Formik, useField} from "formik";
import {Button, Form, Spinner} from "react-bootstrap";
import {MUTATION_CREATE_STACK_TYPE_FORMULA_FIELD} from "../../../../../../graphql/stackTypeFormulaField";
import {QUERY_SYSTEM_TYPES} from "../../../../../../graphql/allData";
import {ModalForm, ModalFormContext} from "../modals";


const SystemPropertyGroupSelectField = ({systemTypeId, name, ...props}) => {
    const [ field,, ] = useField(name);
    const { data, loading } = useQuery(QUERY_SYSTEM_TYPES);

    let options = [<option key='-1' disabled value=''>Choose a system type</option>]
    if (!!systemTypeId && !loading) {
        options = [...options, data.systemTypes.find(st => st.id === systemTypeId).systemPropertyGroups.map(spg => (
            <option key={spg.id} value={spg.id}>{spg.name}</option>
        ))]
    }

    return (
        <Form.Control as='select' {...field} {...props}>
            { options }
        </Form.Control>
    )
}


const SelectField = ({name, ...props}) => {
    const [ field,, ] = useField(name)

    return (
        <Form.Control as='select' {...field} {...props}>
            {props.children}
        </Form.Control>
    )
}


export const StackTypeFormulaFieldCreateModalForm = ({stackTypeFormula, ...props}) => {
    const [ createStackTypeFormulaField, { loading } ] = useMutation(MUTATION_CREATE_STACK_TYPE_FORMULA_FIELD, {
        refetchQueries: [{query: QUERY_STACK_TYPE, variables: {stackTypeId: stackTypeFormula.stackTypeId }}]
    })

    const { data: systemTypesData, loading: systemTypesLoading } = useQuery(QUERY_SYSTEM_TYPES);
    const { data: stackTypeData, loading: stackTypeLoading } = useQuery(QUERY_STACK_TYPE, {
        variables: {stackTypeId: stackTypeFormula.stackTypeId}
    });

    const { closeModal } = useContext(ModalFormContext);

    if (systemTypesLoading || stackTypeLoading) return 'loading....'

    const systemTypeOptions = systemTypesData.systemTypes.map(st => (
        <option key={st.id} value={st.id}>{st.name}</option>
    ))

    const stackTypeSystemFieldOptions = stackTypeData.stackType.systemFields.map(sf => (
        <option key={sf.id} value={sf.id}>{sf.name}</option>
    ))

    return (
        <Formik
            initialValues={{
                name: '',
                systemTypeId: '',
                systemPropertyGroupId: '',
                stackTypeSystemFieldId: '',
            }}
            onSubmit={(values, { setSubmitting}) => {
                createStackTypeFormulaField({
                    variables: {
                        stackTypeFormulaId: stackTypeFormula.id,
                        name: values.name,
                        systemPropertyGroupId: values.systemPropertyGroupId,
                        stackTypeSystemFieldId: values.stackTypeSystemFieldId,
                    }
                }).then(
                    () => setSubmitting(false),
                ).then(
                    () => closeModal(),
                )
            }}
        >
            {({ values, handleSubmit, handleReset }) => (
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Field as={Form.Control} name='name' placeholder='Name' />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>System type</Form.Label>
                        <SelectField name='systemTypeId'>
                            {systemTypeOptions}
                        </SelectField>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>System feature</Form.Label>
                        <SystemPropertyGroupSelectField
                            name='systemPropertyGroupId'
                            systemTypeId={values.systemTypeId}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Stack field</Form.Label>
                        <SelectField name='stackTypeSystemFieldId'>
                            {stackTypeSystemFieldOptions}
                        </SelectField>
                    </Form.Group>
                    <Button type='submit'>Save { (loading) ? <Spinner animation='border' size='sm' /> : null }</Button>
                </Form>
            )}
        </Formik>
    )
}


export const StackTypeFormulaFieldCreateModalFormButton = ({stackTypeFormula, ...props}) => {
    const [ show, setShow ] = useState(false);

    return (
        <React.Fragment>
            <Button onClick={() => setShow(true)} {...props} />
            <ModalForm show={show} setShow={setShow} title={`Add formula field to ${stackTypeFormula.name}`}>
                <StackTypeFormulaFieldCreateModalForm stackTypeFormula={stackTypeFormula} />
            </ModalForm>
        </React.Fragment>
    )
}