import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import React, {useContext} from "react";
import {ChartContext} from "../charts/Chart";
import {Form} from "react-bootstrap";

export const ColorBySelector = ({}) => {
    const { allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const { chart, setURL, settings, categoryGroups } = useContext(ChartContext)

    function onColorByChanged(event) {
        if (settings.colorBy !== event.target.value) {
            settings.colorBy = event.target.value
            setURL(chart, settings)
            setAllSettings({...allSettings})
        }
    }

    return (
        <Form.Control className={"mt-2 mb-3"} as='select' value={settings.colorBy} onChange={onColorByChanged}>
            {categoryGroups.map(cg => {
                return <option value={cg.id}>{cg.name}</option>
            })}
        </Form.Control>
    )
}