import React, {useContext} from "react";
import Select from "react-select";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {sortAlphabetical} from "../functions/SortAlphabetical";
import {ChartContext} from "../charts/Chart";

export const FormulaOrSPGSelector = ({fKey, forSort=false}) => {
    const { allFormulas, allSystemTypes, allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    const { chart, setURL, settings } = useContext(ChartContext)

    function onSelectedFeatureChange(selectedFeature) {
        if (selectedFeature.value === "abc" || selectedFeature.value === "sum") {
            settings.sortBy = selectedFeature.value
        } else {
            if (forSort) {
                settings.sortBy = {groupId: selectedFeature.value.groupId, id: selectedFeature.value.id}
            } else {
                settings.selectedFeatures[fKey] = {groupId: selectedFeature.value.groupId, id: selectedFeature.value.id}
            }
        }
        setURL(chart, settings)
        setAllSettings({...allSettings})
    }

    let options = []
    if (forSort) {
        options.push({
            label: "General",
            options: [{
                label: "Alphabetical",
                value: "abc"
            }, {
                label: "Sum of bars",
                value: "sum"
            }]
        })
    }
    options.push({
        label: "Stack formulas",
        options: allFormulas
            .filter(f => !f.hide)
            .sort((a, b) => sortAlphabetical(a.name, b.name))
            .sort((a, b) => a.index - b.index)
            .map(f => {
                return {
                    label: f.name,
                    group: "Stack",
                    value: {
                        groupId: "6",
                        id: f.id
                    },
                    // isDisabled:,
                    // isHidden:
                }
            })
    })
    allSystemTypes.sort((a, b) => ["1", "2", "3", "5", "4"].indexOf(a.id) - ["1", "2", "3", "5", "4"].indexOf(b.id)).forEach(sysT => {
        options.push({
            label: sysT.name,
            options: sysT.systemPropertyGroups
                .sort((a, b) => sortAlphabetical(a.name, b.name))
                .sort((a, b) => b.index - a.index)
                .sort((a, b) => a.index === null ? 1 : -1)
                .map(spg => {
                    return {
                        label: spg.name,
                        group: sysT.name,
                        value: {
                            groupId: sysT.id,
                            id: spg.id
                        }
                    }
                })
        })
    })

    const selectedFeature = forSort ? settings.sortBy : settings.selectedFeatures[fKey]
    let selectedFeatureOption
    if (typeof selectedFeature === "string") {
        selectedFeatureOption = selectedFeature === "abc" ?
            { label: "Alphabetical", value: "abc" } :
            { label: "Sum of bars", value: "sum" }
    } else {
        selectedFeatureOption = options
            .reduce((acc, optionGroup) => [...acc, ...optionGroup.options], [])
            .find(f =>
                f.value.groupId === selectedFeature.groupId &&
                f.value.id === selectedFeature.id
            )
        selectedFeatureOption.label = selectedFeatureOption.group + " / " + selectedFeatureOption.label
    }

    return (
        <Select
            className={'my-2'}
            onChange={onSelectedFeatureChange}
            value={selectedFeatureOption}
            options={options}
        />
    )
}