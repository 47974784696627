import React, {useContext, useMemo} from "react";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";
import {TableDisplay, sortNumbers, sortStrings} from "./table";
import {isObject} from "../functions/isObject";

const page = "energyCarriers"
const chart = "table"

export const EnergyCarriersTable = () => {
    const { allSystems, allSystemTypes, allCategoryGroups, allSettings, setAllSettings } = useContext(SustainablePowerDataContext2)
    if (!(page in allSettings) || !isObject(allSettings[page]) || !(chart in allSettings[page])) {
        // return illegalParameters("emptyLocalStorage")
        if (!(page in allSettings) || !isObject(allSettings[page])) {
            allSettings[page] = {}
        }
        allSettings[page][chart] = {
            groupBy: '2'
        }
        setAllSettings({...allSettings})
    }
    const filter = allSettings[page][chart]

    const categoryGroups = allCategoryGroups
        .filter(cg => !cg.hide)
        .filter(cg => ["2", "3"].includes(cg.id))
        .sort((a, b) => a.index - b.index)

    if ((!Number.isInteger(Number(filter.groupBy)) || categoryGroups.find(cg => cg.id === filter.groupBy) === undefined) && filter.groupBy !== "ungrouped") {
        filter.groupBy = '2'
        setAllSettings({...allSettings})
    }

    const energyCarriers = allSystems.filter(sys => sys.systemTypeId === "2")

    const metaFields = allSystemTypes.find(sysT => sysT.id === '2').metaFields

    const columns = useMemo(
        () => [
            // { accessor: 'group', Header: 'Group' },
            { accessor: 'name', Header: 'Energy Carrier', sortType: (rowA, rowB, columnId, desc) => sortStrings(rowA, rowB, columnId, desc) },
            { accessor: 'pathwayCat', Header: 'Pathway Category', sortType: (rowA, rowB, columnId, desc) => sortStrings(rowA, rowB, columnId, desc), tooltip: metaFields.find(mf => mf.name === 'Pathway Category').description },
            { accessor: 'pathwaySpec', Header: 'Pathway Specification', sortType: (rowA, rowB, columnId, desc) => sortStrings(rowA, rowB, columnId, desc), tooltip: metaFields.find(mf => mf.name === 'Pathway Specification').description },
            { accessor: 'stateOfMatter', Header: 'State of matter', sortType: (rowA, rowB, columnId, desc) => sortStrings(rowA, rowB, columnId, desc), tooltip: metaFields.find(mf => mf.name === 'State of matter').description },
            { accessor: 'physical', Header: 'Physical', sortType: (rowA, rowB, columnId, desc) => sortStrings(rowA, rowB, columnId, desc), tooltip: metaFields.find(mf => mf.name === 'Physical').description },
            { accessor: 'description', Header: 'Description', sortType: (rowA, rowB, columnId, desc) => sortStrings(rowA, rowB, columnId, desc), tooltip: metaFields.find(mf => mf.name === 'Description').description },
            { accessor: 'energyDensity', Header: 'Energy Density', disableSortBy: true, tooltip: 'The energy density of the energy carrier separated into uncontained (the physical carrier) and contained (with storage tanks) figures', columns: [
                { accessor: 'uncontained', Header: 'Uncontained', disableSortBy: true, tooltip: 'The physical density of the energy carrier. Volumetric figures are corrected for the storage conditions on-board as specified under physical', columns: [
                    { accessor: 'uncontainedVolumetric', Header: 'MJ/L', decimals: 2, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc, true), tooltip: 'the physical volumetric density. Values are corrected for the storage pressure on-board as specified under Physical'},
                    { accessor: 'uncontainedGravimetric', Header: 'MJ/kg', decimals: 2, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc, true), tooltip: 'the physical gravimetric density'},
                ]},
                { accessor: 'contained', Header: 'Contained', disableSortBy: true, tooltip: 'defined as the energy that is stored divided by the volume/mass of its containment. The containment includes the constructions for containment but also ships systems for distribution, replenishment and safety of the energy carrier storage', columns: [
                    { accessor: 'containedVolumetric', Header: 'MJ/L', decimals: 2, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc, true), tooltip: 'defined as the energy that is stored divided by the sum of its volume plus the volume of its containment. The containment includes the constructions for containment but also ships systems for distribution, replenishment and safety of the energy carrier storage.'},
                    { accessor: 'containedGravimetric', Header: 'MJ/kg', decimals: 2, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc, true), tooltip: 'defined as the energy that is stored divided by the sum of its own mass plus the mass of its containment. The containment includes the constructions for containment but also ships systems for distribution, replenishment and safety of the energy carrier storage.'},
                ]},
            ]},
            { accessor: 'emissions', Header: 'WTT Emissions & Global warming potential', disableSortBy: true, tooltip: 'The Well-to-Tank emissions arising from energy carrier production and transport. Land use (change) is not considered in the emission.', columns: [
                { accessor: 'CO2', Header: 'CO2', disableSortBy: true, tooltip: 'the amount of CO2 emitted from carrier production to fuel bunkering. This is expressed in kg CO2 emitted per unit energy in the fuel. This can be a negative value when considering bio-fuels or fuels produced using carbon capture.', columns: [
                    { accessor: 'emissionsCO2', Header: 'kg/GJ', decimals: 2, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc, true), tooltip: 'the amount of CO2 emitted from carrier production to fuel bunkering. This is expressed in kg CO2 emitted per unit energy in the fuel. This can be a negative value when considering bio-fuels or fuels produced using carbon capture.'},
                ]},
                { accessor: 'CH4', Header: 'CH4', disableSortBy: true, tooltip: 'the amount of CH4 emitted from carrier production to fuel bunkering. This is expressed in kg CH4 emitted per unit energy in the fuel.', columns: [
                    { accessor: 'emissionsCH4', Header: 'kg/GJ', decimals: 2, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc, true), tooltip: 'the amount of CH4 emitted from carrier production to fuel bunkering. This is expressed in kg CH4 emitted per unit energy in the fuel.'},
                ]},
                { accessor: 'N2O', Header: 'N2O', disableSortBy: true, tooltip: 'the amount of N2O emitted from carrier production to fuel bunkering. This is expressed in kg N2O emitted per unit energy in the fuel.', columns: [
                    { accessor: 'emissionsN2O', Header: 'kg/GJ', decimals: 2, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc, true), tooltip: 'the amount of N2O emitted from carrier production to fuel bunkering. This is expressed in kg N2O emitted per unit energy in the fuel.'},
                ]},
                { accessor: 'GWP20', Header: 'GWP20', disableSortBy: true, tooltip: 'GWP20 the Well-to-Tank global warming potential for a 20 year period. Calculated as 1x CO2, 85x CH4, 265x N2O ', columns: [
                    { accessor: 'emissionsGWP20', Header: 'kg/GJ', decimals: 2, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc, true), tooltip: 'GWP20 the Well-to-Tank global warming potential for a 20 year period. Calculated as 1x CO2, 85x CH4, 265x N2O '},
                ]},
                { accessor: 'GWP100', Header: 'GWP100', disableSortBy: true, tooltip: 'GWP100 the Well-to-Tank global warming potential for a 100 year period. Calculated as 1x CO2, 30x CH4, 265x N2O ', columns: [
                    { accessor: 'emissionsGWP100', Header: 'kg/GJ', decimals: 2, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc, true), tooltip: 'GWP100 the Well-to-Tank global warming potential for a 100 year period. Calculated as 1x CO2, 30x CH4, 265x N2O '},
                ]},
            ]},
            { accessor: 'costs', Header: 'Costs', disableSortBy: true, columns: [
                { accessor: 'CapEx', Header: 'CapEx', disableSortBy: true, tooltip: 'Capital expenses for energy carrier storage per kWh energy stored on-board. This consist of constructions for containment and ships systems for distribution, replenishment and safety of the energy carrier storage.', columns: [
                    { accessor: 'costsCapEx', Header: '€/kWh', decimals: 2, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc, true), tooltip: 'Capital expenses for energy carrier storage per kWh energy stored on-board. This consist of constructions for containment and ships systems for distribution, replenishment and safety of the energy carrier storage.'},
                ]},
                { accessor: 'OpEx', Header: 'OpEx', disableSortBy: true, tooltip: 'Operational expenses for energy carrier bunkering per kWh energy purchased. This includes the production costs of the energy carrier, transport, distribution and bunkering on-board.', columns: [
                    { accessor: 'costsOpEx', Header: '€/kWh', decimals: 2, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc, true), tooltip: 'Operational expenses for energy carrier bunkering per kWh energy purchased. This includes the production costs of the energy carrier, transport, distribution and bunkering on-board.'},
                ]},
            ]},
            { accessor: 'readiness', Header: 'Readiness', disableSortBy: true, columns: [
                { accessor: 'TRL', Header: 'TRL', decimals: 0, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc,true), tooltip: 'TRL Technical Readiness Level of the Energy Carrier is an internationally accepted measure for maturity of the technology. To what extend is the energy carrier suitable for practical application. At little bit against the pure definition the logistic availability is included, meaning that ‘technical ready, but almost nowhere to get’ scores low TRL.'},
                { accessor: 'SRL', Header: 'SRL', decimals: 0, sortType: (rowA, rowB, columnId, desc) => sortNumbers(rowA, rowB, columnId, desc, true), tooltip: 'TRL Technical Readiness Level of the Energy Carrier is an internationally accepted measure for maturity of the technology. To what extend is the energy carrier suitable for practical application. At little bit against the pure definition the logistic availability is included, meaning that ‘technical ready, but almost nowhere to get’ scores low TRL.'}
            ]}
        ], []
    )

    const data = useMemo(() => energyCarriers.sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) { return -1 }
            if (a.name.toUpperCase() > b.name.toUpperCase()) { return 1 }
            return 0
        }).map(sys => {
            return {
                group: sys.categories.find(c => c.categoryGroupId === filter.groupBy)!==undefined ?
                    sys.categories.find(c => c.categoryGroupId === filter.groupBy).name :
                    undefined,
                name: sys.name,
                pathwayCat: sys.metaData.find(m => m.systemTypeMetaField.name==="Pathway Category").value,
                pathwaySpec: sys.metaData.find(m => m.systemTypeMetaField.name==="Pathway Specification").value,
                stateOfMatter: sys.metaData.find(m => m.systemTypeMetaField.name==="State of matter").value,
                physical: sys.metaData.find(m => m.systemTypeMetaField.name==="Physical").value,
                description: sys.metaData.find(m => m.systemTypeMetaField.name==="Description").value,
                uncontainedVolumetric: sys.values !== null && 'Uncontained Volumetric Energy Density' in sys.values ? {
                    value: sys.values['Uncontained Volumetric Energy Density']['average'],
                    min: sys.values['Uncontained Volumetric Energy Density']['minimum'].toFixed(2),
                    max: sys.values['Uncontained Volumetric Energy Density']['maximum'].toFixed(2),
                    tooltip: "Range {min} to {max}"
                } : null,
                uncontainedGravimetric: sys.values !== null && 'Uncontained Gravimetric Energy Density' in sys.values ? {
                    value: sys.values['Uncontained Gravimetric Energy Density']['average'],
                    min: sys.values['Uncontained Gravimetric Energy Density']['minimum'].toFixed(2),
                    max: sys.values['Uncontained Gravimetric Energy Density']['maximum'].toFixed(2),
                    tooltip: "Range {min} to {max}"
                } : null,
                containedVolumetric: sys.values !== null && 'Contained Volumetric Energy Density' in sys.values ? {
                    value: sys.values['Contained Volumetric Energy Density']['average'],
                    min: sys.values['Contained Volumetric Energy Density']['minimum'].toFixed(2),
                    max: sys.values['Contained Volumetric Energy Density']['maximum'].toFixed(2),
                    tooltip: "Range {min} to {max}"
                } : null,
                containedGravimetric: sys.values !== null && 'Contained Gravimetric Energy Density' in sys.values ? {
                    value: sys.values['Contained Gravimetric Energy Density']['average'],
                    min: sys.values['Contained Gravimetric Energy Density']['minimum'].toFixed(2),
                    max: sys.values['Contained Gravimetric Energy Density']['maximum'].toFixed(2),
                    tooltip: "Range {min} to {max}"
                } : null,
                emissionsCO2: sys.values !== null && 'CO2 Well to Tank Emission' in sys.values ? {
                    value: sys.values['CO2 Well to Tank Emission']['average'],
                    min: sys.values['CO2 Well to Tank Emission']['minimum'].toFixed(2),
                    max: sys.values['CO2 Well to Tank Emission']['maximum'].toFixed(2),
                    tooltip: "Range {min} to {max}"
                } : null,
                emissionsCH4: sys.values !== null && 'CH4 Well to Tank Emission' in sys.values ? {
                    value: sys.values['CH4 Well to Tank Emission']['average'],
                    min: sys.values['CH4 Well to Tank Emission']['minimum'].toFixed(2),
                    max: sys.values['CH4 Well to Tank Emission']['maximum'].toFixed(2),
                    tooltip: "Range {min} to {max}"
                } : null,
                emissionsN2O: sys.values !== null && 'N2O Well to Tank Emission' in sys.values ? {
                    value: sys.values['N2O Well to Tank Emission']['average'],
                    min: sys.values['N2O Well to Tank Emission']['minimum'].toFixed(2),
                    max: sys.values['N2O Well to Tank Emission']['maximum'].toFixed(2),
                    tooltip: "Range {min} to {max}"
                } : null,
                emissionsGWP20: sys.values !== null && 'GWP20 Well to Tank Emission' in sys.values ? {
                    value: sys.values['GWP20 Well to Tank Emission']['average'],
                    min: sys.values['GWP20 Well to Tank Emission']['minimum'].toFixed(2),
                    max: sys.values['GWP20 Well to Tank Emission']['maximum'].toFixed(2),
                    tooltip: "Range {min} to {max}"
                } : null,
                emissionsGWP100: sys.values !== null && 'GWP100 Well to Tank Emission' in sys.values ? {
                    value: sys.values['GWP100 Well to Tank Emission']['average'],
                    min: sys.values['GWP100 Well to Tank Emission']['minimum'].toFixed(2),
                    max: sys.values['GWP100 Well to Tank Emission']['maximum'].toFixed(2),
                    tooltip: "Range {min} to {max}"
                } : null,
                costsCapEx: sys.values !== null && 'CapEx' in sys.values ? {
                    value: sys.values['CapEx']['average'],
                    min: sys.values['CapEx']['minimum'].toFixed(2),
                    max: sys.values['CapEx']['maximum'].toFixed(2),
                    tooltip: "Range {min} to {max}"
                } : null,
                costsOpEx: sys.values !== null && 'OpEx per unit energy' in sys.values ? {
                    value: sys.values['OpEx per unit energy']['average'],
                    min: sys.values['OpEx per unit energy']['minimum'].toFixed(2),
                    max: sys.values['OpEx per unit energy']['maximum'].toFixed(2),
                    tooltip: "Range {min} to {max}"
                } : null,
                TRL: sys.values !== null && 'TRL' in sys.values ? {
                    value: sys.values['TRL']['average'],
                    min: sys.values['TRL']['minimum'],
                    max: sys.values['TRL']['maximum'],
                    tooltip: "Range {min} to {max}"
                } : null,
                SRL: sys.values !== null && 'SRL' in sys.values ? {
                    value: sys.values['SRL']['average'],
                    min: sys.values['SRL']['minimum'],
                    max: sys.values['SRL']['maximum'],
                    tooltip: "Range {min} to {max}"
                } : null,
            }
    }), [energyCarriers])

    return (
        <TableDisplay
            data={data}
            columns={columns}
            page={page}
            categoryGroups={allCategoryGroups
                .filter(cg => !cg.hide)
                .filter(cg => ["2", "3"].includes(cg.id))
                .sort((a, b) => a.index - b.index)
            }
        />
    )
}