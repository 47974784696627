import React, {useContext} from 'react';
import {Chart} from "../../general/charts/Chart";
import {SustainablePowerDataContext2} from "../../providers/SustainablePowerDataProvider2";


export const Home = (props) => {
    const { allPages } = useContext(SustainablePowerDataContext2)
    const showPage = allPages.find(p => p.name === 'Pathways')
    const ch = showPage.charts[0]
    // const wtwPage = allPages.find(p => p.name ==='Well-to-Wake')
    // const scatterCh = wtwPage.charts.find(c => c.name === 'bubble')
    // let settingToUpdate
    // settingToUpdate = scatterCh.chartSettings.find(st => st.name === 'selectedFeatures')
    // settingToUpdate.default = JSON.stringify([
    //     {groupId: '6', id: '30'},
    //     {groupId: '6', id: '1'},
    //     {groupId: '2', id: '14'}
    // ])
    //
    // settingToUpdate = scatterCh.chartSettings.find(st => st.name === 'rangeXMin')
    // settingToUpdate.default = 0.05
    // settingToUpdate = scatterCh.chartSettings.find(st => st.name === 'rangeXMax')
    // settingToUpdate.default = 0.45

    return (
        <React.Fragment>
            <div className='row mt-3'>
                <div className='col-xl-8 col-lg-10 col-md-12 mx-auto' style={{maxWidth: 1300}}>
                <h3>
                    The content of this portal is sustained by ongoing strategic research on zero emission maritime
                    transport conducted at MARIN, the Maritime Research Institute Netherlands, and made possible thanks to
                    the European Commission, by means of its working group on Sustainable Alternative Power for Ships
                    (SAPS), part of the European Sustainable Shipping Forum (ESSF).
                </h3>
                <p>
                    The ESSF-SAPS working group agreed in 2020 to give public access to its ongoing work and dataset
                    concerning energy carriers and their emission pathways, likely to be used for maritime transport. This
                    working group is composed of experts from all Europe, from major energy providers, ship owners,
                    shipyards, harbour authorities, to flag states, ministries, classification societies, engine
                    manufacturers, energy carriers producers, branch organisations or NGO’s.
                </p>
                <p>
                    <img alt='Diagram' className='w-100' style={{maxWidth: 1360}} id='imageHome01' src='/HomeImage01_v7.png'/>
                    <center>
                        <b>Overview of the defined systems and features</b>
                    </center>
                </p>
                <p>
                    The portal proposes an aggregation of data, that is constantly updated on basis of the latest research
                    and publications made available to us. Would you spot any inconsistency, would you have any additional
                    relevant information or research results, please do not hesitate to provide us feedback at
                    ships@marin.nl with “ESSF-SAPS feedback” mentioned in the title. Thank you in advance for your
                    contribution!
                </p>
                <h2>
                    <u>Goal of the portal</u>
                </h2>
                <ul>
                    <li>
                        Give free access to consistent and transparent knowledge & information in order to brings awareness of what is possible in terms of sustainable alternative power for Ships.
                    </li>
                    <li>
                        Provide a comprehensive summary of the existing scientific knowledge on the performance and potential of different alternative sustainable power, energy conversion technologies for shipping, including their environmental performance on a complete well-to-wake approach, complemented, where appropriate, with life cycle considerations.
                    </li>
                    <li>
                        Trigger your curiosity, gather facts, learn, imagine alternative sustainable solutions, think about their implementation, launch discussion, support urgent strategic choice.
                    </li>
                </ul>
                <p>
                    If we this portal helps effectively the maritime sector making efficient and useful choices to reduce rapidly their emission levels and sail towards zero emission well-to-wake pathways, our goal will be fulfilled.
                </p>
                <p>
                    {
                        <Chart page={showPage} chart={ch} isPreview={true} />
                    }
                    <center>
                        <b>Overview of the defined systems and features</b>
                    </center>
                </p>
                <h2>
                    Content of the database
                </h2>
                <p>
                    The present database reflects the features of a selection of energy carriers and selected pathways, as well as the characteristics of associated power conversion systems. All possible combinations of energy carrier with a conversion system are not developed. We have selected a number of them to guarantee that observed trends are globally representatives.
                </p>
                <p>
                    <img alt='Diagram' className='w-100' style={{maxWidth: 1360}} id='imageHome02' src='/visual_data_set_V1.png'/>
                </p>
                <p>
                    Please note that any additional systems and properties can be added to this database at any time.
                </p>
                <p>
                    References are also given for the source where data were collected. So far, only the name and link towards our local site is given. When public, the link to the publication will also be added and the document provided as hyperlink into the information table.
                </p>
                <p>
                    The information present in the <i>DATA TABLE</i> is shown in an interactive table in the menu <i>ENERGY CARRIERS</i>. In this table, the energy carriers are listed but not yet linked to specific power conversion systems. This is done in the <i>WELL-TO-WAKE</i> menu and further illustrated in the <i>PATHWAYS</i> menu
                </p>
                <p>
                    <img alt='Diagram' className='w-100' style={{maxWidth: 1360}} id='imageHome03' src='/visual_list_V1.png'/>
                    <center>
                        <b>List of properties of documented energy carriers in interactive <i>Energy Carriers</i> menu</b>
                    </center>
                </p>
                <p>
                    Some information are however not (yet) available on this portal (… but we keep developing):
                </p>
                <ul>
                    <li>Information on the scalability of resources to meet a local or global demand is not (yet) evaluated in the present portal.</li>
                    <li>Feasibility to install a specific solution on a specific ship type and route. Data can be used as starting point but such feasibility study would require specific information on ship size, displacement, general arrangement, required power, operational profile, required range and bunkering logistics.</li>
                    <li>Identification of barriers that could hamper the uptake and deployment of potential sustainable alternative technology.</li>
                </ul>
                <h2>
                    Interactive knowledge database to understand and explore sustainable horizons
                </h2>
                <p>
                    Interactivity has been set-up in all graphs, in order to allow the visitor to browse efficiently the data set. Attention has been paid to make the visualisation accessible to most and understandable. Any suggestion is always welcome to improve the way we present the data.
                </p>
                <p>
                    Here after are different graphs that illustrate the global trend of the situation and shapes already the challenges and options that offer to us.
                </p>
                <p>
                    One of the first observation is the fact that none of the current documented Well-to-Wake pathways making use of fossil resources can match the emission reduction ambitions. Even despite carbon sequestration measures for some solutions, fugitive emissions (methane slip for example) of fossil resource will hamper their potential and even produce larger emissions than the current products.
                </p>
                <p>
                    In the same way, it is clear to identify that most solutions making use of renewable and nuclear energy are providing significant reduction of zero emission level, with two exceptions.
                </p>
                <p>
                    Biomass pathways have more spreading in their CO2eq. GWP levels, ranging far above current fossil Diesel level down to zero emission level.
                </p>
                <p>
                    <center>
                        <img alt='Diagram' style={{maxWidth: 1360}} id='imageHome04' src='/chart.png'/>
                        <p>
                            <b>Illustration of the importance of the used resource on the Well-to-Wake emission level, for all documented solutions</b>
                        </p>
                    </center>
                </p>
                <p>
                    Per type of energy carrier, the resource used for production will be of prime importance as the same molecule for a tank-to-wake perspective can have positive of devastating effect when looking at the full pathway. An illustration is given here after for Hydrogen but will apply as well for energy carriers (Methanol, DME, Ammonia, etc).
                </p>
                <p>
                    <center>
                        <img alt='Diagram' style={{maxWidth: 1360}} id='imageHome05' src='/H2_origin.png'/>
                        <p>
                            <b>Illustration of the importance of the type of energy resource used on the well-to-wake emission level of Hydrogen (same applies to all other energy carriers)</b>
                        </p>
                    </center>
                </p>
                <p>
                    Actually the same applies in road transport at this moment. A kWh of electricity can, on the well-to-wheel emission perspective which is the only that count for the planet, produce as much CO2eq. Global Warming Potential as diesel or zero depending on the resource used to produce it. A classification of similar energy carriers will probably need to be put into place based on their complete pathway in order to identify their effective sustainability (at regulatory or consumer perspective view point).
                </p>
                <p>
                    <center>
                        {/*{*/}
                        {/*    <Chart page={wtwPage} chart={scatterCh} isPreview={false} />*/}
                        {/*}*/}
                        <img alt='Diagram' className='w-100' style={{maxWidth: 1360}} id='imageHome06' src='/stack_scatter_V1.png'/>
                        <p>
                            <b>Overview of Emission levels & current Costs per delivered energy unit</b>
                        </p>
                    </center>
                </p>
                <p>
                    Another interesting overview is the current offer of sustainable alternative solutions, their emission reduction potential, their cost and TRL level. The figure above illustrates the actual bottleneck in their development and introduction: many solutions but mostly at higher costs (2021 status), lower energy density and low TRL level. Price will evolve downward with larger volume of production, TRL will increase with time, inherent energy density properties will remain similar. Because of this, for any upcoming sustainable alternative solution, energy efficiency in the transport side and energy sobriety will be of prime importance.
                </p>
                <p>
                    All feedback can be provided via the following e-mail address:
                    <a href='mailto:ships@marin.nl'>ships@marin.nl</a> with “ESSF-SAPS feedback” mentioned in the title.
                    Thank you in advance for your contribution!
                </p>
                </div>
            </div>
        </React.Fragment>

    )
}