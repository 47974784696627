import gql from 'graphql-tag';
import { FRAGMENT_SYSTEM_CONTENT } from './system';


export const QUERY_SYSTEM_TYPES = gql`
    query getSystemTypes {
        systemTypes {
            id
            name
        }
    }
`


export const QUERY_SYSTEM_TYPE = gql`
    query getSystemType($systemTypeId: ID!) {
        systemType(systemTypeId: $systemTypeId) {
            id
            name
            metaFields {
                id
                name
                description
                index
            }
            systemPropertyGroups {
                id
                name
                shortName
                description
                unit
                color
                index
            }
            systems {
                id
                ...systemContent
            }
            categoryGroups {
                id
                name
            }
        }
    }
    ${FRAGMENT_SYSTEM_CONTENT}
`