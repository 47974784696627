import React, {useState} from 'react';
import {Modal, Form, Row, Col, ButtonGroup, Button, OverlayTrigger, Tooltip, Card, ListGroup} from 'react-bootstrap';
import {useParams} from "react-router";
import {Formik} from 'formik';
import {SelectField} from "../../../general/forms/SelectField";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {MUTATION_ADD_CATEGORY_GROUP_TO_ENTITY_TYPE, QUERY_CATEGORY_GROUPS} from "../../../../graphql/categoryGroup";
import {QUERY_ENTITY_TYPE} from "../../../../graphql/entityType";
import * as Yup from 'yup';
import {RemoveCategoryGroupFromEntityTypeModalButton} from "./category-groups/delete-category-group";


const AddCategoryGroupToEntityTypeModal = ({entityType, show, setShow, ...props}) => {
    const {data: categoryGroupsData = {categoryGroups: []}, loading: categoryGroupsLoading} = useQuery(QUERY_CATEGORY_GROUPS)
    const [addCategoryGroupToEntityType, {loading}] = useMutation(MUTATION_ADD_CATEGORY_GROUP_TO_ENTITY_TYPE, {
        refetchQueries: [{query: QUERY_ENTITY_TYPE, variables: {entityTypeId: entityType.id}}]
    })

    const onSubmit = (values, {setSubmitting}) => {
        addCategoryGroupToEntityType({
            variables: {
                data: {
                    categoryGroupId: values.categoryGroupId,
                    entityTypeId: entityType.id,
                }
            }
        }).then(() => setSubmitting(false)).then(() => setShow(false))
    }

    const validationSchema = Yup.object().shape({
        categoryGroupId: Yup.number()
            .required()
            .moreThan(0)
    })

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add category group to {entityType.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    isInitialValid={false}
                    validationSchema={validationSchema}
                    initialValues={{
                        categoryGroupId: '',
                    }}
                    onSubmit={onSubmit}
                >
                    {({handleReset, handleSubmit, isSubmitting, isValid}) => (
                        <Form onReset={handleReset} onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Label>Category group:</Form.Label>
                                <SelectField disabled={categoryGroupsLoading} name='categoryGroupId'>
                                    {categoryGroupsLoading ? (
                                        <option disabled value=''>Loading....</option>
                                    ) : (
                                        <React.Fragment>
                                            <option value='' disabled>Select a category group</option>
                                            { categoryGroupsData.categoryGroups.map(categoryGroup => (
                                                <option key={categoryGroup.id} value={categoryGroup.id}>{categoryGroup.name}</option>
                                            ))}
                                        </React.Fragment>
                                    )}
                                </SelectField>
                            </Form.Group>
                            <Form.Group>
                                <ButtonGroup>
                                    <Button type='submit' disabled={!isValid || isSubmitting || loading}>Submit</Button>
                                    <Button type='button' onClick={() => setShow(false)} disabled={isSubmitting}>Cancel</Button>
                                </ButtonGroup>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}


export const AddCategoryGroupToEntityTypeModalButton = ({entityType, ...props}) => {
    const [show, setShow] = useState(false)

    return (
        <React.Fragment>
            <OverlayTrigger
                overlay={<Tooltip id='add-category-group-tooltip'>Link this entity type to a category group</Tooltip>}>
                <Button onClick={() => setShow(true)}>Link a category group</Button>
            </OverlayTrigger>
            <AddCategoryGroupToEntityTypeModal show={show} setShow={setShow} entityType={entityType}/>
        </React.Fragment>
    )
}


export const EntityTypeCategoryGroups = (props) => {
    const {stackTypeId, systemTypeId} = useParams()
    const entityTypeId = stackTypeId || systemTypeId;

    const {data, loading} = useQuery(QUERY_ENTITY_TYPE, {
        variables: {entityTypeId: entityTypeId},
    })

    if (loading) return 'Loading.....'

    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col>
                        <Card.Title>
                            Category groups for {data.entityType.name}
                        </Card.Title>
                    </Col>
                    <Col className='text-right'>
                        <AddCategoryGroupToEntityTypeModalButton entityType={data.entityType}/>
                    </Col>
                </Row>

            </Card.Header>
            <ListGroup variant='flush'>
                {data.entityType.categoryGroups.map(categoryGroup => (
                    <ListGroup.Item key={categoryGroup.id}>
                        <Row>
                            <Col>
                                {categoryGroup.name}
                            </Col>
                            <Col className='text-right'>
                                <RemoveCategoryGroupFromEntityTypeModalButton categoryGroup={categoryGroup} entityType={data.entityType} />
                            </Col>
                        </Row>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </Card>
    )
}