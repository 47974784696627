import {useField} from "formik";
import {Form} from "react-bootstrap";
import React from "react";


export const SelectField = (props) => {
    const [field] = useField(props)

    return (
        <Form.Control as='select' {...field} {...props}>
            {props.children}
        </Form.Control>
    )
}