import React, {useContext} from 'react'
import {FilteredTable} from "./FilteredTable";
import {SustainablePowerDataContext} from "../../providers/SustainablePowerDataProvider";


export const Table = (props) => {
    const { systemProperties } = useContext(SustainablePowerDataContext)

    return (
        <FilteredTable systemProperties={systemProperties} />
    )
}