import React, { useState, useRef } from 'react'
import { Formik, Field } from 'formik'
import {ButtonGroup, Modal, Button, Form, Spinner, Alert, InputGroup} from "react-bootstrap";
import {useMutation, useQuery} from "@apollo/react-hooks";
import { QUERY_SYSTEM_TYPES, QUERY_SYSTEM_TYPE} from "../../../graphql/systemType";
import {QUERY_SYSTEMS} from "../../../graphql/system";
import {MUTATION_CREATE_SYSTEM_PROPERTY, QUERY_SYSTEM_PROPERTIES} from "../../../graphql/systemProperty";
import DateTime from "react-datetime";
import * as Yup from 'yup';
import {Hourglass} from "react-bootstrap-icons";
import {omit} from "lodash/object";

const SystemTypeSelectBox = ({setFieldValue, setFieldTouched, value, name, ...props}) => {
    const { data, loading } = useQuery(QUERY_SYSTEM_TYPES)

    if (loading) return <div><Spinner size='sm' animation='border' className='mr-3' /> Loading......</div>

    const options = data.systemTypes.map(st => <option key={st.id} value={st.id}>{st.name}</option>)

    return (
        <Form.Control
            onChange={(e) => setFieldValue(name, e.target.value)}
            onBlur={(e) => setFieldTouched(name, true)}
            as='select'
            value={value}
            { ...props }
        >
            <option value={''} disabled>Select a system type</option>
            { options }
        </Form.Control>
    )
}


const SystemSelectBox = ({setFieldValue, setFieldTouched, systemTypeId, value, name, ...props}) => {
    const { data, loading } = useQuery(QUERY_SYSTEMS)

    if (loading) return <div><Spinner size='sm' animation='border' className='mr-3' /> Loading......</div>

    if (systemTypeId === '') return (
        <Form.Control disabled as='select'>
            <option disabled selected>Select a system type first</option>
        </Form.Control>
    )

    const options = data.systems
        .filter(s => s.systemTypeId === systemTypeId)
        .map(s => <option key={s.id} value={s.id}>{s.name}</option>)

    return (
        <Form.Control
            onChange={(e) => setFieldValue(name, e.target.value)}
            onBlur={(e) => setFieldTouched(name, true)}
            as='select'
            value={value}
            {...props}
        >
            <option value={''} disabled>Select a system</option>
            { options }
        </Form.Control>
    )
}


const SystemPropertyGroupSelectBox = ({setFieldValue, setFieldTouched, systemTypeId, value, name, ...props}) => {
    const { data, loading } = useQuery(QUERY_SYSTEM_TYPE, {variables: {systemTypeId}, skip: (systemTypeId === '')})

    if (loading) return <div><Spinner size='sm' animation='border' className='mr-3' /> Loading......</div>

    if (systemTypeId === '') return (
        <Form.Control disabled as='select'>
            <option disabled selected>Select a system type first</option>
        </Form.Control>
    )

    const options = data.systemType.systemPropertyGroups.map(spg => <option key={spg.id} value={spg.id}>{spg.name}</option>)

    return (
        <Form.Control
            onChange={(e) => setFieldValue(name, e.target.value)}
            onBlur={(e) => setFieldTouched(name, true)}
            as='select'
            value={value}
            {...props}
        >
            <option value={''} disabled>Select a feature</option>
            { options }
        </Form.Control>
    )
}


const IncludeCheck = ({value, setFieldValue, setFieldTouched, name, ...props}) => {
    return (
        <Form.Check
            type='checkbox'
            onBlur={() => setFieldTouched(name, true)}
            onChange={(e) => setFieldValue(name, e.target.checked)}
            checked={value}
            {...props}
        />
    )
}


const DateControl = ({...props}) => {
    return (
        <Form.Control id='add-property-date' {...props} />
    )
}


const ValueField = ({systemPropertyGroupId, systemTypeId, ...props}) => {
    const { data, loading } = useQuery(QUERY_SYSTEM_TYPE, {
        variables: {systemTypeId: systemTypeId},
        skip: (systemTypeId === ''),
    })

    let unit = '--';
    let systemPropertyGroup;
    if (!!systemPropertyGroupId && !!systemTypeId && !loading) {
        systemPropertyGroup = data.systemType.systemPropertyGroups.find(spg => spg.id === systemPropertyGroupId);
        if (!!systemPropertyGroup) {
            unit = systemPropertyGroup.unit || '--';
        }
    }

    return (
        <Form.Group>
            <Form.Label>Value</Form.Label>
            <InputGroup>
                <Field as={Form.Control} name='value' type='number' step='0.01' />
                <InputGroup.Append>
                    <InputGroup.Text>{unit}</InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>

        </Form.Group>
    )
}


const insertSchema = Yup.object().shape({
    systemId: Yup.number().required(),
    systemPropertyGroupId: Yup.number().required(),
    certainty: Yup.number().optional().min(0.0).max(100.0),
    value: Yup.number(),
    date: Yup.date(),
})


const systemPropertyInitialValues = {
    systemTypeId: '',
    systemId: '',
    systemPropertyGroupId: '',
    value: 0.0,
    reference: '',
    referenceMarin: '',
    referenceDetail: '',
    timescale: 2020,
    include: true,
    certainty: 60,
    explanation: '',
    date: new Date(),
    note: '',
}


const InsertDataForm = ({setShow, ...props}) => {
    const [ createSystemProperty, { loading }] = useMutation(MUTATION_CREATE_SYSTEM_PROPERTY, {
        update: (cache, { data }) => {
            const cachedData = cache.readQuery({query: QUERY_SYSTEM_PROPERTIES})
            const systemIdx = cachedData.systems.findIndex(s => s.id === data.createSystemProperty.systemId);
            const newSystemsArray = [...cachedData.systems]
            newSystemsArray[systemIdx] = {
                ...cachedData.systems[systemIdx],
                systemProperties: [
                    ...cachedData.systems[systemIdx].systemProperties,
                    data.createSystemProperty
                ]}

            cache.writeQuery({query: QUERY_SYSTEM_PROPERTIES, data: { systems: newSystemsArray } })
        }
    })
    const shouldCloseForm = useRef(false);

    function handleSubmit(values, { setSubmitting, resetForm }) {
        return createSystemProperty({
            variables: {
                data: omit(values, ['systemTypeId'])
            }
        }).then(() => setSubmitting(false)).then(() => {
            shouldCloseForm.current ? setShow(false) : resetForm({
                values: {
                    ...systemPropertyInitialValues,
                    systemTypeId: values.systemTypeId,
                    systemId: values.systemId,
                    systemPropertyGroupId: values.systemPropertyGroupId,
                }
            })
        })
    }

    function onKeyDown(e) {
        if ((e.charCode || e.keyCode) === 13) {
            e.preventDefault();
        }
    }

    return (
        <Formik
            initialValues={systemPropertyInitialValues}
            validationSchema={insertSchema}
            onSubmit={handleSubmit}
        >
            {({values, setValues, errors, initialValues, handleSubmit, handleReset, submitForm, resetForm, setFieldValue, setFieldTouched, isSubmitting}) => (
                <Form autoComplete='on' onSubmit={handleSubmit} onReset={handleReset} onKeyDown={onKeyDown}>
                    <Form.Group>
                        <Form.Label>System type</Form.Label>
                        <SystemTypeSelectBox
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            name='systemTypeId'
                            value={values.systemTypeId}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>System</Form.Label>
                        <SystemSelectBox
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            systemTypeId={values.systemTypeId}
                            name='systemId'
                            value={values.systemId}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Feature</Form.Label>
                        <SystemPropertyGroupSelectBox
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            systemTypeId={values.systemTypeId}
                            name='systemPropertyGroupId'
                            value={values.systemPropertyGroupId}
                        />
                    </Form.Group>

                    <ValueField systemTypeId={values.systemTypeId} systemPropertyGroupId={values.systemPropertyGroupId} />

                    <Form.Group>
                        <Form.Label>Time scale</Form.Label>
                        <Field as={Form.Control} name='timescale' type='number' step='5' />
                    </Form.Group>

                    <Form.Group>
                        <IncludeCheck
                            id='insert-form-include'
                            label='Include'
                            name='include'
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            value={values.include}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Certainty</Form.Label>
                        <Field as={Form.Control} name='certainty' type='number' step='10' />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Date</Form.Label>
                        <DateTime
                            onChange={value => setFieldValue('date', new Date(value))}
                            onBlur={() => setFieldTouched('date', true)}
                            inputProps={{placeholder: 'Datum', size: 'sm'}}
                            value={values.date}
                            placeholder='datum'
                            dateFormat='DD-MM-YYYY'
                            timeFormat=''
                            viewMode='days'
                            renderInput={DateControl}
                            {...props}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Reference</Form.Label>
                        <Field as={Form.Control} autoComplete='on' id='add-property-reference' name='reference' type='text' />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Reference detail</Form.Label>
                        <Field as={Form.Control} autoComplete='on' id='add-property-reference' name='referenceDetail' type='text' />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Reference (internal)</Form.Label>
                        <Field as={Form.Control} autoComplete='on' id='add-property-reference' name='referenceMarin' type='text' />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Explanation</Form.Label>
                        <Field as={Form.Control} autoComplete='on' id='add-property-explanation' name='explanation' type='text'/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Notes</Form.Label>
                        <Field as={Form.Control} autoComplete='on' id='add-property-note' name='note' type='text'/>
                    </Form.Group>
                    { (Object.keys(errors).length > 0) ? (
                        <Alert variant='warning'>
                            { Object.keys(errors).map((k, kIdx) => <p className='my-1' key={kIdx}>{errors[k]}</p>) }
                        </Alert>
                    ) : null}
                    <ButtonGroup className='w-100'>
                        { (loading || isSubmitting) ? <Button disabled><Hourglass size={18}/></Button> : null}
                        <Button disabled={isSubmitting} type='submit' onClick={() => {shouldCloseForm.current = true}}>Submit</Button>
                        <Button
                            disabled={isSubmitting}
                            type='button'
                            onClick={() => {
                                shouldCloseForm.current = false
                                submitForm()
                            }}>
                            Submit and insert another
                        </Button>
                    </ButtonGroup>

                </Form>
            )}
        </Formik>
    )
}


const InsertDataModal = ({show, setShow, ...props}) => {
    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>Insert new data</Modal.Header>
            <Modal.Body>
                <InsertDataForm setShow={setShow} />
            </Modal.Body>
        </Modal>
    )
}


export const InsertDataButton = (props) => {
    const [ show, setShow ] = useState(false);

    return (
        <React.Fragment>
            <Button onClick={() => setShow(!show)} {...props} />
            <InsertDataModal show={show} setShow={setShow} />
        </React.Fragment>
    )
}