import React, {useState} from 'react';
import {Modal, Row, Col, Button, ButtonGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useMutation} from "@apollo/react-hooks";
import {QUERY_CHARTS} from "../../../../graphql/chart";
import {MUTATION_DELETE_CHART_SETTING} from "../../../../graphql/chartSetting";


const DeleteChartSettingModal = ({show, setShow, chartSetting, ...props}) => {
    const [deleteChartSetting, {loading}] = useMutation(MUTATION_DELETE_CHART_SETTING, {
        refetchQueries: [{query: QUERY_CHARTS}],
        variables: {chartSettingId: chartSetting.id},
    })

    const onSubmit = () => {
        deleteChartSetting().then(() => setShow(false))
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Delete a chart setting
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        This will delete the chart setting "{chartSetting.name}". Confirm?
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        <ButtonGroup className='w-100'>
                            <Button disabled={loading} onClick={onSubmit}>Confirm</Button>
                            <Button disabled={loading} variant='danger' onClick={() => setShow(false)}>Cancel</Button>
                        </ButtonGroup>
                    </Col>
                </Row>

            </Modal.Body>
        </Modal>
    )
}

export const DeleteChartSettingModalButton = ({chartSetting, ...props}) => {
    const [show, setShow] = useState(false);

    const onClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShow(true);
    }

    return (
        <React.Fragment>
            <OverlayTrigger
                overlay={
                    <Tooltip id={`delete-chart-setting-${chartSetting.id}-tooltip-id`}>
                        Delete this chart setting
                    </Tooltip>}
            >
                <span onClick={onClick} style={{cursor: "pointer", color: "red"}}>delete</span>
            </OverlayTrigger>
            <DeleteChartSettingModal chartSetting={chartSetting} show={show} setShow={setShow}/>
        </React.Fragment>
    )
}
