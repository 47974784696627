import { useState } from 'react';


export function usePagination(data, options = {initialItemsPerPage: 25}) {
    const [ page, setPage ] = useState(0);
    const [ itemsPerPage, setItemsPerPage ] = useState(options.initialItemsPerPage);

    const firstItem = page * itemsPerPage;
    const lastItem = page * itemsPerPage + itemsPerPage;

    if (firstItem > data.length) {
        setPage(Math.floor(data.length / itemsPerPage));
    }

    function nextPage() {
        setPage(page => ((firstItem) < data.length) ? page + 1 : page);
    }

    function previousPage() {
        setPage(page => (page > 0) ? page - 1 : page);
    }

    return {
        data: data.slice(firstItem, lastItem),
        page, nextPage, setPage, previousPage,
        itemsPerPage, setItemsPerPage, firstItem, lastItem,
        numberOfItems: data.length,
    }
}