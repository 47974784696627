import React from 'react'
import { ListGroup, ButtonGroup, Row, Col } from 'react-bootstrap';
import {EditCategoryModalButton} from "./edit-category";
import {DeleteCategoryModalButton} from "./delete-category";


export const Category = ({category, ...props}) => {
    return (
        <ListGroup.Item>
            <Row>
                <Col sm={3}>
                    {category.name}
                </Col>
                <Col sm={5}>
                    {category.description}
                </Col>
                <Col sm={2}>
                    <div style={{width: '100%', height: '100%', backgroundColor: category.color}} />
                </Col>
                <Col sm={2}>
                    <ButtonGroup>
                        <EditCategoryModalButton category={category} size='sm' />
                        <DeleteCategoryModalButton category={category} size='sm' />
                    </ButtonGroup>
                </Col>
            </Row>
        </ListGroup.Item>
    )
}